import {
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_FAILURE, 
    GET_CUSTOMER,  
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAILURE, 
    DELETE_CUSTOMER, 
    CREATE_CUSTOMER, 
    CREATE_CUSTOMERFROMARR, 
    CREATE_CUSTOMER_SUCCESS,
    CREATE_CUSTOMER_FAILURE, 
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAILURE    
  } from '../actions/constants'
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
    saved : false
  };
  
  
  export const customerReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_CUSTOMER:
        return {...state, ...{loading: true, error: null, saved : false}}      
      case GET_CUSTOMER_SUCCESS :      
        return {...state, ...{loading: false, data : action.data, error: '', saved : false}}      
      case GET_CUSTOMER_FAILURE:     
        return {...state, loading: false, error : action.data.error };
      case DELETE_CUSTOMER:
            return {...state, ...{loading: true, error: null, saved : false}}      
      case DELETE_CUSTOMER_SUCCESS: {          
        const list = state.data.filter(item => item.customerId !== action.data.id);      
        return {...state, ...{loading: false, error : '', data : list }}; 
      }
      case DELETE_CUSTOMER_FAILURE:             
        return {...state, loading: false, error : action.data.error };
  
      case CREATE_CUSTOMER:
      case CREATE_CUSTOMERFROMARR:
        return {...state, ...{loading: true, error : null, saved : false}}      
      case CREATE_CUSTOMER_SUCCESS: { 
        let newArr = state.data.concat(action.data);

        return {...state, ...{loading: false, data: newArr,  error : '', saved : true}};
      }
      case CREATE_CUSTOMER_FAILURE:      
        return {...state, loading: false, error : action.data.error, saved : false };
      case UPDATE_CUSTOMER:
        return {...state, ...{loading: true, error: null, saved : false}}      
      case UPDATE_CUSTOMER_SUCCESS: {            
        
        let newArr = [...state.data]; 
        let index = newArr.findIndex(item => item.customerId === action.data.customerId);
        if (index >= 0)
            newArr.splice(index, 1, action.data)
  
        return {...state, ...{loading: false, data :  newArr, error : '', saved : true}};
      }    
      case UPDATE_CUSTOMER_FAILURE:
        return {...state, loading: false, error : action.data.error, saved : false };     
      default:      
        return state;
    }
  };