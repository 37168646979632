import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class NewsApi {
    constructor() {
        this.endpoint = `/News`;
    }

    async getNews() {
        try {
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }
    
    async createNewsEntry(newsItem) {
        try {
            return axiosConfig.post(this.endpoint, {
                Message : newsItem.message,
                Title : newsItem.title
            });
        }
        catch(error) {
            throw error;
        }
    }

    async deleteNewsById(newsId) {
        try {
            return axiosConfig.delete(`${this.endpoint}/${newsId}`);
        }
        catch(error) {
            throw error;
        }        
    }

    async updateNews(news) {        
        try {
            return axiosConfig.put(`${this.endpoint}/${news.newsId}`, {
                Message : news.message,
                Title : news.title,
                CompanyId : news.companyId
        })
        }
        catch(error) {
            throw error;
        }        
    }
}

mock.onPost("/News").reply(200, {
    newsId : "c3636c70-cd1c-11ea-87d0-0242ac130003",
    header : "header 1",
    message : "news 123",        
    date    : "2020-01-01 20:00",        
    type : "company",        
});

mock.onPut(/News\/.+/).reply(200, {    
});

mock.onGet("/News").reply(200,
    [{
        newsId : "a3636c70-cd1c-11ea-87d0-0242ac130003",
        header : "header 1",
        message : "news 123",    
        image : "https://i.pravatar.cc/300?img=10",
        date    : "2020-01-01 20:00",
        createdBy : "Øyvind Ludvigsen",        
        type : "root"    
    },
    {
        newsId : "b3636c70-cd1c-11ea-87d0-0242ac130003",
        header : "header 2",
        image : "https://i.pravatar.cc/300?img=10",
        message : "news 1234",        
        date    : "2020-01-01 20:00",        
        createdBy : "Øyvind Ludvigsen",        
        type : "company"    
    },
    {
        newsId : "c3636c70-cd1c-11ea-87d0-0242ac130003",
        header : "header 3",
        message : "news 1235",    
        date    : "2020-01-01 20:00",        
        createdBy : "Øyvind Ludvigsen",        
        type : "root"    
    },
    {
        newsId : "d3636c70-cd1c-11ea-87d0-0242ac130003",
        header : "header 4",
        image : "https://i.pravatar.cc/300?img=10",
        message : "news 1236",        
        date    : "2020-01-01 20:00",        
        createdBy : "Øyvind Ludvigsen",        
        type : "company"    
    }
]
);

mock.onDelete(/News\?id.+/).reply(200);

