import {
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILURE,
  CHANGE_ROLEITEM,
  CHANGE_ROLEITEM_SUCCESS,
  CHANGE_ROLEITEM_FAILURE,
  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  SAVE_ROLE,
  SAVE_ROLE_SUCCESS,
  SAVE_ROLE_FAILURE,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE
}
  from '../actions/constants'

import {deepCopy} from '../utils/deepCopy'

const initialState = {
    data: [],  
    permissionsAvailable : [],
    error: null,
    loading: false,
    saved : false,
    deleted: false
  };  

export const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLE:
    case DELETE_ROLE: 
        return {...state, ...{loading: true, error: null, saved : false, deleted : false}}      
      case GET_ROLE_SUCCESS :                  
        addMissingPermissionsToArray(action.data, action.permissionsAvailable);              

        return {...state, ...{loading: false, data : action.data, permissionsAvailable : action.permissionsAvailable,  error: '', saved : false, deleted : false}}      

      case GET_ROLE_FAILURE:     
        return {...state, loading: false, error : action.data.error, saved : false, deleted : false };      

      case CREATE_ROLE:      
        return {...state, ...{loading: true, error: null, deleted : false}}      
      case CREATE_ROLE_SUCCESS :           
        var newRoleData = deepCopy(action.data.newRole);        
                
        // add default value for checkbox
        var arr = deepCopy(state.permissionsAvailable).map(perm => {
          perm.isSet = false;
          return perm;
        });

        newRoleData.permissions = groupPermissions(arr);
        newRoleData.permissionCount = 0;
        newRoleData.isNew = true;

        var copy2 = deepCopy(state.data);               
        copy2.push(newRoleData);
        
        return {...state, ...{loading: false, data : copy2, error: '', deleted : false}}      
      case CREATE_ROLE_FAILURE:     
        return {...state, loading: false, error : action.data.error, deleted : false };      
      
      case CHANGE_ROLEITEM:
          return { ...state, ...{ loading: true, error: null, saved: false, deleted : false } };
      case CHANGE_ROLEITEM_SUCCESS: {              
          var copy = deepCopy(state.data);         

          var selectedRole = copy.find(t => t.roleId === action.data.roleId);
          var permissionArr = selectedRole.permissions[action.data.arrKey];            
          var permissionItem = permissionArr.find(item => item.permissionId === action.data.permissionId);

          if (action.data.isChecked) {
            permissionItem.isSet = true;   
            selectedRole.permissionCount +=1;         
          }            
          else {
            permissionItem.isSet = false;                               
            selectedRole.permissionCount-=1;         
          }

          return { ...state, ...{ loading: false, error: '', data : copy, saved: false, deleted : false } };
      }
      case DELETE_ROLE_SUCCESS: {
        let  newCopy = deepCopy(state.data);
        let indexFound = newCopy.findIndex(t => t.roleId === action.data);        
        
        if (indexFound >=0)
          newCopy.splice(indexFound, 1)

        return { ...state, ...{ loading: false, error: '', data : newCopy,  saved : false, deleted : true } };
      }
      
      case CHANGE_ROLEITEM_FAILURE:
      case DELETE_ROLE_FAILURE:
          return { ...state, loading: false, error: action.data.error, deleted : false };    

      case UPDATE_ROLE:
      case SAVE_ROLE:
        return {...state, ...{loading: true, error: null, deleted : false}}   

      case UPDATE_ROLE_SUCCESS:     
      case SAVE_ROLE_SUCCESS:     
        return {...state, loading: false, error : '', saved : true, deleted : false };      
      
      case UPDATE_ROLE_FAILURE:       
      case SAVE_ROLE_FAILURE:           
        return {...state, loading: false, error : action.data.error, deleted : false };         

    default:      
      return state;
  }
};

function addMissingPermissionsToArray(orgArr, permsissionsAvailable) {    
    
  var copyExisting = Array.from(permsissionsAvailable);    
  for (let i = 0; i < orgArr.length; ++i) {
      var currItem = orgArr[i];
      var permissionSetCounter = 0;

      var newPermissionArr = [];
      for (let index = 0; index < copyExisting.length; ++index) {        
          var permission = copyExisting[index];
          const permissionId = permission.permissionId;
          
          var found = currItem.permissions.find(t => t.permissionId === permissionId);
          if (found) {
              found.isSet = true;
              newPermissionArr.push(found);
              permissionSetCounter++;
          } else {
              permission.isSet = false;
              newPermissionArr.push(permission);
          }        
      }
      
      currItem.permissionCount = permissionSetCounter;
      currItem.permissions = groupPermissions(newPermissionArr);
  }      
}

function groupPermissions(newPermissionArr) {
  return newPermissionArr.reduce((r, a) => {            
    r[a.group] = [...(r[a.group] || []), a];
    return r;
   }, {});     
}
