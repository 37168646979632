
const initialState = {
    data: [],  
    error: null,
    loading: false,
  };  

export const namedStandardReducer = (actionName = '') => {
    return function counter(state = initialState, action) {
      switch (action.type) {
        case actionName:            
            return {...state, ...{loading: true, error: null}}      
        case `${actionName}_SUCCESS`:
            return { ...state, ...{ loading: false, data: action.data, error: '' } };
        case `${actionName}_FAILURE`:
            return { ...state, loading: false, error: action.data.error };
        case `${actionName}_RESET`:
            return { ...state, loading: false, error: null };
        default:
          return state
      }
    }
  }