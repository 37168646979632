import {
  GET_HOURS, GET_HOURS_SUCCESS, GET_HOURS_FAILURE,
  GET_PROJECTHOURS, GET_PROJECTHOURS_SUCCESS, GET_PROJECTHOURS_FAILURE,
  GET_HOURBANK, GET_HOURBANK_SUCCESS, GET_HOURBANK_FAILURE,
  GET_HOURSBETWEENDATES, GET_HOURSBETWEENDATES_SUCCESS, GET_HOURSBETWEENDATES_FAILURE,
  APPROVE_HOUR, APPROVE_HOUR_SUCCESS, APPROVE_HOUR_FAILURE, RESET_APPROVESAVED,
  DELETE_HOUR, DELETE_HOUR_SUCCESS, DELETE_HOUR_FAILURE,
  CREATE_HOUR, CREATE_HOUR_SUCCESS, CREATE_HOUR_FAILURE, RESET_HOURSAVED,
  SAVE_HOURS, SAVE_HOURS_SUCCESS, SAVE_HOURS_FAILURE,
  CHANGE_HOURITEM, CHANGE_HOURITEM_SUCCESS, CHANGE_HOURITEM_FAILURE,
  CREATE_ABSENCEHOUR,
  CHANGE_HOURITEMREMOTESYSTEMSYNCSTARTED  
} from '../actions/constants'

import { put, takeLatest, call } from 'redux-saga/effects'
import { HourApi, ProjectApi } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

export function* approveHour(action) {
  let api = new HourApi(); 

  try {
    var hourData = action.payload.hourApproveData;
    var startSpinnner = action.payload.startSpinner;        
    
    for (let item in hourData) {
        let dataItem =  hourData[item];
        let response = null;
        if (dataItem.val === true) {          
          if (startSpinnner && !dataItem.isApproved) {            
            yield put ({type : CHANGE_HOURITEMREMOTESYSTEMSYNCSTARTED, data : [dataItem.hourKey]});
          }
          response = yield call(() => api.approveHour(dataItem.hourKey));                      
        } else {
          response = yield call(() => api.unApproveHour(dataItem.hourKey));    
        }    
    
        hourData[item] = response.data;
    }        
    
    yield put({ type: APPROVE_HOUR_SUCCESS, data: {hourData} });    
  } catch (err) {        
    yield put({ type: APPROVE_HOUR_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* getHours(action) {
  let api = new HourApi();

  try {    
    var hourData = action.payload;          
    
    let response = yield call(() => api.getHoursFromWeek(hourData.date, hourData.employeeId));    
      
    yield put({ type: GET_HOURS_SUCCESS, data: response.data });
  } catch (err) {        
    yield put({ type: GET_HOURS_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* getHoursBetweenDates(action) {
  let api = new HourApi();

  try {
    var hourData = action.payload;              
    let response = yield call(() => api.getHoursBetweenDates(hourData.fromDate, hourData.toDate));    
      
    yield put({ type: GET_HOURSBETWEENDATES_SUCCESS, data: response.data });
  } catch (err) {        
    yield put({ type: GET_HOURSBETWEENDATES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* getHoursBank(action) {
  let api = new HourApi();

  try {
    var hourData = action.payload;                  
    let response = yield call(() => api.getHoursBankBetweenDates(hourData.fromDate, hourData.toDate));    
    
    yield put({ type: GET_HOURBANK_SUCCESS, data: response.data });
  } catch (err) {        
    yield put({ type: GET_HOURBANK_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* getProjectHours(action) {
  let api = new ProjectApi();

  try {    
    var payload = action.payload;                  
    let response = yield call(() => api.getProjectHours(payload.projectId));    
    
    yield put({ type: GET_PROJECTHOURS_SUCCESS, data: response.data });
  } catch (err) {        
    yield put({ type: GET_PROJECTHOURS_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* deleteHours(action) {
  let api = new HourApi();

  try {    
    let hourId = action.payload.hourId;

    yield call(() => api.deleteHour(hourId));

    yield put({ type: DELETE_HOUR_SUCCESS, data: {hourId : hourId }});
  } catch (err) {    
    yield put({ type: DELETE_HOUR_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* addHour(action) {
  let api = new HourApi();
  
  try {    
    var hour = action.payload.hour;      
    
    var postData = yield call(() => api.addHourEntry(hour));    
    
    yield put({ type: CREATE_HOUR_SUCCESS, data: { hourItem : postData.data }});    
  } catch (err) {
    yield put({ type: CREATE_HOUR_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    return false;
  }
}

export function* addAbsenceHours(action) {
  let api = new HourApi();
  
  try {    
    var hourArr = action.payload.hourArr;  
    
    for (let hour of hourArr) {
      var postData = yield call(() => api.addHourEntry(hour));        
      yield put({ type: CREATE_HOUR_SUCCESS, data: {hourItem : postData.data }});
    }      
  } catch (err) {
    yield put({ type: CREATE_HOUR_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    return false;
  }
}

export function* changeHourItem(action) {
  let api = new HourApi();  
  try {    
    var hour = action.payload;  
    
    yield call(() => api.updateHourEntry(hour))
    
    yield put({ type: CHANGE_HOURITEM_SUCCESS, data: hour});  
  } catch (err) {
    yield put({ type: CHANGE_HOURITEM_FAILURE, data: { error: handleCommonHttpErrors(err.response)} });
  }
}

export function* addTimeSheet(action){
  var api = new HourApi();

  try {    
    var data = action.payload;
    
    let response = yield call(() => api.addTimeSheet(data.hours, data.currWeekYear, data.deletedHours));   
    yield call(getHours, {payload : {date : data.currentDate}});            
    
    yield put({ type: SAVE_HOURS_SUCCESS, data: {id : response.data }});
  } catch (err) {
    yield put({ type: SAVE_HOURS_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* watchResetHourSaved() {   
  yield put({type : RESET_HOURSAVED, data : {}})
}

export function* watchResetApproved() {   
  yield put({type : RESET_APPROVESAVED, data : {}})
}

export function* watchGetHoursBetweenDates() {
  yield takeLatest(GET_HOURSBETWEENDATES, getHoursBetweenDates);
}

export function* watchGetHours() {
  yield takeLatest(GET_HOURS, getHours);
}

export function* watchGetHourBank() {
  yield takeLatest(GET_HOURBANK, getHoursBank)
}

export function* watchGetProjectHours() {
  yield takeLatest(GET_PROJECTHOURS, getProjectHours)
}

export function* watchDeleteHours() {
  yield takeLatest(DELETE_HOUR, deleteHours);
}

export function* watchAddHour() {
  yield takeLatest(CREATE_HOUR, addHour);
}

export function* watchAddAbsenceHours() {
  yield takeLatest(CREATE_ABSENCEHOUR, addAbsenceHours);
}

export function* watchChangeHour() {
  yield takeLatest(CHANGE_HOURITEM, changeHourItem);
}

export function* watchApproveHour() {
  yield takeLatest(APPROVE_HOUR, approveHour);
}

export function* watchAddTimeSheets() {
  yield takeLatest(SAVE_HOURS, addTimeSheet); 
}