import createSagaMiddleware from 'redux-saga';
import { reconnectSignalR } from './signalRMiddleware'
import { createLogger } from 'redux-logger';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

export const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware, reconnectSignalR];

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {  
  const invariant = reduxImmutableStateInvariant;

  middleware.push(invariant());
  middleware.push(createLogger({ collapsed: true }));
}

export default middleware;