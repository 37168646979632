import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class UserApi {
    constructor() {
        this.endpoint = `/User`;
    }        

    async getUserRoles(userId) {
        try {
            return axiosConfig.get(`${this.endpoint}/${userId}/role`)
        }
        catch(error) {
            throw error;
        }        
    }

    async getUserById(userId) {
        try {            
            return axiosConfig.get(`${this.endpoint}/${userId}`);
        }
        catch (error) {
            throw error;
        }        
    }        

    async verifyNewUser(token, data) {
        try {            
            return axiosConfig.get(`${this.endpoint}/verify?userToken=${token}&data=${data}`);
        }
        catch (error) {
            throw error;
        }        
    }        

    async recoverUserPassword(email) {
        try {                        
            return  axiosConfig.post(`${this.endpoint}/password/recovery`, {
                email
            });
        }
        catch (error) {            
            throw error;
        }        
    }        

    async resetUserPassword(resetData) {
        try {                        
            return  axiosConfig.post(`${this.endpoint}/password/reset`, resetData);
        }
        catch (error) {            
            throw error;
        }        
    }        

    async getUserSettings(userId) {
        try {            
            return axiosConfig.get(`${this.endpoint}/${userId}/setting`);
        }
        catch (error) {
            throw error;
        }        
    }        

    async updateUserById(userId, userData) {    
        try {
            return axiosConfig.put(`${this.endpoint}/${userId}`, userData);
        }
        catch (error) {
            throw error;
        }        
    }    
    
    async updateUserRoles(userId, roles) {
        try {
            return axiosConfig.put(`${this.endpoint}/${userId}/role`, roles)
        }
        catch(error) {
            throw error;
        }        
    }
}

mock.onGet(/\/User\/?.+/).reply(200, {
    'UserId' : '675454-4545-45-4545',
    'FirstName' : 'Øyvind',
    'LastName' : 'Ludvigsen',
    'UserName': 'oyvindlu@gmail.com',
    'EmployeeNumber': '120',
    'PhoneNumber': '90924575', 
    'DateOfBirth': '1977-09-25',
});




