import axiosConfig from './axiosConfig';

var MockAdapter = require('axios-mock-adapter');

var mock = new MockAdapter(axiosConfig, { delayResponse: 500 });

if (process.env.REACT_APP_MOCK_API !== 'true')
    mock.restore();

export {mock};
