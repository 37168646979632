import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class HourRuleApi {
    constructor() {
        this.endpoint = `/HourRule/`;
    }

    async getAllHourRules() {
        try {          
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }        

    async createHourRule(rule) {
        try {          
            return axiosConfig.post(this.endpoint, rule);
        }
        catch (error) {
            throw error;
        }        
    }        

    async updateHourRule(hourRuleId, rule) {
        try {          
            return axiosConfig.put(`${this.endpoint}${hourRuleId}`, rule);            
        }
        catch (error) {
            throw error;
        }        
    }        

    async deleteHourRule(hourRuleId) {
        try {          
            return axiosConfig.delete(`${this.endpoint}${hourRuleId}`);            
        }
        catch (error) {
            throw error;
        }        
    }        
}


mock.onGet(/HourRule/).reply(200, [
    {
        "hourRuleId": "2307b6c0-e3a2-45f5-9a81-022ea0fbbcae",
        "companyId": "309c3648-1631-46ed-8fa6-55bacc458a50",
        "projectId": null,
        "activityId": null,
        "ruleType": "addition",
        "active": false,
        "name": "rule1",
        "defaultValue": 0.0,
        "hourRuleItem": [
            {
                "hourRuleItemId": "8b8da6e4-301a-40d6-bb4f-6af457225bdc",
                "dayRuleStart": 1,
                "startMinutesAfterMidnight": 100,
                "endMinutesAfterMidnight": 200,
                "notBeforeElapsedMinutes": 10
            }
        ]
    }
]);