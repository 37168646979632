import {
    GET_TEMPLATE_SUCCESS,
    GET_TEMPLATE_FAILURE,
    GET_TEMPLATE,
    GET_TEMPLATEBYID_SUCCESS,
    GET_TEMPLATEBYID_FAILURE,
    GET_TEMPLATEBYID,
    CREATE_TEMPLATE_SUCCESS,
    CREATE_TEMPLATE_FAILURE,
    CREATE_TEMPLATE,
    DELETE_TEMPLATE,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILURE,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
    CLEAR_TEMPLATESAVED,
  } from '../actions/constants'

  const initialState = {
    data: [],
    error: null,
    loading: false,
    saved : false,
  };


  export const templateReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TEMPLATE:
      case GET_TEMPLATEBYID:
      case CREATE_TEMPLATE:
      case DELETE_TEMPLATE:
      case UPDATE_TEMPLATE:
        return {...state, ...{loading: true, error: null, saved : false}}

      case GET_TEMPLATE_SUCCESS :
        return {...state, ...{loading: false, saved : false, data : action.data, error: ''}}
      case CREATE_TEMPLATE_SUCCESS : {
        let newArr = state.data.concat(action.data);
        return {...state, ...{loading: false, saved : true, data : newArr, error: ''}}
      }
      case DELETE_TEMPLATE_SUCCESS: {
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.templateId === action.data);
        copyArr.splice(index, 1);

        return {...state, ...{loading: false, saved : false, data : copyArr, error: ''}}
      }
      case UPDATE_TEMPLATE_SUCCESS: {
        let copyUpdateArr = [...state.data];
        let index = copyUpdateArr.findIndex(item => item.templateId === action.data.templateId);
        copyUpdateArr[index] = action.data;

        return {...state, ...{loading: false, saved : true, data : copyUpdateArr, error: ''}}
      }
      case GET_TEMPLATEBYID_SUCCESS: {
        let copyUpdateArr = [...state.data];
        let index = copyUpdateArr.findIndex(item => item.templateId === action.data.templateId);

        if (index >= 0)
          copyUpdateArr[index] = action.data;
        else
          copyUpdateArr.push(action.data)

        return {...state, ...{loading: false, saved : true, data : copyUpdateArr, error: ''}}
      }

      case GET_TEMPLATE_FAILURE:
      case GET_TEMPLATEBYID_FAILURE:
      case CREATE_TEMPLATE_FAILURE:
      case DELETE_TEMPLATE_FAILURE:
      case UPDATE_TEMPLATE_FAILURE:
        return {...state, loading: false, saved : false, error : action.data.error };
      case CLEAR_TEMPLATESAVED:
        return {...state, saved : false };
      default:
        return state;
    }
  };