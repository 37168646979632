import axiosConfig from '../axiosConfig';
import { mock } from '../mock'
import {v1 as uuid} from 'uuid'
import {getWeek} from 'date-fns'
import { padString } from '../../utils/stringUtil'

export default class HourApi {
    constructor() {
        this.endpoint = `/Hour`;         
    }    

    async getHoursFromWeek(dateInWeek, employeeId) {     
        try {                        
            let query = employeeId ? `/${dateInWeek.toISOString()}/${employeeId}` : `/${dateInWeek.toISOString()}`;          
          
            return axiosConfig.get(this.endpoint + query);
        }
        catch(error) {
            throw error;
        }                   
    }    

    async getHoursBetweenDates(fromDate, toDate) {     
        try {                        
            let query = `?startDate=${fromDate.toISOString()}&endDate=${toDate.toISOString()}`;          
            return axiosConfig.get(this.endpoint + query);
        }
        catch(error) {
            throw error;
        }                   
    }    

    async getHoursBankBetweenDates(fromDate, toDate) {     
        try {
            let dtFrom = fromDate ? fromDate.toISOString() : '';                         
            let dtTo = toDate ? toDate.toISOString() : '';            
            
            let query = `/hourbank?fromTime=${dtFrom}&toTime=${dtTo}`;          
            return axiosConfig.get(this.endpoint + query);
        }
        catch(error) {
            throw error;
        }                   
    }    

    async getHoursByEmployee(employeeId, dateInWeek) {     
        try {            
            let query = `?employee=${employeeId}&dateInWeek=${dateInWeek}`
            return axiosConfig.get(this.endpoint + employeeId + query);
        }
        catch(error) {
            throw error;
        }                   
    }    

    async deleteHour(hourId) {     
        try {                                               
            return axiosConfig.delete(`${this.endpoint}/entry/${hourId}`);
        }
        catch(error) {
            throw error;
        }                   
    }    

    async addHourEntry(hour) {     
        try {      
            return axiosConfig.post(`${this.endpoint}/entry`, hour);
        }
        catch(error) {
            throw error;
        }                   
    }    

    async updateHourEntry(hour) {     
        try {      
            return axiosConfig.put(`${this.endpoint}/entry/${hour.hourId}`, hour);
        }
        catch(error) {
            throw error;
        }                   
    }    

    async addTimeSheet(timeSheet, weekDate, deletedHours) {     
        try {                
            return axiosConfig.patch(this.endpoint + `/Week/${weekDate}`, {
                hours : timeSheet,
                hoursToDelete : deletedHours
            });
        }
        catch(error) {
            throw error;
        }                   
    }      

    async approveHour(hourId) {             
        try {                        
            let query = `/entry/${hourId}/approved`;          
            return axiosConfig.patch(this.endpoint + query);
        }
        catch(error) {
            throw error;
        }                   
    }    

    async unApproveHour(hourId) {     
        try {                        
            let query = `/entry/${hourId}/unapproved`;          
            return axiosConfig.patch(this.endpoint + query);
        }
        catch(error) {
            throw error;
        }                   
    }    
}

mock.onDelete("Hour").reply(200);
mock.onPost("Hour").reply(200);
mock.onPost("Hour/Week").reply(200);

mock.onGet(/\/Hour\?dateInWeek.+/).reply(200, 
    {
    page : 1,
    pageSize : 25,
    totalItemCount : 0,     
    items : [{
        hourId : uuid(),
        activityId : 'ddeb27fb-d9a0-4624-be4d-4615062daed4',
        projectId : 'ddeb27fb-d9a0-4624-be4d-4615062daed5',
        companyId : uuid(),
        intelliUserId : uuid(),
        equipmentId : uuid(),
        equipmentTypeId : uuid(),
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0,    
        breakTimeMinutes : "",    
        systemSync : "",           
        weekYear : padString(getWeek(new Date()), 2)        
    }]});
