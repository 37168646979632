import {
    GET_SALARY_SUCCESS,
    GET_SALARY_FAILURE, 
    GET_SALARY,     
    CREATE_SALARY_SUCCESS,
    CREATE_SALARY_FAILURE, 
    CREATE_SALARY,
    DELETE_SALARY,
    DELETE_SALARY_SUCCESS,
    DELETE_SALARY_FAILURE,
    UPDATE_SALARY,    
    UPDATE_SALARY_SUCCESS,    
    UPDATE_SALARY_FAILURE,
    CLEAR_SALARYSAVED
  } from '../actions/constants'  
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
    saved : false,
  };
  
  
  export const salaryReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_SALARY:
      case CREATE_SALARY:
      case DELETE_SALARY:
      case UPDATE_SALARY:
        return {...state, ...{loading: true, error: null, saved : false}}      
        
      case GET_SALARY_SUCCESS :      
        return {...state, ...{loading: false, saved : false, data : action.data, error: ''}}      
      case CREATE_SALARY_SUCCESS : {
        let newArr = state.data.concat(action.data);
        return {...state, ...{loading: false, saved : true, data : newArr, error: ''}}                 
      }
      case DELETE_SALARY_SUCCESS: {        
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.salaryTypeId === action.data);
        copyArr.splice(index, 1);

        return {...state, ...{loading: false, saved : false, data : copyArr, error: ''}}      
      }
      case UPDATE_SALARY_SUCCESS: {
        let copyUpdateArr = [...state.data];
        let index = copyUpdateArr.findIndex(item => item.salaryTypeId === action.data.salaryTypeId);
        copyUpdateArr[index] = action.data;       
        
        return {...state, ...{loading: false, saved : true, data : copyUpdateArr, error: ''}}      
      }

      case GET_SALARY_FAILURE:     
      case CREATE_SALARY_FAILURE:
      case DELETE_SALARY_FAILURE:
      case UPDATE_SALARY_FAILURE:
        return {...state, loading: false, saved : false, error : action.data.error };      
      case CLEAR_SALARYSAVED:
        return {...state, saved : false };      
      default:      
        return state;
    }
  };