import {
    GET_PROJECTSDOCUMENTS,    
    GET_PROJECTSDOCUMENTS_SUCCESS,    
    GET_PROJECTSDOCUMENTS_FAILURE,    
    CREATE_PROJECTSDOCUMENTS,
    CREATE_PROJECTSDOCUMENTS_SUCCESS,
    CREATE_PROJECTSDOCUMENTS_FAILURE,
  } from '../actions/constants'
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
  };  
  
  
  export const projectDocsReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_PROJECTSDOCUMENTS:
      case CREATE_PROJECTSDOCUMENTS:
        return {...state, ...{loading: true, error: null}}      
      case GET_PROJECTSDOCUMENTS_SUCCESS : 
      case CREATE_PROJECTSDOCUMENTS_SUCCESS:
        return { ...state, ...{ loading: false, data: action.data, error: '' } };
      case GET_PROJECTSDOCUMENTS_FAILURE: 
      case CREATE_PROJECTSDOCUMENTS_FAILURE:     
      return { ...state, loading: false, error: action.data.error };
      default:      
        return state;
    }
};