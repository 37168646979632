import { GET_COMPANYBREGINFO_SUCCESS, GET_COMPANYBREGINFO_FAILURE, GET_COMPANYBREGINFO, GET_BREGINFO_RESET } from '../actions/constants'

const initialState = {  
  error: null,
  loading: false,
  success: false,
  data : {}
};

export const companyBregReducer = (state = initialState, action) => {
  switch (action.type) {    
    case GET_COMPANYBREGINFO:
      return { ...state, ...{ loading: true, error: null } }      
    case GET_COMPANYBREGINFO_SUCCESS: {                  
      return { ...state, ...{ loading: false, error: null, success: true, data : action.data } }
    }
    case GET_COMPANYBREGINFO_FAILURE: {                  
      return { ...state, ...{loading: false, error: null }};
    }
    case GET_BREGINFO_RESET: {
       return { ...state, ...{loading: false, error: null }};
    }
    default:
      return state;
  }
}; 