import {
    CREATE_CHATMESSAGE,
    CREATE_CHATMESSAGE_SUCCESS,
    CREATE_CHATMESSAGE_FAILURE,
    SET_CHATSTATUS
    } from '../actions/constants'
  
  const initialState = {
    data: [], 
    online : [],     
    loading: false,
    saved : false,
  };  
  
  export const chatReducer = (state = initialState, action) => {  
    switch (action.type) {
    case CREATE_CHATMESSAGE:          
        return {...state, ...{loading : true}}    
    case CREATE_CHATMESSAGE_SUCCESS:      
        var items = [...state.data];      
        items.push({chatMessageId : action.data.chatMessageId, message : action.data.message, fromUserId : action.data.fromUserId, toUserId : action.data.toUserId, time : action.data.time });
        
        return {...state, data : items, loading : false}    
    case CREATE_CHATMESSAGE_FAILURE:     
        return {...state, ...{loading : false}}    
    case SET_CHATSTATUS: {      
        return {...state, online : action.payload.onlineUsers}
    }
      default:      
        return state;
    }
  };