import { put, takeLatest, call} from 'redux-saga/effects'
import { HourRuleApi } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

import { 
  GET_HOURRULES, 
  GET_HOURRULES_SUCCESS,
  GET_HOURRULES_FAILURE,
  CREATE_HOURRULES,
  CREATE_HOURRULES_SUCCESS,
  CREATE_HOURRULES_FAILURE,
  UPDATE_HOURRULES,
  UPDATE_HOURRULES_SUCCESS,
  UPDATE_HOURRULES_FAILURE,
  DELETE_HOURRULES,
  DELETE_HOURRULES_SUCCESS,
  DELETE_HOURRULES_FAILURE,
  HOURRULES_RESET  
} from '../actions/constants'

export function* getAllHourRules() {
  var api = new HourRuleApi();
  try {        
    const response = yield call(() => api.getAllHourRules());    
    yield put({ type: GET_HOURRULES_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_HOURRULES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* createHourRule(action) {
  var api = new HourRuleApi();
  try {        
    var hourRule = action.payload;          
    
    const response = yield call(() => api.createHourRule(hourRule));    
    yield put({ type: CREATE_HOURRULES_SUCCESS, data: response.data });
    yield put({ type: HOURRULES_RESET });
  } catch (err) {
    yield put({ type: CREATE_HOURRULES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* updateHourRule(action) {
  var api = new HourRuleApi();
  try {        
    var hourRule = action.payload;          
    
    const response = yield call(() => api.updateHourRule(hourRule.hourRuleId, hourRule));    
    yield put({ type: UPDATE_HOURRULES_SUCCESS, data: response.data });
    yield put({ type: HOURRULES_RESET });
  } catch (err) {
    yield put({ type: UPDATE_HOURRULES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* deleteHourRule(action) {
  var api = new HourRuleApi();
  try {        
    var hourRuleId = action.payload;              

    const response = yield call(() => api.deleteHourRule(hourRuleId));    
    yield put({ type: DELETE_HOURRULES_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: DELETE_HOURRULES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* watchGetAllHourRules() {
  yield takeLatest(GET_HOURRULES, getAllHourRules);
}

export function* watchCreateHourRule() {
  yield takeLatest(CREATE_HOURRULES, createHourRule);
}

export function* watchUpdateHourRule() {
  yield takeLatest(UPDATE_HOURRULES, updateHourRule);
}

export function* watchDeleteHourRule() {
  yield takeLatest(DELETE_HOURRULES, deleteHourRule);
}
