import axiosConfig from '../axiosConfig'
import { mock } from '../mock'
import {v1 as uuid} from 'uuid'

export default class ActivityApi {
    constructor() {        
        this.endpoint = `/Activity`;         
    }

    async getActivities() {                     
        try {
            return axiosConfig.get(this.endpoint);
        }
        catch(error) {
            throw error;
        }                
    }    

    async createNewActivity(activity) {
        try {
            return axiosConfig.post(this.endpoint, activity);
        }
        catch(error) {
            throw error;
        }
    }

    async deleteActivityId(activityId) {
        try {
            return axiosConfig.delete(`${this.endpoint}/${activityId}`);
        }
        catch(error) {
            throw error;
        }
    }
    
    async updateActivity(activity) {
        try {
            return axiosConfig.put(`${this.endpoint}/${activity.activityId}`, activity);
        }
        catch(error) {
            throw error;
        }
    }
}

mock.onDelete("/Activity").reply(200);

mock.onPut("/Activity/.+/").reply(200, {
    id : 'ddeb27fb-d9a0-4624-be4d-2615062daed4',
    projectId : 'ddeb27fb-d9a0-4624-ye4d-4615062daed5',
    groupId : uuid(),
    name  : 'ActivityUpdate'            
});

mock.onGet('/Activity').reply(200, 
    [
        {
            activityId : 'ddeb27fb-d9a0-4624-be4d-4615062daed4',
            projectId : 'ddeb27fb-d9a0-4624-be4d-4615062daed5',
            groupId : uuid(),
            name  : 'Activity1'            
        },
        {
            activityId : uuid(),
            projectId : uuid(),
            groupId : uuid(),
            name  : 'Activity2'            
        },
        {
            activityId : uuid(),
            projectId : uuid(),
            groupId : uuid(),
            name  : 'Activity3'            
        },
    ]);

mock.onPost("/Activity").reply(200, {
        activityId: uuid(),
        projectId: uuid(),
        groupId: uuid(),
        name: 'Activity100'
    })