import { put, takeLatest, call } from 'redux-saga/effects'
import { GoodsConsumptionApi, ProjectApi } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

import {
    GET_GOODSCONSUMPTION,
    GET_GOODSCONSUMPTION_SUCCESS,
    GET_GOODSCONSUMPTION_FAILURE,
    GET_GOODSCONSUMPTIONBYPROJECT,
    CREATE_GOODSCONSUMPTION,
    CREATE_GOODSCONSUMPTION_SUCCESS,
    CREATE_GOODSCONSUMPTION_FAILURE,
    UPDATE_GOODSCONSUMPTION,
    UPDATE_GOODSCONSUMPTION_SUCCESS,
    UPDATE_GOODSCONSUMPTION_FAILURE,
    DELETE_GOODSCONSUMPTION,
    DELETE_GOODSCONSUMPTION_SUCCESS,
    DELETE_GOODSCONSUMPTION_FAILURE,
    APPROVE_GOODSCONSUMPTION,
    APPROVE_GOODSCONSUMPTION_SUCCESS,
    APPROVE_GOODSCONSUMPTION_RESET,
    APPROVE_GOODSCONSUMPTION_FAILURE,
    CHANGE_INVOICEITEMREMOTESYSTEMSYNCSTARTED
} from '../actions/constants'

export function* getAllGoodsConsumptions() {
    var api = new GoodsConsumptionApi();
    try {
        const response = yield call(() => api.getConsumptions());
        yield put({ type: GET_GOODSCONSUMPTION_SUCCESS, data: response.data });
    } catch (err) {
        yield put({ type: GET_GOODSCONSUMPTION_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* getGoodsConsumptionsByProject(action) {
    var api = new ProjectApi();
    try {
        let data = action.payload;

        const response = yield call(() => api.getGoodsConsumptionByProject(data.projectId));
        yield put({ type: GET_GOODSCONSUMPTION_SUCCESS, data: response.data });
    } catch (err) {
        yield put({ type: GET_GOODSCONSUMPTION_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* createGoodsConsumption(action) {
    var api = new GoodsConsumptionApi();

    try {
        let data = action.payload;

        let response = yield call(() => api.createConsumption(data));
        yield put({ type: CREATE_GOODSCONSUMPTION_SUCCESS, data: response.data });

    } catch (err) {
        yield put({ type: CREATE_GOODSCONSUMPTION_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* updateGoodsConsumption(action) {
    var api = new GoodsConsumptionApi();

    try {
        let data = action.payload;

        yield call(() => api.updateConsumption(data));
        yield put({ type: UPDATE_GOODSCONSUMPTION_SUCCESS, data: data });

    } catch (err) {
        yield put({ type: UPDATE_GOODSCONSUMPTION_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* approveGoodsConsumption(action) {
    let api = new GoodsConsumptionApi(); 
  
    try {
      let goodsData = action.payload.consumptionApproveData;
      let startSpinner = action.payload.startSpinner;

      for (let item in goodsData) {
          let dataItem =  goodsData[item];  
          
          if (dataItem.approve === true) {            
            if (startSpinner) {                                           
                yield put ({type : CHANGE_INVOICEITEMREMOTESYSTEMSYNCSTARTED, data : [dataItem.goodsConsumptionId]});
            }              
            
            yield call(() => api.approveGoodsConsumption(dataItem.goodsConsumptionId));            
          } else {
            yield call(() => api.unApproveGoodsConsumption(dataItem.goodsConsumptionId));    
          }    
      }        
      yield put({ type: APPROVE_GOODSCONSUMPTION_SUCCESS, data: {goodsData} });
      yield put({ type: APPROVE_GOODSCONSUMPTION_RESET, data: {} });
    } catch (err) {        
      yield put({ type: APPROVE_GOODSCONSUMPTION_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
  }
  

export function* deleteGoodsConsumption(action) {
    var api = new GoodsConsumptionApi();

    try {
        let data = action.payload;

        yield call(() => api.deleteConsumption(data.goodsConsumptionId));
        yield put({ type: DELETE_GOODSCONSUMPTION_SUCCESS, data: data.goodsConsumptionId });

    } catch (err) {
        yield put({ type: DELETE_GOODSCONSUMPTION_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}


export function* watchGetAllGoodsConsumptions() {
    yield takeLatest(GET_GOODSCONSUMPTION, getAllGoodsConsumptions);
}

export function* watchGetGoodsConsumptionsByProject() {
    yield takeLatest(GET_GOODSCONSUMPTIONBYPROJECT, getGoodsConsumptionsByProject);
}

export function* watchApproveGoodsConsumption() {
    yield takeLatest(APPROVE_GOODSCONSUMPTION, approveGoodsConsumption);
}

export function* watchGoodsConsumptionCreate() {
    yield takeLatest(CREATE_GOODSCONSUMPTION, createGoodsConsumption);
}

export function* watchGoodsConsumptionUpdate() {
    yield takeLatest(UPDATE_GOODSCONSUMPTION, updateGoodsConsumption);
}

export function* watchDeleteConsumptionUpdate() {
    yield takeLatest(DELETE_GOODSCONSUMPTION, deleteGoodsConsumption);
}

