import { put, takeLatest, call } from 'redux-saga/effects'
import { EquipmentTypeApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import { 
  GET_EQUIPMENTTYPE,
  GET_EQUIPMENTTYPE_SUCCESS, 
  GET_EQUIPMENTTYPE_FAILURE,    
  CREATE_EQUIPMENTTYPE,
  CREATE_EQUIPMENTTYPE_SUCCESS,
  CREATE_EQUIPMENTTYPE_FAILURE,
  DELETE_EQUIPMENTTYPE_SUCCESS,
  DELETE_EQUIPMENTTYPE_FAILURE,
  DELETE_EQUIPMENTTYPE,
  UPDATE_EQUIPMENTTYPE_SUCCESS,
  UPDATE_EQUIPMENTTYPE_FAILURE,
  UPDATE_EQUIPMENTTYPE  
 } from '../actions/constants' 

export function* getAllEquipmentTypes() {
  var api = new EquipmentTypeApi();

  try {          
    const response = yield call(() => api.getEquipmentTypes());    
    
    yield put({ type: GET_EQUIPMENTTYPE_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: GET_EQUIPMENTTYPE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createEquipmentType(action) {
    var api = new EquipmentTypeApi();
  
    try {                
      var equipment = action.payload;          
      
      const response = yield call(() => api.createEquipmentType(equipment));              
      
      yield put({ type: CREATE_EQUIPMENTTYPE_SUCCESS, data: response.data });
    } catch(err) {        
      yield put({ type: CREATE_EQUIPMENTTYPE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
    }  
  }
  

export function* deleteEquipmentType(action) {
  var api = new EquipmentTypeApi();

  try {                
    var equipmentId = action.payload;
        
    yield call(() => api.deleteEquipmentType(equipmentId));            

    yield put({ type: DELETE_EQUIPMENTTYPE_SUCCESS, data: equipmentId });
  } catch(err) {        
    yield put({ type: DELETE_EQUIPMENTTYPE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* updateEquipmentType(action) {
  var api = new EquipmentTypeApi();

  try {                    
    var data = action.payload;        
    yield call(() => api.updateEquipmentType(data));            
    
    yield put({ type: UPDATE_EQUIPMENTTYPE_SUCCESS, data: data }); 
  } catch(err) {        
    yield put({ type: UPDATE_EQUIPMENTTYPE_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* watchEquipmentTypes() {
  yield takeLatest(GET_EQUIPMENTTYPE, getAllEquipmentTypes);
}

export function* watchDeleteEquipmentType() {
  yield takeLatest(DELETE_EQUIPMENTTYPE, deleteEquipmentType);
}

export function* watchUpdateEquipmentType() {
  yield takeLatest(UPDATE_EQUIPMENTTYPE, updateEquipmentType);
}

export function* watchCreateEquipmentType() {
    yield takeLatest(CREATE_EQUIPMENTTYPE, createEquipmentType);
  }