import {
    CREATE_INTEGRATION_PUSHREQUEST,
    CREATE_INTEGRATION_PUSHREQUEST_SUCCESS,
    CREATE_INTEGRATION_PUSHREQUEST_FAILURE,
    CREATE_INTEGRATION_PULLREQUEST,
    CREATE_INTEGRATION_PULLREQUEST_SUCCESS,
    CREATE_INTEGRATION_PULLREQUEST_FAILURE,
    RESET_INTEGRATION_ISALIVE_SUCCESS,
    GET_INTEGRATION_ISALIVE,
    GET_INTEGRATION_ISALIVE_SUCCESS,
    GET_INTEGRATION_ISALIVE_FAILURE,
    GET_INTEGRATION_ACTIVITY_FAILURE,
    GET_INTEGRATION_ACTIVITY_SUCCESS,
    GET_INTEGRATION_ACTIVITY,
    GET_INTEGRATION_PROJECT_FAILURE,
    GET_INTEGRATION_PROJECT_SUCCESS,
    GET_INTEGRATION_PROJECT,
    CREATE_INTEGRATIONPROJECTCONNECT,
    CREATE_INTEGRATIONPROJECTCONNECT_SUCCESS,
    CREATE_INTEGRATIONPROJECTCONNECT_FAILURE,
    CREATE_INTEGRATION_ACTIVITY,
    CREATE_INTEGRATION_ACTIVITY_FAILURE,
    CREATE_INTEGRATION_ACTIVITY_SUCCESS,
    CREATE_INTEGRATION_PROJECT,
    CREATE_INTEGRATION_PROJECT_FAILURE,
    CREATE_INTEGRATION_PROJECT_SUCCESS,
    CREATE_INTEGRATION_EMPLOYEE,
    CREATE_INTEGRATION_EMPLOYEE_FAILURE,
    CREATE_INTEGRATION_EMPLOYEE_SUCCESS,
    GET_INTEGRATION_EMPLOYEE,
    GET_INTEGRATION_EMPLOYEE_FAILURE,
    GET_INTEGRATION_EMPLOYEE_SUCCESS,
    GET_INTEGRATION_SALARY,
    GET_INTEGRATION_SALARY_FAILURE,
    GET_INTEGRATION_SALARY_SUCCESS,
    GET_INTEGRATION_CUSTOMER,
    GET_INTEGRATION_CUSTOMER_FAILURE,
    GET_INTEGRATION_CUSTOMER_SUCCESS,
    INTEGRATION_EXPORTHOUR,
    INTEGRATION_EXPORTHOUR_SUCCESS,
    INTEGRATION_EXPORTHOUR_FAILURE
  } from '../actions/constants'
  
  const initialState = {
    data: [],
    activities : [],
    projects : [],  
    employees : [],
    salaries : [],
    customers : [],
    error: null,
    loading: false,
    connectionOk : null,
    success : null,
    activitySaved : false,
    projectSaved : false,
    employeeSaved : false,
  };
  
  
  export const integrationReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_INTEGRATION_ISALIVE_SUCCESS: {        
        return {...state, ...{loading: false, data : '', error: '', connectionOk : true}} 
      }
      case GET_INTEGRATION_ACTIVITY_SUCCESS: {        
        return {...state, ...{loading: false, data : '', error: '', activities : action.data}}
      }
      case GET_INTEGRATION_PROJECT_SUCCESS: {        
        return {...state, ...{loading: false, data : '', error: '', projects : action.data}}
      }
      case GET_INTEGRATION_EMPLOYEE_SUCCESS: {
        return {...state, ...{loading: false, data : '', error: '', employees : action.data}}
      }
      case GET_INTEGRATION_SALARY_SUCCESS: {
        return {...state, ...{loading: false, data : '', error: '', salaries : action.data}}
      }
      case GET_INTEGRATION_CUSTOMER_SUCCESS: {
        return {...state, ...{loading: false, data : '', error: '', customers : action.data}}
      }      
      case GET_INTEGRATION_EMPLOYEE:
      case GET_INTEGRATION_CUSTOMER:
      case GET_INTEGRATION_SALARY:
      case GET_INTEGRATION_ACTIVITY:        
      case GET_INTEGRATION_PROJECT:
      case INTEGRATION_EXPORTHOUR:
      case CREATE_INTEGRATION_EMPLOYEE:           
      case CREATE_INTEGRATION_PROJECT:
      case CREATE_INTEGRATION_ACTIVITY:
      case CREATE_INTEGRATION_PUSHREQUEST:
      case CREATE_INTEGRATION_PULLREQUEST:
      case CREATE_INTEGRATIONPROJECTCONNECT:      
        return {...state, ...{loading: true, error: null, success : null, activitySaved : false, projectSaved : false, employeeSaved : false}}      
      case GET_INTEGRATION_ISALIVE:        
        return {...state, ...{loading: true, error: null, connectionOk : null}} 
      case RESET_INTEGRATION_ISALIVE_SUCCESS: {
        return {...state, ...{connectionOk : null}} 
      }          
      case CREATE_INTEGRATION_PUSHREQUEST_SUCCESS :      
      case CREATE_INTEGRATION_PULLREQUEST_SUCCESS :    
        return {...state, ...{loading: false, data : action.data.logs, success : action.data.success, error: ''}}                 
      case CREATE_INTEGRATION_ACTIVITY_SUCCESS: {
        let copy = [...state.activities];
        copy.push(action.data);        
        
        return {...state, ...{loading: false, error: '', activities : copy, activitySaved : true}}                 
      }
      case CREATE_INTEGRATION_PROJECT_SUCCESS: {
        let copy = [...state.projects];
        copy.push(action.data);        
       
        return {...state, ...{loading: false, error: '', projects : copy, projectSaved : true}}                         
      }
      case CREATE_INTEGRATION_EMPLOYEE_SUCCESS: {
        let newEmployeeArr = [...state.employees];
        newEmployeeArr.push(action.data);        

        return {...state, ...{loading: false, error: '', employees : newEmployeeArr, employeeSaved : true}}                                 
      }
      case CREATE_INTEGRATIONPROJECTCONNECT_SUCCESS: {
        return {...state, ...{loading: false, error: ''}}
      }

      case INTEGRATION_EXPORTHOUR_SUCCESS: {
        return {...state, ...{loading: false, error: ''}}
      }

      case INTEGRATION_EXPORTHOUR_FAILURE:
      case GET_INTEGRATION_EMPLOYEE_FAILURE:
      case CREATE_INTEGRATION_EMPLOYEE_FAILURE:
      case CREATE_INTEGRATION_PROJECT_FAILURE:
      case CREATE_INTEGRATION_ACTIVITY_FAILURE:
      case CREATE_INTEGRATION_PUSHREQUEST_FAILURE:     
      case CREATE_INTEGRATION_PULLREQUEST_FAILURE:                          
      case GET_INTEGRATION_ISALIVE_FAILURE:
      case GET_INTEGRATION_ACTIVITY_FAILURE:
      case GET_INTEGRATION_SALARY_FAILURE:
      case GET_INTEGRATION_PROJECT_FAILURE:
      case GET_INTEGRATION_CUSTOMER_FAILURE:
      case CREATE_INTEGRATIONPROJECTCONNECT_FAILURE:          
        return {...state, loading: false, error : action.data.error, connectionOk : false, activitySaved : false, projectSaved : false, employeeSaved : false };      
      default:      
        return state;
    }
  };