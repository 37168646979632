import axiosConfig from '../axiosConfig';

export default class GoodsApi {
    constructor() {
        this.endpoint = `/Goods/`;
    }

    async getGoods() {
        try {          
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }       

    async getGoodsCategories() {
        try {          
            return axiosConfig.get(this.endpoint + "categories");
        }
        catch (error) {
            throw error;
        }        
    }       
    
    async createGoods(goods) {
        try {          
            return axiosConfig.post(this.endpoint, goods);
        }
        catch (error) {
            throw error;
        }        
    }          

    async createGoodsPrice(goodsId, priceObject) {
        try {          
            return axiosConfig.post(`${this.endpoint}${goodsId}/price`, priceObject);
        }
        catch (error) {
            throw error;
        }        
    }          

    async updateGoodsPrice(goodsId, priceObject) {
        try {          
            return axiosConfig.put(`${this.endpoint}${goodsId}/${priceObject.goodsPriceId}/price`, priceObject);
        }
        catch (error) {
            throw error;
        }        
    }          

    async createGoodsCategory(goodsCategory) {
        try {          
            return axiosConfig.post(this.endpoint + "categories", goodsCategory);
        }
        catch (error) {
            throw error;
        }        
    }          
    
    async deleteGoods(goodsId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}${goodsId}`, {
                GoodsId : goodsId            
            });
        }
        catch(error) {
            throw error;
        }        
    }    
    
    async updateGoods(goods) {
        try {
            return axiosConfig.put(`${this.endpoint}${goods.goodsId}`, goods);
        }
        catch(error) {
            throw error;
        }        
    }

    async updateGoodsCategory(goodsCategory) {
        try {
            return axiosConfig.put(`${this.endpoint}categories/${goodsCategory.goodsCategoryId}`, goodsCategory);
        }
        catch(error) {
            throw error;
        }        
    }

    async deleteGoodsCategory(goodsCategoryId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}categories/${goodsCategoryId}`, {                
            });
        }
        catch(error) {
            throw error;
        }        
    }    
}
