import { SET_TOPMENUTEXT } from '../actions/constants'

const initialState = {    
    text: ''    
  };  
  
  export const topMenuReducer = (state = initialState, action) => {  
    switch (action.type) {
      case SET_TOPMENUTEXT:    
        return {...state, ...{text: action.payload.text}};            
      default:       
        return state;
    }
  };