import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class ReportApi {
    constructor() {
        this.endpoint = `/Report`;
    }

    async createEmployeeReport(columns, guids) {
        try {
            var url = `${this.endpoint}/employee`
            if (guids) {
                url += `?guids=${guids}`;
            }

            return axiosConfig.post(url, {
                "Columns" : columns,
                "IsLandscape" : true,
                "UseLogo" : false
            });
        }
        catch(error) {
            throw error;
        }
    }
}

mock.onPost("/Report").reply(200, {    
});
