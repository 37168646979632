import ability  from '../config/ability'
import { AbilityBuilder, Ability } from '@casl/ability';
import groupBy from 'lodash/groupBy'

export const checkAccess = (resource, access) => {  
    if (!resource || !access) return true;    

    let haveAccess = access.length > 0;
    if (!haveAccess) return false;

    for(let itemAccess of access) {
      let accessGiven = ability.can(itemAccess, resource);    
      haveAccess = haveAccess && accessGiven;      
    }      

    return haveAccess;
  }

export const hasFeatureAccess = (name) => {  
    if (!name) return true;    
    
    let hasFeature = ability.can("access", name)        
    return hasFeature;
}

export const setLoggedInUsersAbilities = (abilities, modules) => {  
    const { can, rules } = new AbilityBuilder(Ability);    
  
    var groupedAbilities = groupBy(abilities, ability => {
        return ability.group;
    });
  
    for (const [, value] of Object.entries(groupedAbilities)) {
        var permissionArr = value;               
        for (let i=0; i <permissionArr.length; ++i) {                
            let perm = permissionArr[i];
  
            can(perm.permission, perm.group);            
        }                        
    }        

    for (let accessibleModule of modules) {
      can("access", accessibleModule.name)
    }
  
    ability.update(rules);        
  }    