import {
        GET_GOODS,
        GET_GOODS_FAILURE,
        GET_GOODS_SUCCESS,
        CREATE_GOODS,
        CREATE_GOODS_SUCCESS,
        CREATE_GOODS_FAILURE,
        UPDATE_GOODS,
        UPDATE_GOODS_FAILURE,
        UPDATE_GOODS_SUCCESS,
        DELETE_GOODS,
        DELETE_GOODS_FAILURE,
        DELETE_GOODS_SUCCESS,
        GET_GOODSCATEGORIES,
        GET_GOODSCATEGORIES_SUCCESS,
        GET_GOODSCATEGORIES_FAILURE,
        CREATE_GOODSCATEGORIES,
        CREATE_GOODSCATEGORIES_SUCCESS,
        CREATE_GOODSCATEGORIES_FAILURE,
        UPDATE_GOODSCATEGORIES,
        UPDATE_GOODSCATEGORIES_FAILURE,
        UPDATE_GOODSCATEGORIES_SUCCESS,
        DELETE_GOODSCATEGORIES,
        DELETE_GOODSCATEGORIES_SUCCESS,
        DELETE_GOODSCATEGORIES_FAILURE,
        CREATE_GOODS_PRICE,
        CREATE_GOODS_PRICE_SUCCESS,
        CREATE_GOODS_PRICE_FAILURE

} from '../actions/constants'

const initialState = {
        goods: [],
        categories: [],
        error: null,
        loading: false,
        goodsSaved: false,
        goodsDeleted : false,
        categorySaved: false,
        categoryDeleted : false
};

export const goodsReducer = (state = initialState, action) => {
        switch (action.type) {
                case CREATE_GOODS_PRICE:
                case GET_GOODS:
                case CREATE_GOODSCATEGORIES:
                case GET_GOODSCATEGORIES:
                case CREATE_GOODS:
                case UPDATE_GOODS:
                case UPDATE_GOODSCATEGORIES:
                case DELETE_GOODS:
                case DELETE_GOODSCATEGORIES:
                        return { ...state, ...{ loading: true, error: null, goodsSaved: false, categorySaved: false, goodsDeleted : false, categoryDeleted : false } }
                case GET_GOODS_FAILURE:
                case GET_GOODSCATEGORIES_FAILURE:
                case DELETE_GOODS_FAILURE:
                case DELETE_GOODSCATEGORIES_FAILURE:
                case UPDATE_GOODS_FAILURE:
                case UPDATE_GOODSCATEGORIES_FAILURE:
                case CREATE_GOODS_FAILURE:
                case CREATE_GOODSCATEGORIES_FAILURE:
                case CREATE_GOODS_PRICE_FAILURE:
                        return { ...state, loading: false, error: action.data.error, goodsSaved: false, categorySaved: false, goodsDeleted : false };
                case GET_GOODS_SUCCESS: {
                        return { ...state, ...{ loading: false, goods: action.data, error: '', goodsSaved: false } };
                }
                case CREATE_GOODS_SUCCESS: {                       
                        return { ...state, ...{ loading: false, error: '', goodsSaved: true } }
                }
                case UPDATE_GOODS_SUCCESS: {                        
                        return { ...state, ...{ loading: false, error: '', goodsSaved: true } }
                }
                case DELETE_GOODS_SUCCESS: {
                        let deletedGoods = state.goods.filter(item => item.goodsId !== action.data);
                        return { ...state, ...{ loading: false, error: '', goods : deletedGoods, goodsSaved: false, goodsDeleted : true } }
                }
                case GET_GOODSCATEGORIES_SUCCESS: {
                        return { ...state, ...{ loading: false, categories: action.data, error: '' } };
                }
                case CREATE_GOODSCATEGORIES_SUCCESS: {
                        let categories = [...state.categories];                        
                        categories.splice(0, 0, action.data);
                        return { ...state, ...{ loading: false, categories: categories, error: '', categorySaved: true } };
                }
                case UPDATE_GOODSCATEGORIES_SUCCESS: {
                        let newArr = [...state.categories];
                        let index = newArr.findIndex(item => item.goodsCategoryId === action.data.goodsCategoryId);
                        
                        if (index >= 0)
                                newArr.splice(index, 1, action.data)
                        
                        return { ...state, ...{ loading: false, categories: newArr, error: '', categorySaved: true } };
                }
                case DELETE_GOODSCATEGORIES_SUCCESS: {
                        let deletedArr = state.categories.filter(item => item.goodsCategoryId !== action.data);                           
                                                
                        return { ...state, ...{ loading: false, categories: deletedArr, error: '', categorySaved: false, categoryDeleted : true } };                        
                }
                case CREATE_GOODS_PRICE_SUCCESS : {                        
                        return { ...state, ...{ loading: false, error: '', goodsSaved : true } };
                }
                default:
                        return state;
        }
};