import { GET_SECURITYTOKEN, GET_SECURITYTOKEN_SUCCESS, GET_SECURITYTOKEN_FAILURE, SET_SECURITYTOKEN_RESET, SECURITYTOKEN_REHYDRATE, USER_LOGOUT } from '../actions/constants'
import { put, takeLatest, call, select } from 'redux-saga/effects'
import { SecurityApi, CompanyApi } from '../api'
import { TOKEN, TOKENPERSIST_ROOT } from '../globals'
import { setLoginInProgress } from '../../src/utils/globalDataUtil'
import { getCurrentUser, getCurrentUserFromStore } from './users'
import { setLoggedInUsersAbilities } from '../access/auth'
import { stopSignalRConnection } from '../singnalR'

export const getSecurityContextFromStore = state => state.securityTokenReducer.context;

export function* getSecurityToken(action) {
  let securityApi = new SecurityApi();  
  let companyApi = new CompanyApi();  

  try {
    setLoginInProgress(true);

    const response = yield call(() => securityApi.getToken(action.payload.username, action.payload.password));
    if (!response.data)
      throw new Error("Token cannot be emnpty");

    setLoginInProgress(false);

    localStorage.setItem(TOKEN, response.data.tokenKey);

    const contextResponse = yield call(() => securityApi.getContext());
    const abilities = yield call(() => securityApi.getAbilities());   

    let companyId = contextResponse.data.companyId;
    const modules = yield call(() => companyApi.getCompanyModulesAccess(companyId)); 
    
    setLoggedInUsersAbilities(abilities.data, modules.data);
    yield call(getCurrentUser, { payload: { userId: contextResponse.data.userId, abilities: abilities.data, modules : modules.data } })
    let user = yield select(getCurrentUserFromStore);   
    
    if (user.error || user.data.length === 0) {
      throw new Error("Cannot get user");
    } else {
      yield put({ type: GET_SECURITYTOKEN_SUCCESS, data: { abilities: abilities.data, modules : modules, context: contextResponse.data, tokenKey: response.data.tokenKey } });
    }
  } catch (err) {
    localStorage.setItem(TOKEN, "");
    yield put({ type: GET_SECURITYTOKEN_FAILURE, payload: "" });
    yield put({ type: SET_SECURITYTOKEN_RESET, payload: "" });
  }
}

export function userLogout() {
  try {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(TOKENPERSIST_ROOT);
    
    stopSignalRConnection();
  } catch(err) {
    console.log(err)
  }
}

export function* reHydrate() {  
  let user = yield select(getCurrentUserFromStore);  
  if (!user.abilities) return;  
  
  setLoggedInUsersAbilities(user.abilities, user.modules);  
}

export function* watchSecurityToken() {
  yield takeLatest(GET_SECURITYTOKEN, getSecurityToken);
}

export function* watchRehydrate() {
  yield takeLatest(SECURITYTOKEN_REHYDRATE, reHydrate);
}

export function* watchUserLogout() {
  yield takeLatest(USER_LOGOUT, userLogout);
}