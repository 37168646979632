import {v1 as uuid} from 'uuid'
import { GET_COMPANYPERMISSIONS_SUCCESS, GET_COMPANYPERMISSIONS_FAILURE, GET_COMPANYPERMISSIONS } from '../actions/constants'

const initialState = {
  permissions: [],
  roles: [],
  error: null,
  loading: false,
};


export const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANYPERMISSIONS:
      return { ...state, ...{ loading: true, error: null } }      
    case GET_COMPANYPERMISSIONS_SUCCESS: {      
      var allPemissions = action.data.permissions.map(item => {        
        return {id : uuid(), content : item.name, orgPermissionId : item.id}
      });   
            
      var roles = action.data.roles.map(item => {        
        var newPermissions = item.permissions != null ? Array.from(item.permissions) : [];
        return {id : item.id, name : item.name, permissions : newPermissions};
      });
      
      return { ...state, ...{ loading: false, error: null, permissions : allPemissions, roles : roles } }
    }
    case GET_COMPANYPERMISSIONS_FAILURE:      
      return { ...state, loading: false, error: "" };
    default:
      return state;
  }
};