import { 
  GET_ACTIVITY_SUCCESS, 
  GET_ACTIVITY_FAILURE, 
  GET_ACTIVITY,
  SAVE_ACTIVITY,
  SAVE_ACTIVITY_FAILURE,
  SAVE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_FAILURE,
  DELETE_ACTIVITY_SUCCESS,
  CHANGE_ACTIVITYITEM,
  CHANGE_ACTIVITYITEM_SUCCESS,
  CHANGE_ACTIVITYITEM_FAILURE,  
  RESET_ACTIVITYSAVED,
  RESET_ACTIVITYERROR
} from '../actions/constants'

const initialState = {
  data: [],
  error: null,
  loading: false,
  saved: false,
};

export const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY:
      return { ...state, ...{ loading: true, error: null } }
    case GET_ACTIVITY_SUCCESS:
      return { ...state, ...{ loading: false, data: action.data, error: '' } }
    case GET_ACTIVITY_FAILURE:
      return { ...state, loading: false, error: action.data.error };

    case CHANGE_ACTIVITYITEM:
      return { ...state, ...{ loading: true, error: null, saved: false } };
    case CHANGE_ACTIVITYITEM_FAILURE:
      return { ...state, loading: false, error: action.data.error, saved : false };
    case CHANGE_ACTIVITYITEM_SUCCESS:
      return { ...state, loading: false, error: action.data.error, saved : true };

    case SAVE_ACTIVITY:
      return { ...state, ...{ loading: true, error: null, saved: false } }
    case SAVE_ACTIVITY_SUCCESS: {    
      let item = action.data;       
      let newArr = state.data.concat(item);              
      return { ...state, ...{ loading: false, data : newArr, error: '', saved: true } }
    }
    case SAVE_ACTIVITY_FAILURE:
      return { ...state, loading: false, error: action.data.error, saved: false };

    case DELETE_ACTIVITY:
      return { ...state, ...{ loading: true, error: null, saved: false } }
    case DELETE_ACTIVITY_SUCCESS: {
      const list = state.data.filter(item => item.activityId !== action.data.id);      
      return {...state, ...{loading: false, error : '', data : list }};     
    }
    case DELETE_ACTIVITY_FAILURE:
      return { ...state, loading: false, error: action.data.error, saved: false };    

    case UPDATE_ACTIVITY:
      return { ...state, ...{ loading: true, error: null, saved: false } }
    case UPDATE_ACTIVITY_SUCCESS: {
      let newArr = [...state.data]; 
      let index = newArr.findIndex(item => item.activityId === action.data.activityId);
      
      if (index >= 0)
          newArr.splice(index, 1, action.data)

      return {...state, ...{loading: false, data : newArr, error : '', saved : true}};      
    }
    case UPDATE_ACTIVITY_FAILURE:
      return { ...state, loading: false, error: action.data.error, saved: false };
    case RESET_ACTIVITYSAVED: {
      return {...state, ...{saved : false}};      
    }
    case RESET_ACTIVITYERROR:
      return {...state, ...{saved : false, error: false, loading: false}};            
    default:
      return state;
  }
};

