import { 
    DELETE_DEPARTMENTS,
    DELETE_DEPARTMENTS_SUCCESS,
    DELETE_DEPARTMENTS_FAILURE,
    UPDATE_DEPARTMENTS,
    UPDATE_DEPARTMENTS_SUCCESS,
    UPDATE_DEPARTMENTS_FAILURE,
    CREATE_DEPARTMENTS,
    CREATE_DEPARTMENTS_SUCCESS,
    CREATE_DEPARTMENTS_FAILURE,
    GET_DEPARTMENTS_SUCCESS, 
    GET_DEPARTMENTS_FAILURE, 
    GET_DEPARTMENTS     
  } from '../actions/constants'
  
  const initialState = {  
    error: null,
    loading: false,
    saved : false,    
    data : [],    
  };
  
  export const departmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case DELETE_DEPARTMENTS:
      case UPDATE_DEPARTMENTS:
      case CREATE_DEPARTMENTS:
      case GET_DEPARTMENTS:      
        return { ...state, ...{ loading: true, error: null, saved : false } }          
      case GET_DEPARTMENTS_SUCCESS:      
        return { ...state, ...{ loading: false, error: null, data : action.data, saved : false } }
      case CREATE_DEPARTMENTS_SUCCESS: {          
          let newData = state.data.concat(action.data);          
          return { ...state, ...{ loading: false, error: null, data : newData, saved : true } }
      }
      case UPDATE_DEPARTMENTS_SUCCESS: {           
        let newArr = [...state.data]; 
        let index = newArr.findIndex(item => item.departmentId === action.data.departmentId);
  
        if (index >= 0)
            newArr.splice(index, 1, action.data)

        return { ...state, ...{ loading: false, error: null, data : newArr, saved : true } }
      }
      case DELETE_DEPARTMENTS_SUCCESS: {        
        const list = state.data.filter(item => item.departmentId !== action.data.departmentId);
        return { ...state, ...{ loading: false, error: null, data : list, saved : true } }
      }
      case DELETE_DEPARTMENTS_FAILURE:
      case UPDATE_DEPARTMENTS_FAILURE:
      case CREATE_DEPARTMENTS_FAILURE:
      case GET_DEPARTMENTS_FAILURE: {          
        return { ...state, ...{loading: false, error: action.data.error, saved : false }};
      }      
      default:
        return state;
    }
  }; 
  