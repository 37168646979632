import axiosConfig from '../axiosConfig';
import { mock } from '../mock'
import {v1 as uuid} from 'uuid'

export default class ProjectApi {
    constructor() {
        this.endpoint = `/Project`;
    }

    async getAllProjectsForCompany() {
        try {
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }

    async getAllEmployeesAtProject(projectId) {
        try {
            return axiosConfig.get(`${this.endpoint}/${projectId}/checkin`);
        }
        catch (error) {
            throw error;
        }        
    }

    async getAllObservationForProjects(project) {
        try {                      
            return axiosConfig.get(`${this.endpoint}/${project.projectId}/observation`);
        }
        catch (error) {
            throw error;
        }        
    }

    async getProjectDocuments(projectId) {
        try {
            return axiosConfig.get(`${this.endpoint}/${projectId}/report`);
        }
        catch (error) {
            throw error;
        }        
    }    

    async getProjectConsumptions(projectId) {
        try {
            return axiosConfig.get(`${this.endpoint}/${projectId}/consumption`);
        }
        catch (error) {
            throw error;
        }        
    }    

    async getProjectMachineHours(projectId) {
        try {
            return axiosConfig.get(`${this.endpoint}/${projectId}/machine`);
        }
        catch (error) {
            throw error;
        }        
    }    

    async getGoodsConsumptionByProject(projectId) {
        try {
            return axiosConfig.get(`${this.endpoint}/${projectId}/consumption`);
        }
        catch (error) {
            throw error;
        }        
    }    

    async getProjectHours(projectId) {
        try {
            return axiosConfig.get(`${this.endpoint}/${projectId}/hour`);
        }
        catch (error) {
            throw error;
        }        
    }    
    
    async createNewProject(project) {
        try {
            return axiosConfig.post(`${this.endpoint}`, project);
        }
        catch(error) {
            throw error;
        }
    }

    async createNewProjectDocument(projectId, type, friendlyName) {
        try {
            return axiosConfig.post(`${this.endpoint}/${projectId}/report?type=${type}&friendlyName=${friendlyName}`);
        }
        catch(error) {
            throw error;
        }
    }    

    async deleteProjectById(projectId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}/${projectId}`);
        }
        catch(error) {
            throw error;
        }        
    }

    async updateProject(project) {
        try {                        
            return axiosConfig.put(`${this.endpoint}/${project.projectId}`, project)
        }
        catch(error) {
            throw error;
        }        
    }
}

mock.onPost("/Project").reply(200, {
        projectId: uuid(),
        companyId: "112121-121212-2211",
        mainProjectRef: "",
        name: "ProjectXXX",
        latitude: "60.34",
        longitude: "7.2121",
        internal: "false",
        closed: "false",
        active: "true",
        address: "Steinfossveien 82",
        location: "Bergen",
        zipCode: "5098",
        startDate : "2020-01-01",
        endDate : "2020-04-31",
        description : "Dette er en beskrivelse av prosjektet",
        manager : {
            id : "1212121212-1212-44343",
            name : "Oyvind Prosjektleder"            
        },
        category : {
            id : "3434343-4343434-343434",
            name : "Standard oppdrag"
        },
        customer : {
            id : "1212-1212-111245",
            name : "Kunde 1",
            orgNumber : "12345678",
            address : "LilleRuisten14",
            zipCode : "9988",
            place : "Alta"
        }
});

mock.onPut(/\Project\/.+/).reply(200, {
    projectId: "112121-121212-111",
    companyId: "112121-121212-2211",
    mainProjectRef: "",
    name: "ProjectXXX",
    latitude: "60.34",
    longitude: "7.2121",
    internal: "false",
    closed: "false",
    active: "true",
    address: "Steinfossveien 82",
    location: "Bergen",
    zipCode: "5098",
    startDate : "2020-01-01",
    endDate : "2020-04-31",
    description : "Dette er en beskrivelse av prosjektet",
    manager : {
        id : "1212121212-1212-44343",
        name : "Oyvind Prosjektleder Changed"            
    },
    category : {
        id : "3434343-4343434-343434",
        name : "Standard oppdrag"
    },
    customer : {
        id : "1212-1212-111245",
        name : "Kunde 1"
    }
});


mock.onDelete("Project/").reply(200);

mock.onGet("Project").reply(200,
    [{
        projectId: "ddeb27fb-d9a0-4624-be4d-4615062daed5",
        companyId: "112121-121212-2211",
        mainProjectRef: "",
        name: "Project1",
        latitude: "60.34",
        longitude: "7.2121",
        internal: "false",
        closed: "false",
        active: "true",
        address: "Steinfossveien 12",
        location: "Bergen",
        zipCode: "5098",
        startDate : "2020-01-01",
        endDate : "2020-04-31",
        description : "Dette er en beskrivelse av prosjektet",
        manager : {
            id : "1212121212-1212-44343",
            name : "Oyvind Prosjektleder"            
        },
        category : {
            id : "3434343-4343434-343434",
            name : "Standard oppdrag"
        },
        customer : {
            id : "1212-1212-111245",
            name : "Kunde 1",
            orgNumber : "12345678",
            address : "LilleRuisten14",
            zipCode : "9988",
            place : "Alta"
        }
    },
    {
        projectId: "112121-121212-333",
        companyId: "112121-121212-3311",
        mainProjectRef: "",
        name: "Project2",
        latitude: "20.34",
        longitude: "17.2121",
        internal: "false",
        closed: "false",
        active: "true",
        address: "LilleRusten 12",
        location: "Bergen",
        zipCode: "5098",
        startDate : "2019-05-01",
        endDate : "2020-01-31",
        description : "Dette er en beskrivelse av prosjektet",
        manager : {
            id : "1212121212-1212-44313",
            firstName : "Oyvind2",
            lastName : "Prosjektleder"
        },
        category : {
            id : "3434343-4343434-3467434",
            name : "Standard oppdrag"
        },
        customer : {
            id : "1212-1212-111245",
            name : "Kunde 2"
        }
    }]
);

mock.onGet(/\/Project\/.+\/report/).reply(200, [
    {
        reportId : '98612121-121212-333',
        projectId: "112121-121212-333",
        generatedDate : "2020-01-02'",
        url : 'http://www.vg.no',
        fileId : '1212121212-1212-44313',
        fileType : ".docx"
    },
    {
        reportId : '9912121-121212-333',
        projectId: "2212121-121212-333",
        generatedDate : "2020-02-02'",
        url : 'http://www.db.no',
        fileId : '1212121212-1212-44313',
        fileType : ".docx"
    }
]);

mock.onPost(/\/Project\/.+\/report/).reply(200, {});


