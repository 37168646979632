import axiosConfig from '../axiosConfig';

export default class GoodsConsumptionApi {
    constructor() {
        this.endpoint = `/GoodsConsumption/`;
    }

    async getConsumptions() {
        try {          
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }           

    async createConsumption(consumption) {
        try {          
            return axiosConfig.post(this.endpoint, consumption);
        }
        catch (error) {
            throw error;
        }        
    }           

    async updateConsumption(consumption) {
        try {          
            return axiosConfig.put(`${this.endpoint}${consumption.goodsConsumptionId}`, consumption);
        }
        catch (error) {
            throw error;
        }        
    }           

    async approveGoodsConsumption(goodsConsumptionId) {
        try {          
            return axiosConfig.patch(`${this.endpoint}${goodsConsumptionId}/approved`);
        }
        catch (error) {
            throw error;
        }        
    }           

    async unApproveGoodsConsumption(goodsConsumptionId) {
        try {          
            return axiosConfig.patch(`${this.endpoint}${goodsConsumptionId}/unapproved`);
        }
        catch (error) {
            throw error;
        }        
    }           
    
    async deleteConsumption(consumptionId) {
        try {          
            return axiosConfig.delete(`${this.endpoint}${consumptionId}`);
        }
        catch (error) {
            throw error;
        }        
    }           
    
}
