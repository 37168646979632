import { 
    GET_HOURS, 
    GET_HOURBANK,
     DELETE_HOUR, 
     CREATE_HOUR, 
     SAVE_HOURS, 
     CHANGE_HOURITEM, 
     GET_HOURSBETWEENDATES, 
     APPROVE_HOUR, 
     CLEAR_HOURITEMSTATE, 
     CHANGE_HOURITEMREMOTESYSTEMSTATE,
     CREATE_ABSENCEHOUR, 
     GET_PROJECTHOURS, 
     RESET_HOURSAVED,
     RESET_APPROVESAVED} from './constants'
import { format } from 'date-fns'


export function approveHour(hourApproveData, startSpinner = false) {    
    return {
        type : APPROVE_HOUR,
        payload : {hourApproveData, startSpinner}
    }
}

export function getHoursForWeekInDate(dateWithinSelectedWeek, employeeId) {       
    return {
        type : GET_HOURS,
        payload : { date : new Date(dateWithinSelectedWeek), employeeId }
    }
}

export function getHoursBetweenDates(fromDate, toDate) {    
    return {
        type : GET_HOURSBETWEENDATES,
        payload : { fromDate, toDate }
    }
}

export function getHourBankBewteen() {
    return {
        type : GET_HOURBANK,
        payload : { }
    }
}

export function getProjectHours(projectId) {
    return {
        type : GET_PROJECTHOURS,
        payload : { projectId }
    }
}

export function deleteHoursById(hourId) {    
    return {
        type : DELETE_HOUR,
        payload : { hourId}
    }
}

export function addNewHour(hour) {    
    return {
        type : CREATE_HOUR,
        payload : { hour}
    }
}

export function addNewAbsenceHours(hourArr) {    
    return {
        type : CREATE_ABSENCEHOUR,
        payload : { hourArr }
    }
}

export function updateHour(hour) {    
    return {
        type : CHANGE_HOURITEM,
        payload : hour
    }
}

export function saveHours(currentDate, hours, deletedHours) {       
    let dtString = format(currentDate, 'MMddyyyy');   
    
    return {
        type : SAVE_HOURS,
        payload : { currentDate, hours, currWeekYear : dtString, deletedHours}
    }
}

export function changeHourItem(id, key, val, arr) {
    return {
        type : CHANGE_HOURITEM,
        payload : { id: id, key : key, val : val, arr : arr}
    }
}

export function clearHourState() {
    return {
        type : CLEAR_HOURITEMSTATE,
        payload : { }
    }
}

export function resetSavedFlag() {
    return {
        type : RESET_HOURSAVED,
        payload : {  }
    }    
}

export function resetApproveFlag() {
    return {
        type : RESET_APPROVESAVED,
        payload : {  }
    }    
}

export function changeHourStatusForExternalSystem(hourRemoteStatus) {
    const hourStatus = JSON.parse(hourRemoteStatus);

    return {
        type : CHANGE_HOURITEMREMOTESYSTEMSTATE,
        payload : { hourId : hourStatus.hourId, externalSystemRef : hourStatus.externalSystemRef, errorMessage : hourStatus.errorMessage }
    }    
}
