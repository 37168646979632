import { put, takeLatest, call} from 'redux-saga/effects'
import { NewsApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import { 
  GET_NEWS, 
  GET_NEWS_SUCCESS,
  GET_NEWS_FAILURE,
  CREATE_NEWS,  
  CREATE_NEWS_SUCCESS,  
  CREATE_NEWS_FAILURE,  
  DELETE_NEWS, 
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAILURE,  
  UPDATE_NEWS,    
  UPDATE_NEWS_SUCCESS,    
  UPDATE_NEWS_FAILURE,    
} from '../actions/constants'

export function* getNews() {
  var api = new NewsApi();
  try {    
    //var data = action.payload;

    const response = yield call(() => api.getNews());
    yield put({ type: GET_NEWS_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_NEWS_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* createNewsEntry(action) {
  var api = new NewsApi();

  try {       
    var data = action.payload;       
    var response = yield call(() => api.createNewsEntry(data));    

    yield put({ type: CREATE_NEWS_SUCCESS, data: response.data}); 
  } catch(err) {    
    yield put({ type: CREATE_NEWS_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
  }  
}

export function* deleteNewsEntry(action) {
  var api = new NewsApi();

  try {                    
    var newsId = action.payload.newsId;    
    
    yield call(() => api.deleteNewsById(newsId));        
    
    yield put({ type: DELETE_NEWS_SUCCESS, data: { newsId } });
  } catch(err) {        
    yield put({ type: DELETE_NEWS_FAILURE, data: {error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* updateNewsEntry(action) {
  var api = new NewsApi();

  try {                    
    var news = action.payload;    

    yield call(() => api.updateNews(news));        
    
    yield put({ type: UPDATE_NEWS_SUCCESS, data: news }); 
  } catch(err) {            
    yield put({ type: UPDATE_NEWS_FAILURE, data:{ error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* watchGetNews() {
  yield takeLatest(GET_NEWS, getNews);
}

export function* watchNewsCreate() {
  yield takeLatest(CREATE_NEWS, createNewsEntry);
}

export function* watchNewsDelete() {
  yield takeLatest(DELETE_NEWS, deleteNewsEntry);
}

export function* watchNewsUpdate() {
  yield takeLatest(UPDATE_NEWS, updateNewsEntry);
}