import {
    CREATE_NOTIFICATION_SUCCESS,
    CREATE_NOTIFICATION_FAILURE, 
    CREATE_NOTIFICATION,     
    CREATE_ALERT_SUCCESS,
    MARK_NOTIFICATIONASREAD,
    MARK_NOTIFICATIONASREAD_SUCCESS,
    MARK_NOTIFICATIONASREAD_FAILURE
  } from '../actions/constants'  

  import {deepCopy} from '../utils/deepCopy'
  
  const initialState = {
    notifications: [],      
    error: null,    
  };  
  
  export const notificationReducer = (state = initialState, action) => {  
    switch (action.type) {
      case CREATE_NOTIFICATION:
      case MARK_NOTIFICATIONASREAD:          
        return {...state}              
      case CREATE_NOTIFICATION_SUCCESS: {                    
        //let notifications = state.notifications.push(action.data)    
        let notifications = [...state.notifications];
        let found = notifications.find(item => item.correlationId !== null &&  item.correlationId === action.data.correlationId && item.message === action.data.message);
        if (!found) {
          let item = {...action.data, read : false}
          notifications.push(item);
        }

        return {...state, ...{notifications : notifications}}              
      }
      case MARK_NOTIFICATIONASREAD_SUCCESS: {
        let copy = deepCopy(state.notifications);
        for (let noti of copy) {          
          noti.read = true;          
        }

        return {...state, ...{notifications : copy}}              
      }
      case CREATE_ALERT_SUCCESS:              
        state.alerts.push(action.data)      
        return {...state, ...{alerts : state.alerts}}              
      case CREATE_NOTIFICATION_FAILURE:            
      case MARK_NOTIFICATIONASREAD_FAILURE:
        return {...state}              
      default:      
        return state;
    }
  };