import { 
    CREATE_GOODSCONSUMPTION,
    GET_GOODSCONSUMPTION,    
    GET_GOODSCONSUMPTIONBYPROJECT,
    UPDATE_GOODSCONSUMPTION,
    DELETE_GOODSCONSUMPTION,
    APPROVE_GOODSCONSUMPTION,
    CHANGE_INVOICEITEMREMOTESYSTEMS
} from './constants'


export function getGoodsConsumptionsForCompany() {
    return {
        type : GET_GOODSCONSUMPTION,
        payload : {}
    }
}

export function getGoodsConsumptionsByProject(projectId) {
    return {
        type : GET_GOODSCONSUMPTIONBYPROJECT,
        payload : {projectId}
    }
}

export function createConsumption(goodsId, projectId, comment, date, numberConsumed) {
    return {
        type : CREATE_GOODSCONSUMPTION,
        payload : { goodsId, projectId, comment, date, numberConsumed}
    }
}

export function updateConsumption(updated) {
    return {
        type : UPDATE_GOODSCONSUMPTION,
        payload : updated
    }
}

export function deleteConsumption(goodsConsumptionId) {
    return {
        type : DELETE_GOODSCONSUMPTION,
        payload : { goodsConsumptionId}
    }
}

export function approveConsumption(consumptionApproveData, startSpinner = null) {    
    return {
        type : APPROVE_GOODSCONSUMPTION,
        payload : { consumptionApproveData, startSpinner }
    }
}

export function changeInvoiceStatusForExternalSystem(invoiceStatusStruct) {
    if (!invoiceStatusStruct) return; 
    
    const invoiceStatus = JSON.parse(invoiceStatusStruct);
    return {
        type : CHANGE_INVOICEITEMREMOTESYSTEMS,
        payload : { goodsConsumptionId : invoiceStatus.id, externalSystemRef : invoiceStatus.externalSystemRef, errorMessage : invoiceStatus.errorMessage }
    }    
}