import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class CustomerApi {
    constructor() {
        this.endpoint = `/Customer/`;
    }

    async getCustomers() {
        try {          
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }    
    
    async getCustomerLocations(customerId) {
        try {          
            return axiosConfig.get(`${this.endpoint}${customerId}/Location`);
        }
        catch (error) {
            throw error;
        }        
    }    

    async createCustomer(customer) {
        try {          
            return axiosConfig.post(this.endpoint, customer);
        }
        catch (error) {
            throw error;
        }        
    }      

    async createLocationForCustomer(customerId, locationName, parentId) {
        try {    
            return axiosConfig.post(`${this.endpoint}${customerId}/Location`, {
                CustomerId : customerId,
                Name : locationName,
                ParentId : parentId
            });
        }
        catch (error) {            
            throw error;
        }           
    }      

    async createPlaceAtLocationForCustomer(locationId, customerId, placeName) {
        try {               
            return axiosConfig.post(`${this.endpoint}${customerId}/Location`, {
                CustomerId : customerId,
                ParentId : locationId,
                Name : placeName
            });
        }
        catch (error) {
            throw error;
        }        
    }      

    async deletePlaceAtLocationForCustomer(customerId, locationId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}${customerId}/Location/${locationId}`, {               
            });
        }
        catch(error) {
            throw error;
        }        
    }    

    async deleteLocationForCustomer(customerId, locationId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}${customerId}/Location/${locationId}`, {
                LocationId : locationId            
            });
        }
        catch(error) {
            throw error;
        }        
    }    

    async deleteCustomer(customerId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}${customerId}`, {
                CustomerId : customerId            
            });
        }
        catch(error) {
            throw error;
        }        
    }

    async updateCustomerLocation(customerId, locationId, name) {
        try {
            return axiosConfig.put(`${this.endpoint}${customerId}/Location/${locationId}`, {                
                Name : name
            });
        }
        catch(error) {
            throw error;
        }        
    }

    async updateCustomer(customer) {
        try {
            return axiosConfig.put(`${this.endpoint}${customer.customerId}`, customer);
        }
        catch(error) {
            throw error;
        }        
    }
}

mock.onGet("Customer/").reply(200, [
    {
        customerId: '112121-12122-9911',
        companyId: '112121-121212-2211',
        name: 'Customer1',
        orgNumber: '12345678',
        address: 'LilleRusten 88',
        zipCode: '1345',
        place: 'Stryn'
    },
    {
        customerId: '112121-12122-9912',
        companyId: '112121-121212-2215',
        name: 'Customer2',
        orgNumber: '22345678',
        address: 'LilleRusten 11',
        zipCode: '13645',
        place: 'Bergen'
    }
]);

mock.onGet(/Customer\/.+\/Location/).reply(200, [
    {
        locationId: '512121-12122-9912',
        customerId: '112121-12122-9911',
        name: 'Bygg1',
        places: [
            {
                placeId: '412121-12122-1912',
                name: 'Kjeller'
            }
        ]
    },
    {
        locationId: '312121-12122-1912',
        name: 'Bygg2',
        customerId: '112121-12122-9911',
        places: [
            {
                placeId: '212121-12122-9932',
                name: 'Loft'
            }
        ]
    },
]);

mock.onPost(/Customer\/.+\/Location$/).reply(200, {
    locationId: '512121-12199-3912',
    customerId: '112121-12122-9911',
    name: 'ByggNytt',
});

// mock.onPost(/Customer\/.+\/Location\/.+\/Place$/).reply(200, {
//     placeId: '999999-12199-9912',
//     name: 'ToppEtasje',
// });

mock.onPost("Customer/").reply(200, {
        customerId: '112121-12122-9911',
        companyId: '112121-121212-2211',
        name: 'Customer1',
        orgNumber: '12345678',
        address: 'LilleRusten 88',
        zipCode: '1345',
        place: 'Stryn'
});

mock.onPut(/Customer\/.+\/Location/).reply(200, {
    locationId: '312121-12122-1912',
    customerId: '112121-12122-9911',
    name: 'ByggUpdated'
});

mock.onPut(/Customer\/.+/).reply(200, {
    customerId: '112121-12122-9911',
    companyId: '112121-121212-2211',
    name: 'Customer1XXXX',
    orgNumber: '12345678',
    address: 'LilleRusten 18',
    zipCode: '1345',
    place: 'Stryn'
});

mock.onDelete(/Customer\/.+/).reply(200);

