import axiosConfig from '../axiosConfig';

export default class MachineApi {
    constructor() {
        this.endpoint = `/Machine/`;
    }

    async getMachines() {
        try {          
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }        
    
    async createMachine(machine) {
        try {          
            return axiosConfig.post(this.endpoint, machine);
        }
        catch (error) {
            throw error;
        }        
    }      

    async deleteMachine(machineId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}${machineId}`, {                
            });
        }
        catch(error) {
            throw error;
        }        
    }    
    
    async updateMachine(machine) {
        try {
            return axiosConfig.put(`${this.endpoint}${machine.equipmentId}`, machine);
        }
        catch(error) {
            throw error;
        }        
    }
}
