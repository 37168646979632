import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export class EquipmentApi {
    constructor() {
        this.endpoint = `/Equipment/`;
    }

    async getEquipments() {
        try {          
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }        
    
    async createEquipment(equipment) {
        try {          
            return axiosConfig.post(this.endpoint, equipment);
        }
        catch (error) {
            throw error;
        }        
    }      

    async deleteEquipment(equipmentId, equipmentTypeId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}${equipmentId}/${equipmentTypeId}`, {                
            });
        }
        catch(error) {
            throw error;
        }        
    }    
    
    async updateEquipment(equipment) {
        try {
            return axiosConfig.put(`${this.endpoint}${equipment.equipmentId}`, {                
                EquipmentId : equipment.equipmentId,                
                EquipmentTypeId : equipment.equipmentTypeId,
                Name : equipment.name,
                Description : equipment.Description,
            });
        }
        catch(error) {
            throw error;
        }        
    }
}

mock.onGet("Equipment/").reply(200, [
    {
        equipmentId: '112121-12122-9911',
        equipmentTypeId: '112121-12122-9911',
        companyId: '112121-121212-2211',
        name: 'Sikringskap',
        description : '12345678',
    },
    {
        equipmentId: '212121-12122-9911',
        equipmentTypeId: '112121-12122-9911',
        companyId: '212121-121212-2211',
        name: 'Fasemåler',
        description : '12345678',
    }
]);

mock.onPost("Equipment/").reply(200, {
    equipmentId: '882121-12122-9911',
    equipmentTypeId: '112121-12122-9911',
    companyId: '212121-121212-2211',
    name: 'Fasemåler XXXX',
    description : 'new desciption',
});

mock.onPut(/Equipment\/.+/).reply(200, {
    equipmentId: '112121-12122-9911',
    equipmentTypeId: '112121-12122-9911',
    companyId: '212121-121212-2211',
    name: 'SikringXXXX XXXX',
    description : 'new desciption',
});

mock.onDelete(/Equipment\/.+/).reply(200);

