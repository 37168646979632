import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {configureReduxStore} from "./store";
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { I18nextProvider } from "react-i18next";
import configureI18n from "./i18n";
import { languageChange } from 'i18next-redux-languagedetector';
import { PersistGate } from 'redux-persist/lib/integration/react';
import setupInterceptors  from './api/network-service';


const i18nextConfig = {
  language: 'nb',
  fallbackLanguage: 'nb',
  whitelist: ['en', 'nb'],
  ns: ['translations'],
  defaultNS: 'translations'
};

const store = configureReduxStore({
  i18next: i18nextConfig
});

export const persistor = persistStore(store);

const i18n = configureI18n({
  i18nextConfig,
  redux: {
    lookupRedux: function () {
      return store.getState().i18next;
    },
    cacheUserLanguageRedux: function (language) {
      store.dispatch(languageChange(language));
    }
  }
});

//hooks for adding token and redirecting to login page on 401/403
setupInterceptors(store);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
   <PersistGate persistor={persistor}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </I18nextProvider>
    </PersistGate>
  </Provider>,
  //document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
