export function firstToUpper(text) {
  if (text == null || text === "") return "";

  return text.charAt(0).toUpperCase() + text.substring(1);
}

export function firstToLower(text) {
  if (text == null || text === "") return "";

  return text.charAt(0).toLowerCase() + text.substring(1);
}

export function groupBy(array, f) {
  var groups = {};
  array.forEach(o => {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  })
}

export function padString(num, size) {
  var s = "000000000" + num;
  return s.substr(s.length - size);
}

export function getDecimalSeparator() {
  var n = 1.1;
  n = n.toLocaleString().substring(1, 2);
  return n;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}