import {
    UPDATE_EMPLOYEEATPROJECTS,
    DELETE_EMPLOYEEATPROJECTS,
} from './constants'

/*SignalR event */
export function updateEmployeeAtProject(usersAtProject) {    
    const employeeAtProject = JSON.parse(usersAtProject);
    let type = employeeAtProject.isCheckIn ? UPDATE_EMPLOYEEATPROJECTS : DELETE_EMPLOYEEATPROJECTS;    
    
    return {
        type,
        payload : { ...employeeAtProject, employeeAtProjectWork : { activityId : employeeAtProject.activityId, startTime : employeeAtProject.loggedIn}}
    }
}