import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class BregApi {
    constructor() {
        this.endpoint = `/Breg`;
    }

    async getBregInfo(companyOrgNumber) {
        try {          
            return axiosConfig.get(`${this.endpoint}/${companyOrgNumber}`);
        }
        catch (error) {
            throw error;
        }        
    }      
}

mock.onGet(/Breg.+/).reply(200, {
    "organizationNumber" : "12121212121212",
    "name" : "name",
    "postAddress" : {
        "country" : "Norway",
        "zipCode" : "9518",
        "place" : "Alta",
        "address" : "Lille Rusten 12" 
    }
});

