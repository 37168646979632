import axiosConfig from '../axiosConfig';
import { mock } from '../mock'
//import {v1 as uuid} from 'uuid'

export default class SettingApi {
    constructor() {
        this.endpoint = `/Setting`;
    }

    async getProjectStatusTypes() {
        try {            
            return axiosConfig.get(`${this.endpoint}/projectstatus`);
        }
        catch (error) {
            throw error;
        }        
    }    

    async getObservationTypes() {
        try {            
            return axiosConfig.get(`${this.endpoint}/Observation`);
        }
        catch (error) {
            throw error;
        }        
    }    

    async getObservationCategories() {
        try {            
            return axiosConfig.get(`${this.endpoint}/Observation/Categories`);
        }
        catch (error) {
            throw error;
        }        
    }    

    async getElectroDeviationCodes() {
        try {
            return axiosConfig.get(`${this.endpoint}/Deviation`);
        }
        catch (error) {
            throw error;
        }        
    }    

    async createDeviationCode(devicationCode) {
        try {
            return axiosConfig.post(`${this.endpoint}/Deviation`, devicationCode);
        }
        catch (error) {
            throw error;
        }        
    }    

    async updateDeviationCode(devicationCode) {        
        try {
            return axiosConfig.put(`${this.endpoint}/Deviation/${devicationCode.deviationCodeTypeId}`, devicationCode);
        }
        catch (error) {
            throw error;
        }        
    }    

    async deleteDeviationCode(deviationCodeId) {
        try {
            return axiosConfig.delete(`${this.endpoint}/Deviation/${deviationCodeId}`);
        }
        catch (error) {
            throw error;
        }        
    }    
}

mock.onGet("Setting/Observation").reply(200, 
     [
         {
            observationTypeId : "a349ac70-cd1c-11ea-87d0-0242ac130003",
            typeName : "CRITICAL"
         },
         {
            observationTypeId : "a319ac70-cd1c-11ea-87d0-0242ac130003",
            typeName : "HIGH"
         },
         {
            observationTypeId : "a369ac50-cd1c-11ea-87d0-0242ac130003",
            typeName : "LOW"
         },
     ]    
);

mock.onGet("Setting/Observation/Categories").reply(200, 
    [
        {
           observationCategoryId  : "d349ac70-cd1c-11ea-87d0-0242ac130003",
           name : "Deviation",
           sortIndex : -1001
        },
        {
           observationTypeId : "f319ac70-cd1c-11ea-87d0-0242ac130003",
           name : "Distribution",
           sortIndex : -1000
        }
    ]    
);

mock.onGet("Setting/Deviation").reply(200, [
        {
           deviationCodeTypeId  : "d349ac70-cd1c-11ea-87d0-0242ac130003",
           name : "Utstyr/anleggsdel hadde ikke tilstrekkelig kapslingsgrad. Jf. FEL § 20"
        },
        {
            deviationCodeTypeId : "e319ac70-cd1c-11ea-87d0-0242ac130003",
            name : "Vindfang Utstyr/anleggsdel var brukt/montert feil. Jf. fel § 36"
        },
        {
            deviationCodeTypeId : "f369ac50-cd1c-11ea-87d0-0242ac130003",
            name : "Baderom (2.Etg) Utstyr/anleggsdel var brukt/montert feil. Jf. fel § 36"
        },
    ]
);


