import axiosConfig from '../axiosConfig';
import { mock } from '../mock'
import {v1 as uuid} from 'uuid'

export default class IntegrationApi {
    constructor() {
        this.endpoint = `/Integration`;
    }    
    
    async pull(intergationData) {
        try {
            return axiosConfig.post(`${this.endpoint}/pull`, {
                Token : intergationData.token,
                Action : intergationData.action
            });
        }
        catch(error) {
            throw error;
        }
    }
    
    async push(intergationData) {        
        try {     
            return axiosConfig.post(`${this.endpoint}/push`, {
                Token : intergationData.token,
                Action : intergationData.action,
                FromTime : intergationData.fromTime
            });
        }
        catch(error) {
            throw error;
        }
    }
    
    async createSettings(settingData) {        
        try {
            return axiosConfig.put(`${this.endpoint}/setting`, {             
                Type : settingData.type,
                Token : settingData.token,
                Subscriptions : settingData.subscriptions,
                handlerConfig : settingData.handlerConfig
            });
        }
        catch(error) {
            throw error;
        }
    }

    async createIntegrationActivity(activityId) {
        try {
            return axiosConfig.post(`${this.endpoint}/activity/${activityId}`, {});
        }
        catch(error) {
            throw error;
        }        
    }

    async createIntegrationProject(projectId) {
        try {
            return axiosConfig.post(`${this.endpoint}/project/${projectId}`, {});
        }
        catch(error) {
            throw error;
        }        
    }

    async createIntegrationEmployee(employeeId) {
        try {
            return axiosConfig.post(`${this.endpoint}/employee/${employeeId}`, {});
        }
        catch(error) {
            throw error;
        }        
    }

    async createProjectConnections() {
        try {
            return axiosConfig.post(`${this.endpoint}/project/connect`, {});
        }
        catch(error) {
            throw error;
        }        
    }   

    async createIntegrationHourTransfer(hours) {
        try {
            return axiosConfig.post(`${this.endpoint}/transferHours`, hours);
        }
        catch(error) {
            throw error;
        }        
    }   

    async createIntegrationInvoiceTransfer(goods, machines) {
        try {
            return axiosConfig.post(`${this.endpoint}/transferInvoices`, {
                goods, machines
            });
        }
        catch(error) {
            throw error;
        }        
    }   


    async getEmployees() {           
        try {
            return axiosConfig.get(`${this.endpoint}/employee`);
        }
        catch(error) {
            throw error;
        }
    }

    async getActivities() {           
        try {
            return axiosConfig.get(`${this.endpoint}/activity`);
        }
        catch(error) {
            throw error;
        }
    }

    async getSalaries() {           
        try {
            return axiosConfig.get(`${this.endpoint}/salary`);
        }
        catch(error) {
            throw error;
        }
    }

    async getProjects() {           
        try {
            return axiosConfig.get(`${this.endpoint}/project`);
        }
        catch(error) {
            throw error;
        }
    }

    async getCustomers() {           
        try {
            return axiosConfig.get(`${this.endpoint}/customer`);
        }
        catch(error) {
            throw error;
        }
    }

    async getSettings() {           
        try {
            return axiosConfig.get(`${this.endpoint}/setting`);
        }
        catch(error) {
            throw error;
        }
    }

    async getLogs() {           
        try {
            return axiosConfig.get(`${this.endpoint}/log`);
        }
        catch(error) {
            throw error;
        }
    }
    

    async isAlive() {
        try {            
            return axiosConfig.post(`${this.endpoint}/alive`, {                
            });
        }
        catch(error) {
            throw error;
        }
    }
}

mock.onPost("/push").reply(200, [
    {
        externalLogEntryId  : uuid(),
        message : "message",
        messageType : ""        
     }
]);
