import { put, takeLatest, call } from 'redux-saga/effects'
import { ProjectApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'
import groupBy from 'lodash/groupBy'
import keys from 'lodash/keys'

import { 
  GET_PROJECTSDOCUMENTS,
  GET_PROJECTSDOCUMENTS_SUCCESS,
  GET_PROJECTSDOCUMENTS_FAILURE,
  GET_EMPLOYEEATPROJECTS,
  GET_EMPLOYEEATPROJECTS_SUCCESS,
  GET_EMPLOYEEATPROJECTS_FAILURE,
  CREATE_PROJECTSDOCUMENTS,
  CREATE_PROJECTSDOCUMENTS_SUCCESS,
  CREATE_PROJECTSDOCUMENTS_FAILURE,
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS, 
  GET_PROJECTS_FAILURE,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  DELETE_PROJECT,
  DELETE_PROJECT_FAILURE,
  DELETE_PROJECT_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_SUCCESS ,
  GET_PROJECTCONSUMPTIONS,
  GET_PROJECTCONSUMPTIONS_SUCCESS,
  GET_PROJECTCONSUMPTIONS_FAILURE,
  GET_PROJECTMACHINEHOURS,
  GET_PROJECTMACHINEHOURS_SUCCESS,
  GET_PROJECTMACHINEHOURS_FAILURE,
  RESET_PROJECTDELETE_STATE
 } from '../actions/constants'

 

export function* getAllProjects() {
  var api = new ProjectApi();

  try {          
    const response = yield call(() => api.getAllProjectsForCompany());    

    yield put({ type: GET_PROJECTS_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: GET_PROJECTS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getEmployeeAtProjects(action) {
  var api = new ProjectApi();

  try {      
    let payload = action.payload;
    const response = yield call(() => api.getAllEmployeesAtProject(payload.projectId));                

    yield put({ type: GET_EMPLOYEEATPROJECTS_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: GET_EMPLOYEEATPROJECTS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}


function* getProjectDocuments(action) {
  var api = new ProjectApi();

  try {          
    var data = action.payload;        
    const response = yield call(() => api.getProjectDocuments(data.projectId));        

    yield put({ type: GET_PROJECTSDOCUMENTS_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: GET_PROJECTSDOCUMENTS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }  
}

function* getProjectConsumptions(action) {
  var api = new ProjectApi();

  try {          
    var data = action.payload;        
    const response = yield call(() => api.getProjectConsumptions(data.projectId));        

    yield put({ type: GET_PROJECTCONSUMPTIONS_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: GET_PROJECTCONSUMPTIONS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }  
}

function* getProjectMachineHours(action) {
  var api = new ProjectApi();

  try {          
    var data = action.payload;            
    const response = yield call(() => api.getProjectMachineHours(data.projectId));        

    yield put({ type: GET_PROJECTMACHINEHOURS_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: GET_PROJECTMACHINEHOURS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }  
}

function* createNewProjectDocument(action) {
  var api = new ProjectApi();

  try {                    
    var data = action.payload;        
    yield call(() => api.createNewProjectDocument(data.projectId, data.type, data.friendlyName));    

    const response = yield call(() => api.getProjectDocuments(data.projectId));        
    yield put({ type: CREATE_PROJECTSDOCUMENTS_SUCCESS, data: response.data}); 
  } catch(err) {    
    yield put({ type: CREATE_PROJECTSDOCUMENTS_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
  }  
}

/*Supports creating project/subproject from remote system*/
export function* createNewProject(action) {
  var api = new ProjectApi();

  try {                    
    var data = action.payload;        
    let isArray = Array.isArray(data);
    let projectArr = isArray ? data : [data];

    let groupByParent = groupBy(projectArr, k => k.remoteProjectParent);
    let sortedeKeys = keys(groupByParent).sort().reverse();    

    let projectsCreated = {};   
    for (const parentProjectKey of sortedeKeys) {     
      let allProjects = groupByParent[parentProjectKey];
      for (let project of allProjects) {             
        
        // if (project.externalSystemRef) {          
        //   continue;
        // }        

        let foundInDictionary = projectsCreated[project.remoteProjectParent] ?? null;
        if (foundInDictionary) {
          project.mainProjectRef = foundInDictionary;
        }        

        var response = yield call(() => api.createNewProject(project));    
        yield put({ type: CREATE_PROJECT_SUCCESS, data: response.data}); 

        projectsCreated[project.externalSystemRef] = response.data.projectId;
      }  
      
      if (projectArr.length === 0)
        throw Error("Missing project data");
    } 
  } catch(err) {    
    yield put({ type: CREATE_PROJECT_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
  }  
}

export function* deleteProject(action) {
  var api = new ProjectApi();

  try {                
    var project = action.payload;   

    yield call(() => api.deleteProjectById(project.projectId));        
    
    yield put({ type: DELETE_PROJECT_SUCCESS, data: {id : project.projectId } });
  } catch(err) {        
    yield put({ type: DELETE_PROJECT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}}); 
    yield put({ type: RESET_PROJECTDELETE_STATE });
  }  
}

export function* updateProject(action) {  
  var api = new ProjectApi();

  try {                    
    var data = action.payload;        
    
    
     yield call(() => api.updateProject(data));                
    
    yield put({ type: UPDATE_PROJECT_SUCCESS, data: data }); 
  } catch(err) {        
    yield put({ type: UPDATE_PROJECT_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* watchProjects() {
  yield takeLatest(GET_PROJECTS, getAllProjects);
}

export function* watchEmployeesAtProject() {
  yield takeLatest(GET_EMPLOYEEATPROJECTS, getEmployeeAtProjects);
}

export function* wacthProjectDocuments() {
  yield takeLatest(GET_PROJECTSDOCUMENTS, getProjectDocuments)
}

export function* watchGoodsConsumtionForProject() {
  yield takeLatest(GET_PROJECTCONSUMPTIONS, getProjectConsumptions);
}

export function* watchGetProjectMachineHours() {
  yield takeLatest(GET_PROJECTMACHINEHOURS, getProjectMachineHours);
}

export function* watchProjectCreate() {
  yield takeLatest(CREATE_PROJECT, createNewProject);
}

export function* watchProjectDocumentCreate() {
  yield takeLatest(CREATE_PROJECTSDOCUMENTS, createNewProjectDocument);
}

export function* watchDeleteProject() {
  yield takeLatest(DELETE_PROJECT, deleteProject);
}

export function* watchUpdateProject() {
  yield takeLatest(UPDATE_PROJECT, updateProject);
}