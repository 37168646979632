import {
    GET_USERS, 
    SET_USERINITIALINFO, 
    DELETE_USER, 
    USER_LOGOUT,
    UPDATE_USER,
    UPDATE_LANGUAGE_CURRENTUSER,
    REMOVE_LOGO_CURRENTUSER,
    RECOVER_USERPASSWORD,
    CHANGE_USERPASSWORD,
    RESET_CURRENTUSER_SAVEDSTATE,
    VERIFY_NEWUSER
} from './constants'


export function getUsersForCompany() {    
    return {
        type : GET_USERS,
        payload : {  }
    }
}

export function setInitialUserInformation(phoneNumber, firstName, lastName) {
    return {
        type : SET_USERINITIALINFO,
        payload : { phoneNumber, firstName, lastName }
    }
}

export function updateUserInCompany(userId, firstName, lastName, userName, languageName, employeeNumber, phoneNumber, dateOfBirth, avatarUrl, roles, externalSystemRef) {    
    return {
        type : UPDATE_USER,
        payload : {userId, firstName, lastName, languageName, userName, employeeNumber, phoneNumber, dateOfBirth, avatarUrl, roles, externalSystemRef}
    }
}

export function verifyNewUser(token, data) {    
    return {
        type : VERIFY_NEWUSER,
        payload : {token, data}
    }
}

export function recoverPassword(email) {    
    return {
        type : RECOVER_USERPASSWORD,
        payload : email
    }
}

export function resetPassword(resetData) {    
    return {
        type : CHANGE_USERPASSWORD,
        payload : resetData
    }
}

export function resetSavedState() {
    return {
        type : RESET_CURRENTUSER_SAVEDSTATE,
        payload : {}
    }    
}

export function deleteUserFromCompany(userId) {    
    return {
        type : DELETE_USER,
        payload : userId
    }
}

export function updateLanguageForCurrentUser(languageCode) {        
    return {
        type : UPDATE_LANGUAGE_CURRENTUSER,
        payload : { languageCode }
    }
}

export function removeAvatarCurrentUser() {    
    return {
        type : REMOVE_LOGO_CURRENTUSER,
        payload : { }
    }
}

export function signOut() {    
    return {
        type : USER_LOGOUT,
        payload : null
    }
}
