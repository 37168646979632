import { put, takeLatest, call} from 'redux-saga/effects'
import { GoodsApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import { 
  GET_GOODS, 
  GET_GOODS_SUCCESS,
  GET_GOODS_FAILURE,
  CREATE_GOODS,  
  CREATE_GOODS_SUCCESS,  
  CREATE_GOODS_FAILURE,
  UPDATE_GOODS,
  UPDATE_GOODS_FAILURE,
  DELETE_GOODS, 
  DELETE_GOODS_SUCCESS,
  DELETE_GOODS_FAILURE,
  UPDATE_GOODS_SUCCESS,
  GET_GOODSCATEGORIES,
  GET_GOODSCATEGORIES_FAILURE,
  GET_GOODSCATEGORIES_SUCCESS,
  CREATE_GOODSCATEGORIES,
  CREATE_GOODSCATEGORIES_SUCCESS,
  CREATE_GOODSCATEGORIES_FAILURE,
  UPDATE_GOODSCATEGORIES_SUCCESS,
  UPDATE_GOODSCATEGORIES_FAILURE,
  UPDATE_GOODSCATEGORIES,
  DELETE_GOODSCATEGORIES,
  DELETE_GOODSCATEGORIES_FAILURE,
  DELETE_GOODSCATEGORIES_SUCCESS,
  CREATE_GOODS_PRICE,
  CREATE_GOODS_PRICE_SUCCESS,
  CREATE_GOODS_PRICE_FAILURE
} from '../actions/constants'

export function* getAllGoods() {
  var api = new GoodsApi();
  try {
    
    const response = yield call(() => api.getGoods());        
    yield put({ type: GET_GOODS_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_GOODS_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* createGoods(action) {
  var api = new GoodsApi();  

  try {       
    let  data = action.payload;             
    let hasPriceInfo = data.price && data.cost && data.validFrom;
    
    let response = yield call(() => api.createGoods(data));    
    if (hasPriceInfo) {
      yield call(() => api.createGoodsPrice(response.data.goodsId, {goodsId : response.data.goodsId, cost : data.cost, price : data.price, validFrom : data.validFrom}));
    }
    
    yield put({ type: CREATE_GOODS_SUCCESS, data: response.data}); 
    
    //since were going against two resource do a new fresh get
    yield call(() => getAllGoods());
  } catch(err) {    
    yield put({ type: CREATE_GOODS_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
  }  
}

export function* createGoodsCategory(action) {
  var api = new GoodsApi();  

  try {       
    var data = action.payload;             
    
    var response = yield call(() => api.createGoodsCategory(data));    

    yield put({ type: CREATE_GOODSCATEGORIES_SUCCESS, data: response.data}); 
  } catch(err) {    
    yield put({ type: CREATE_GOODSCATEGORIES_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
  }  
}


export function* deleteGoods(action) {
  var api = new GoodsApi();

  try {                    
    var goodsId = action.payload.goodsId;
    
    yield call(() => api.deleteGoods(goodsId));        
    
    yield put({ type: DELETE_GOODS_SUCCESS, data: goodsId });
  } catch(err) {        
    yield put({ type: DELETE_GOODS_FAILURE, data: {error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* updateGoods(action) {
  var api = new GoodsApi();
  
  try {                    
    var data = action.payload;                
    let hasPriceInfo = data.price && data.cost && data.validFrom;
    if (hasPriceInfo) {
      yield call(() => api.updateGoodsPrice(data.goodsId, {goodsId : data.goodsId, goodsPriceId : data.goodsPriceId, cost : data.cost, price : data.price, validFrom : data.validFrom, validTo : data.validTo}));
    }
    
    var response = yield call(() => api.updateGoods(data));        
    
    yield put({ type: UPDATE_GOODS_SUCCESS, data: response.data }); 

    //since were going against two resource do a new fresh get
    yield call(() => getAllGoods());
  } catch(err) {            
    yield put({ type: UPDATE_GOODS_FAILURE, data:{ error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* updateGoodsCategory(action) {
  var api = new GoodsApi();
  
  try {                    
    var data = action.payload;          
  
    yield call(() => api.updateGoodsCategory(data));        
    
    yield put({ type: UPDATE_GOODSCATEGORIES_SUCCESS, data: data }); 
  } catch(err) {            
    yield put({ type: UPDATE_GOODSCATEGORIES_FAILURE, data:{ error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* getAllGoodsCategories() {
  var api = new GoodsApi();
  try {
        
    const response = yield call(() => api.getGoodsCategories());        
    yield put({ type: GET_GOODSCATEGORIES_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_GOODSCATEGORIES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* deleteGoodsCategory(action) {
  var api = new GoodsApi();
  try {
        
    var data = action.payload;          

    yield call(() => api.deleteGoodsCategory(data.goodsCategoryId));        
    yield put({ type: DELETE_GOODSCATEGORIES_SUCCESS, data: data.goodsCategoryId});
  } catch (err) {
    yield put({ type: DELETE_GOODSCATEGORIES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* updateGoodsPrice(action) {
  var api = new GoodsApi();
  
  try {                    
    var data = action.payload;          
  
    yield call(() => api.createGoodsPrice(data.goodsId, data));   
    yield call(() => getAllGoods());     
    
    yield put({ type: CREATE_GOODS_PRICE_SUCCESS, data: data }); 
  } catch(err) {            
    yield put({ type: CREATE_GOODS_PRICE_FAILURE, data:{ error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* watchGetAllGoods() {
  yield takeLatest(GET_GOODS, getAllGoods);
}

export function* watchGetAllGoodsCategories() {
  yield takeLatest(GET_GOODSCATEGORIES, getAllGoodsCategories);
}

export function* watchGoodsCreate() {
  yield takeLatest(CREATE_GOODS, createGoods);
}

export function* watchGoodsCategoryCreate() {
  yield takeLatest(CREATE_GOODSCATEGORIES, createGoodsCategory);
}

export function* watchGoodsDelete() {
    yield takeLatest(DELETE_GOODS, deleteGoods);
}

export function* watchGoodsUpdate() {
  yield takeLatest(UPDATE_GOODS, updateGoods);
}

export function* watchGoodsUpdateCategory() {
  yield takeLatest(UPDATE_GOODSCATEGORIES, updateGoodsCategory);
}

export function* watchGoodsDeleteCategory() {
  yield takeLatest(DELETE_GOODSCATEGORIES, deleteGoodsCategory);
}

export function* watchCreateGoodsPrice() {
  yield takeLatest(CREATE_GOODS_PRICE, updateGoodsPrice)
}