import {v1 as uuid} from 'uuid'
import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class RoleApi {
    constructor() {
        this.endpoint = `/Role`;         
    }

    async getRolesWithPermissionsForCompany() {     
        try {            
            return axiosConfig.get(this.endpoint);
        }
        catch(error) {
            throw error;
        }                   
    }    

    async getavailablePermissionsForCompany() {     
        try {
            return axiosConfig.get(`${this.endpoint}/Permission`);
        }
        catch(error) {
            throw error;
        }                   
    }        

    async saveRoleForCompany(role) {     
        try {            
            return axiosConfig.post(`${this.endpoint}`, role);
        }
        catch(error) {
            throw error;
        }                   
    }        

    async updateRoleForCompany(role) {     
        try {          
            return axiosConfig.put(`${this.endpoint}/${role.roleId}`, role);
        }
        catch(error) {
            throw error;
        }                   
    }        

    async deleteRole(roleId) {     
        try {          
            return axiosConfig.delete(`${this.endpoint}/${roleId}`);
        }
        catch(error) {
            throw error;
        }                   
    }        
}

mock.onGet('/Role').reply(200, [
    {
        roleId : uuid(),
        name : "Administrator",
        isNew : false,
        permissions : [
            { permissionId : '8610a0d8-a8fb-11ea-bb37-0242ac130002', name: 'Project Read', description : "Can Read Projects", group : "Project"},       
            { permissionId : '93b6afe8-a8fb-11ea-bb37-0242ac130002', name: 'Project Create', description : "Can Create Projects", group : "Project"},
            { permissionId : 'a0a34c7a-a8fb-11ea-bb37-0242ac130002', name: 'User Create', description : "Can Create User", group : "User"}          
        ]
    }, 
    {
        roleId : uuid(),
        name : "BAS",
        isNew : false,
        permissions : [
            { permissionId : '8610a0d8-a8fb-11ea-bb37-0242ac130002', name: 'Project Read', description : "Can Read Projects", group : "Project"},       
            { permissionId : '93b6afe8-a8fb-11ea-bb37-0242ac130002', name: 'Project Create', description : "Can Create Projects", group : "Project"},
            { permissionId : 'a0a34c7a-a8fb-11ea-bb37-0242ac130002', name: 'User Create', description : "Can Create User", group : "User"}               
        ]
    }, 
]);

mock.onGet('/Role/Permission').reply(200, [
    { permissionId : '8610a0d8-a8fb-11ea-bb37-0242ac130002', name: 'Project Read', description : "Can Read Projects", group : "Project"},       
    { permissionId : '93b6afe8-a8fb-11ea-bb37-0242ac130002', name: 'Project Create', description : "Can Create Projects", group : "Project"},
    { permissionId : 'a0a34c7a-a8fb-11ea-bb37-0242ac130002', name: 'User Create', description : "Can Create User", group : "User"},          
    { permissionId : 'd0a34c7a-a8fb-11ea-bb37-0242ac130002', name: 'User Delete', description : "Can Delete User", group : "User"}          
]);