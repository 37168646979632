import { put, takeLatest, call } from 'redux-saga/effects'
import { ObservationApi, MeasurementApi, ProjectApi, FileUploadApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import { 
  GET_OBSERVATION,
  GET_OBSERVATION_SUCCESS, 
  GET_OBSERVATION_FAILURE,  
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_SUCCESS,
  CREATE_OBSERVATION_FAILURE,
  CREATE_OBSERVATIONMEASUREMENT,
  CREATE_OBSERVATIONMEASUREMENT_SUCCESS,
  CREATE_OBSERVATIONMEASUREMENT_FAILURE,
  DELETE_OBSERVATIONMEASUREMENT,
  DELETE_OBSERVATIONMEASUREMENT_SUCCESS,
  DELETE_OBSERVATIONMEASUREMENT_FAILURE,
  DELETE_OBSERVATION_SUCCESS,
  DELETE_OBSERVATION_FAILURE,
  DELETE_OBSERVATION,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION_FAILURE,
  UPDATE_OBSERVATION  
 } from '../actions/constants'


export function* getObservations(action) {
  const projectId = action.payload;
  
  if (projectId)
    yield getAllObservationForProjects(projectId);
  else 
    yield getAllObservations();
}

export function* getAllObservationForProjects(project) {
  var api = new ProjectApi();
    
  try {               
    const response = yield call(() => api.getAllObservationForProjects(project));        

    yield put({ type: GET_OBSERVATION_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: GET_OBSERVATION_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getAllObservations() {
  var api = new ObservationApi();
  try {                  
    const response = yield call(() => api.getAllObservations());        

    yield put({ type: GET_OBSERVATION_SUCCESS, data: response.data });
  } catch (err) {                
    yield put({ type: GET_OBSERVATION_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createNewObservation(action) {
  var api = new ObservationApi();

  try {                    
    var data = action.payload;            
    var response = yield call(() => api.createNewObservation(data));    

    yield put({ type: CREATE_OBSERVATION_SUCCESS, data: response.data}); 
  } catch(err) {    
    yield put({ type: CREATE_OBSERVATION_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
  }  
}

export function* deleteObservation(action) {
  var api = new ObservationApi();

  try {                
    var observationId = action.payload;
        
    yield call(() => api.deleteObservationById(observationId));            
    yield put({ type: DELETE_OBSERVATION_SUCCESS, data: observationId });
  } catch(err) {        
    yield put({ type: DELETE_OBSERVATION_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* updateObservation(action) {
  var api = new ObservationApi();
  var fileApi = new FileUploadApi();

  try {                    
    var data = action.payload;         
    
    for (let i=0; i< data.images.length; ++i) {
      let currImage = data.images[i];
      if (currImage.cropData) {
        let fileId = currImage.imageUrl;
        let cropData = currImage.cropData;
        
        try {
          var resp = yield call(() => fileApi.cropFile(fileId, cropData));            
          currImage.imageUrl = resp.data.croppedFileUrl;
        } catch (err) {
          console.error(err);
        }
      }      
    }

    var response = yield call(() => api.updateObservation(data));                
    yield put({ type: UPDATE_OBSERVATION_SUCCESS, data: response.data }); 
  } catch(err) {        
    yield put({ type: UPDATE_OBSERVATION_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* createNewMeasurement(action) {
  var api = new MeasurementApi();

  try {                        
    var data = action.payload;  
    var response = yield call(() => api.createNewMeasurment(data));    

    yield put({ type: CREATE_OBSERVATIONMEASUREMENT_SUCCESS, data: response.data}); 
  } catch(err) {    
    yield put({ type: CREATE_OBSERVATIONMEASUREMENT_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
  }  
}

export function* deleteMeasurement(action) {
  var api = new MeasurementApi();

  try {                
    var data = action.payload;       
    
    yield call(() => api.deleteMeasurementByObservationAndMeasurmentId(data.measurementId, data.observationId));            
    yield put({ type: DELETE_OBSERVATIONMEASUREMENT_SUCCESS, data: { observationId : data.observationId, measurmentId : data.measurmentId }});
  } catch(err) {        
    yield put({ type: DELETE_OBSERVATIONMEASUREMENT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
  }  
}


export function* watchObservations() {
  yield takeLatest(GET_OBSERVATION, getObservations);
}

export function* watchObservationCreate() {
  yield takeLatest(CREATE_OBSERVATION, createNewObservation);
}

export function* watchDeleteObservation() {
  yield takeLatest(DELETE_OBSERVATION, deleteObservation);
}

export function* watchUpdateObservation() {
  yield takeLatest(UPDATE_OBSERVATION, updateObservation);
}

export function* watchCreateMeasurement() {
  yield takeLatest(CREATE_OBSERVATIONMEASUREMENT, createNewMeasurement);
}

export function* watchDeleteMeasurement() {
  yield takeLatest(DELETE_OBSERVATIONMEASUREMENT, deleteMeasurement);
}