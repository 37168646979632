import {
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILURE,
    GET_GROUPS,
    CREATE_GROUP,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILURE,
    UPDATE_GROUP,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAILURE,
    DELETE_GROUP,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE
} from '../actions/constants'

const initialState = {
    error: null,
    loading: false,
    saved: false,
    data: [],
};

export const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_GROUP:
        case UPDATE_GROUP:
        case CREATE_GROUP:
        case GET_GROUPS:
            return { ...state, ...{ loading: true, error: null, saved: false } }
        case GET_GROUPS_SUCCESS:
            return { ...state, ...{ loading: false, error: null, data: action.data, saved: false } }
        case CREATE_GROUP_SUCCESS: {
            let newData = state.data.concat(action.data);
            return { ...state, ...{ loading: false, error: null, data: newData, saved: true } }
        }
        case UPDATE_GROUP_SUCCESS: {                    
            let newArr = [...state.data]; 
            let index = newArr.findIndex(item => item.intelliGroupId === action.data.id);
            
            if (index >= 0)
                newArr.splice(index, 1, action.data)
      
            return {...state, ...{loading: false, data :  newArr, error : '', saved : true}};
        }    
        case DELETE_GROUP_SUCCESS: {        
            const list = state.data.filter(item => item.intelliGroupId !== action.data);
            return { ...state, ...{ loading: false, error: null, data : list, saved : true } }
        }
        case DELETE_GROUP_FAILURE:
        case UPDATE_GROUP_FAILURE:
        case CREATE_GROUP_FAILURE:
        case GET_GROUPS_FAILURE: {
            return { ...state, ...{ loading: false, error: action.data.error, saved: false } };
        }
        default:
            return state;
    }
};
