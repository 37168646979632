import {
    GET_TEMPLATECATEGORY_SUCCESS,
    GET_TEMPLATECATEGORY_FAILURE,
    GET_TEMPLATECATEGORY,
    CREATE_TEMPLATECATEGORY_SUCCESS,
    CREATE_TEMPLATECATEGORY_FAILURE,
    CREATE_TEMPLATECATEGORY,
    DELETE_TEMPLATECATEGORY,
    DELETE_TEMPLATECATEGORY_SUCCESS,
    DELETE_TEMPLATECATEGORY_FAILURE,
    UPDATE_TEMPLATECATEGORY,
    UPDATE_TEMPLATECATEGORY_SUCCESS,
    UPDATE_TEMPLATECATEGORY_FAILURE,
    CLEAR_TEMPLATECATEGORYSAVED
  } from '../actions/constants'

  const initialState = {
    data: [],
    error: null,
    loading: false,
    saved : false,
  };


  export const templateCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TEMPLATECATEGORY:
      case CREATE_TEMPLATECATEGORY:
      case DELETE_TEMPLATECATEGORY:
      case UPDATE_TEMPLATECATEGORY:
        return {...state, ...{loading: true, error: null, saved : false}}

      case GET_TEMPLATECATEGORY_SUCCESS :
        return {...state, ...{loading: false, saved : false, data : action.data, error: ''}}
      case CREATE_TEMPLATECATEGORY_SUCCESS : {
        let newArr = state.data.concat(action.data);
        return {...state, ...{loading: false, saved : true, data : newArr, error: ''}}
      }
      case DELETE_TEMPLATECATEGORY_SUCCESS: {
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.templateCategoryId === action.data);
        copyArr.splice(index, 1);

        return {...state, ...{loading: false, saved : false, data : copyArr, error: ''}}
      }
      case UPDATE_TEMPLATECATEGORY_SUCCESS: {
        let copyUpdateArr = [...state.data];
        let index = copyUpdateArr.findIndex(item => item.templateId === action.data.templateCategoryId);
        copyUpdateArr[index] = action.data;

        return {...state, ...{loading: false, saved : true, data : copyUpdateArr, error: ''}}
      }

      case GET_TEMPLATECATEGORY_FAILURE:
      case CREATE_TEMPLATECATEGORY_FAILURE:
      case DELETE_TEMPLATECATEGORY_FAILURE:
      case UPDATE_TEMPLATECATEGORY_FAILURE:
        return {...state, loading: false, saved : false, error : action.data.error };
      case CLEAR_TEMPLATECATEGORYSAVED:
        return {...state, saved : false };
      default:
        return state;
    }
  };