import { put, takeLatest, call} from 'redux-saga/effects'
import { SettingApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import {
    GET_SETTINGPROJECTSTATUSES,
    GET_SETTINGPROJECTSTATUSES_SUCCESS,  
    GET_SETTINGPROJECTSTATUSES_FAILURE,
    GET_SETTINGOBSERVATIONTYPES,
    GET_SETTINGOBSERVATIONTYPES_SUCCESS,
    GET_SETTINGOBSERVATIONTYPES_FAILURE,    
    GET_SETTINGDEVIATIONCODES,
    GET_SETTINGDEVIATIONCODES_SUCCESS,
    GET_SETTINGDEVIATIONCODES_FAILURE,
    CREATE_SETTINGDEVIATIONCODES,
    CREATE_SETTINGDEVIATIONCODES_SUCCESS,
    CREATE_SETTINGDEVIATIONCODES_FAILURE,
    UPDATE_SETTINGDEVIATIONCODES,
    UPDATE_SETTINGDEVIATIONCODES_SUCCESS,
    UPDATE_SETTINGDEVIATIONCODES_FAILURE,
    DELETE_SETTINGDEVIATIONCODES,
    DELETE_SETTINGDEVIATIONCODES_SUCCESS,
    DELETE_SETTINGDEVIATIONCODES_FAILURE,
    GET_SETTINGOBSERVATIONCATEGORIES,
    GET_SETTINGOBSERVATIONCATEGORIES_SUCCESS,
    GET_SETTINGOBSERVATIONCATEGORIES_FAILURE
} from '../actions/constants'

export function* getProjectStatusTypes() {
  var api = new SettingApi();
  try {
    const response = yield call(() => api.getProjectStatusTypes());

    yield put({ type: GET_SETTINGPROJECTSTATUSES_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_SETTINGPROJECTSTATUSES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}


export function* getAllObservationTypes() {
  var api = new SettingApi();
  try {
    const response = yield call(() => api.getObservationTypes());

    yield put({ type: GET_SETTINGOBSERVATIONTYPES_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_SETTINGOBSERVATIONTYPES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* getAllObservationCategories() {
  var api = new SettingApi();
  try {
    const response = yield call(() => api.getObservationCategories());

    yield put({ type: GET_SETTINGOBSERVATIONCATEGORIES_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_SETTINGOBSERVATIONCATEGORIES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* getAllDeviationCode() {
  var api = new SettingApi();
  
  try {
    const response = yield call(() => api.getElectroDeviationCodes());
    yield put({ type: GET_SETTINGDEVIATIONCODES_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_SETTINGDEVIATIONCODES_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* addDeviationCode(action) {  
  var api = new SettingApi();
  var deviationPayload = action.payload;   

  try {    
    const response = yield call(() => api.createDeviationCode(deviationPayload));    

    yield put({ type: CREATE_SETTINGDEVIATIONCODES_SUCCESS, data:  response.data});  
  } catch (err) {        
    yield put({ type: CREATE_SETTINGDEVIATIONCODES_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* updateDeviationCode(action) {  
  var api = new SettingApi();
  var deviationPayload = action.payload;     

  try {    
    yield call(() => api.updateDeviationCode(deviationPayload));

    yield put({ type: UPDATE_SETTINGDEVIATIONCODES_SUCCESS, data:  deviationPayload});  
  } catch (err) {        
    yield put({ type: UPDATE_SETTINGDEVIATIONCODES_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* deleteDeviationCode(action) {  
  var api = new SettingApi();
  var deviationPayload = action.payload;   

  try {    
    yield call(() => api.deleteDeviationCode(deviationPayload));
    yield put({ type: DELETE_SETTINGDEVIATIONCODES_SUCCESS, data:  deviationPayload});  
  } catch (err) {        
    yield put({ type: DELETE_SETTINGDEVIATIONCODES_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* watchGetObservationTypes() {
  yield takeLatest(GET_SETTINGOBSERVATIONTYPES, getAllObservationTypes);
}

export function* watchGetObservationCategories() {
  yield takeLatest(GET_SETTINGOBSERVATIONCATEGORIES, getAllObservationCategories);
}

export function* watchGetDeviationCodes() {
  yield takeLatest(GET_SETTINGDEVIATIONCODES, getAllDeviationCode);
}

export function* watchCreateDeviationCodes() {
  yield takeLatest(CREATE_SETTINGDEVIATIONCODES, addDeviationCode);
}

export function* watchUpdateDeviationCodes() {
  yield takeLatest(UPDATE_SETTINGDEVIATIONCODES, updateDeviationCode);
}

export function* watchDeleteDeviationCodes() {
  yield takeLatest(DELETE_SETTINGDEVIATIONCODES, deleteDeviationCode);
}

export function* watchGetProjectStatusTypes() {
  yield takeLatest(GET_SETTINGPROJECTSTATUSES, getProjectStatusTypes);
}
