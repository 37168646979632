import {
    GET_INTEGRATION_SETTING,
    GET_INTEGRATION_SETTING_FAILURE,
    GET_INTEGRATION_SETTING_SUCCESS,
    CREATE_INTEGRATION_SETTING,
    CREATE_INTEGRATION_SETTING_SUCCESS,
    CREATE_INTEGRATION_SETTING_FAILURE    
  } from '../actions/constants'
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,    
  };
  
  
  export const integrationSettingReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_INTEGRATION_SETTING:
      case CREATE_INTEGRATION_SETTING:           
        return {...state, ...{loading: true, error: null}}      
      case GET_INTEGRATION_SETTING_SUCCESS:
      case CREATE_INTEGRATION_SETTING_SUCCESS:                
        return {...state, ...{loading: false, data : action.data, error: ''}} ;     
      case GET_INTEGRATION_SETTING_FAILURE:
      case CREATE_INTEGRATION_SETTING_FAILURE:
        return {...state, loading: false, error : action.data.error };      
      default:      
        return state;
    }
  };