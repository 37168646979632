import { CREATE_ACCOUNT, CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAILURE } from '../actions/constants'
import { put, takeLatest, call } from 'redux-saga/effects'
import { AccountApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

export function* createNewAccount(action) {
  var api = new AccountApi();

  try {  
    
    const response = yield call(() => api.createNewAccount(action.payload.email, action.payload.firstName,
      action.payload.lastName, action.payload.orgNumber, action.payload.password));

    if (response.status !== 200) {      
      yield put({ type: CREATE_ACCOUNT_FAILURE, data: {error : 'error'}   });
    }

    yield put({ type: CREATE_ACCOUNT_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: CREATE_ACCOUNT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* watchCreateAccount() {
  yield takeLatest(CREATE_ACCOUNT, createNewAccount);
}