import { ReportApi } from '../api'
import { GET_EMPLOYEEREPORT, GET_EMPLOYEEREPORT_SUCCESS, GET_EMPLOYEEREPORT_FAILURE } from '../actions/constants'
import { put, takeLatest, call } from 'redux-saga/effects'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'
import { base64ToArrayBuffer, saveByteArray } from '../utils/fileUploadUtil'

export function* getEmployeeReport(action) {
    var api = new ReportApi();

    try {        
        var columns = action.payload ?? [];
        const response = yield call(() => api.createEmployeeReport(columns, null));
        
        saveByteArray("employeeRport.pdf", base64ToArrayBuffer(response.data), "application/pdf");        
        yield put({ type: GET_EMPLOYEEREPORT_SUCCESS, data: response.data });
    } catch (err) {
        yield put({ type: GET_EMPLOYEEREPORT_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });        
    }
}

export function* watchGetEmployeeReport() {
    yield takeLatest(GET_EMPLOYEEREPORT, getEmployeeReport);
}