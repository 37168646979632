import {
    GET_MACHINE_SUCCESS,
    GET_MACHINE_FAILURE, 
    GET_MACHINE,     
    CREATE_MACHINE_SUCCESS,
    CREATE_MACHINE_FAILURE, 
    CREATE_MACHINE,
    DELETE_MACHINE,
    DELETE_MACHINE_SUCCESS,
    DELETE_MACHINE_FAILURE,
    UPDATE_MACHINE,    
    UPDATE_MACHINE_SUCCESS,    
    UPDATE_MACHINE_FAILURE,
  } from '../actions/constants'  
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
    saved: false,
  };
  
  
  export const machineReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_MACHINE:
      case CREATE_MACHINE:
      case DELETE_MACHINE:
      case UPDATE_MACHINE:
        return {...state, ...{loading: true, error: null, saved: false}}      
        
      case GET_MACHINE_SUCCESS :      
        return {...state, ...{loading: false, data : action.data, error: ''}}      
      case CREATE_MACHINE_SUCCESS : {
        let newArr = state.data.concat(action.data);
        return {...state, ...{loading: false, data : newArr, error: '', saved : true}}                 
      }
      case DELETE_MACHINE_SUCCESS: {        
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.equipmentId === action.data.equipmentId);
        copyArr.splice(index, 1);

        return {...state, ...{loading: false, data : copyArr, error: '', saved : true}}      
      }
      case UPDATE_MACHINE_SUCCESS: {
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.equipmentId === action.data.equipmentId);
        copyArr[index] = action.data;       

        return {...state, ...{loading: false, data : copyArr, error: '', saved : true}}      
      }

      case GET_MACHINE_FAILURE:     
      case CREATE_MACHINE_FAILURE:
      case DELETE_MACHINE_FAILURE:
      case UPDATE_MACHINE_FAILURE:
        return {...state, loading: false, error : action.data.error, saved: false };      
      default:      
        return state;
    }
  };