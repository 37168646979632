import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class MesurementApi {
    constructor() {
        this.endpoint = `/Measurement`;
    }

    async getAllMeasurmentsForProjects(projectId) {
        try {
            return axiosConfig.get(`${this.endpoint}/${projectId}/project`);
        }
        catch (error) {
            throw error;
        }        
    }
    
    async createNewMeasurment(measurement) {
        try {                        
            return axiosConfig.post(this.endpoint, measurement);
        }
        catch(error) {
            throw error;
        }
    }

    async deleteMeasurementByObservationAndMeasurmentId(measurmentId, observationId) {
        try {                      
            return axiosConfig.delete(`${this.endpoint}/${measurmentId}`, { data : {
                ObservationId : observationId
            }});
        }
        catch(error) {
            throw error;
        }        
    }

    async deleteMeasurementById(measurmentId) {
        try {                      
            return axiosConfig.delete(`${this.endpoint}/${measurmentId}`);
        }
        catch(error) {
            throw error;
        }        
    }

    async updateMeasurment(measurement) {
        try {
            return axiosConfig.put(`${this.endpoint}/${measurement.measureId}`, measurement)
        }
        catch(error) {
            throw error;
        }        
    }
}

mock.onPost("/Measurement").reply(200, {
    measureId : "f3636c90-cd1c-11ea-87d0-0242ac130003",
    observationId : "b3636c70-cd1c-11ea-87d0-0242ac130003",
    projectId : "c3636c70-cd1c-11ea-87d0-0242ac130011",
    name : "MeasurementName",
    items : [{
        fieldId : "textboxId",
        version : "1",
        fieldType : "textboxwithunit",
        value : "0.4",  
        unit : "Ohm",
        text : "",
        description : "",  
      }]   
});

mock.onPut(/Measurement\/.+/).reply(200, {    
});


mock.onDelete(/Measurement\?id.+/).reply(200);

mock.onGet(/Measurement\?projectId.+/).reply(200,
    [{
        measureId : "a3636c70-cd1c-11ea-87d0-0242ac130003",
        observationId : "b3636c70-cd1c-11ea-87d0-0242ac130003",
        projectId : "c3636c70-cd1c-11ea-87d0-0242ac130011",
        type : "measurelist",
        name : "Måle liste",
        items : [{
            fieldId : "textboxId",
            version : "1",
            fieldType : "textboxwithunit",
            value : "0.2",  
            unit : "Ohm",
            text : "",
            description : "",  
          },
          {
            fieldId : "textboxId",
            version : "1",
            fieldType : "textboxwithunit",
            value : "22.2",  
            unit : "Watt",
            text : "",
            description : "",  
          }]
    },
    {     
        measureId : "f3636c70-cd1c-11ea-87d0-0242ac130003",
        observationId : "g3636c70-cd1c-11ea-87d0-0242ac130003",
        projectId : "h3636c70-cd1c-11ea-87d0-0242ac130011",
        name : "Måleliste",
        type : "measurelist",
        items : [{
            fieldId : "textboxId",
            version : "1",
            fieldType : "textboxwithunit",
            value : "1.2",  
            unit : "Ampere",
            text : "",
            description : "",  
          }]
    },
    {     
        measureId : "f3636c70-cd1c-aaea-87d0-0242ac130003",
        observationId : "g3636c70-c61c-11ea-87d0-0242ac130003",
        projectId : "h3636c70-cd1c-11ea-87d0-0242ac130011",
        type : "checkedlist",
        name : "Sjekk liste",
        options : ["Yes", "No"],
        items : [{
            fieldId : "121212121244",
            version : "1",
            fieldType : "checkbox",            
            selected : "Yes",
            value : "1.2",  
            unit : "Ampere",
            text : "tester123",
            description : "description",  
          }]
    }]
);


// mock.onGet(/Measurement\?projectId.+/).reply(200,
//     [{
//         measureId : "a3636c70-cd1c-11ea-87d0-0242ac130003",
//         observationId : "b3636c70-cd1c-11ea-87d0-0242ac130003",
//         projectId : "c3636c70-cd1c-11ea-87d0-0242ac130011",
//         name : "Ohm",
//         value : "0.2",        
//     },
//     {     
//         measureId : "f3636c70-cd1c-11ea-87d0-0242ac130003",
//         observationId : "g3636c70-cd1c-11ea-87d0-0242ac130003",
//         projectId : "h3636c70-cd1c-11ea-87d0-0242ac130011",
//         name : "Ampere",
//         value : "1.2",
//     }]
// );

