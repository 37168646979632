import {
    GET_SETTINGPROJECTSTATUSES,
    GET_SETTINGPROJECTSTATUSES_SUCCESS,
    GET_SETTINGPROJECTSTATUSES_FAILURE,
    GET_SETTINGOBSERVATIONTYPES_SUCCESS,
    GET_SETTINGOBSERVATIONTYPES_FAILURE, 
    GET_SETTINGOBSERVATIONTYPES,
    GET_SETTINGDEVIATIONCODES,
    GET_SETTINGDEVIATIONCODES_SUCCESS,
    GET_SETTINGDEVIATIONCODES_FAILURE,
    GET_SETTINGOBSERVATIONCATEGORIES,    
    GET_SETTINGOBSERVATIONCATEGORIES_SUCCESS,
    GET_SETTINGOBSERVATIONCATEGORIES_FAILURE,
    CREATE_SETTINGDEVIATIONCODES,
    CREATE_SETTINGDEVIATIONCODES_SUCCESS,
    CREATE_SETTINGDEVIATIONCODES_FAILURE,
    UPDATE_SETTINGDEVIATIONCODES,
    UPDATE_SETTINGDEVIATIONCODES_SUCCESS,
    UPDATE_SETTINGDEVIATIONCODES_FAILURE,
    DELETE_SETTINGDEVIATIONCODES,
    DELETE_SETTINGDEVIATIONCODES_SUCCESS,
    DELETE_SETTINGDEVIATIONCODES_FAILURE,
  } from '../actions/constants'
  
  const initialState = {
    dataObservationTypes : [],
    dataDeviationCodes : [],  
    dataObservationCategories : [],
    projectStatusTypes : [],
    error: null,
    loading: false,
  };
  
  
  export const settingReducer = (state = initialState, action) => {  
    switch (action.type) {
      case DELETE_SETTINGDEVIATIONCODES:
      case UPDATE_SETTINGDEVIATIONCODES:
      case CREATE_SETTINGDEVIATIONCODES:
      case GET_SETTINGOBSERVATIONCATEGORIES:
      case GET_SETTINGDEVIATIONCODES:
      case GET_SETTINGPROJECTSTATUSES:
      case GET_SETTINGOBSERVATIONTYPES:
        return {...state, ...{loading: true, error: null}}            
      case GET_SETTINGOBSERVATIONTYPES_SUCCESS : 
        return {...state, ...{loading: false, dataObservationTypes : action.data, error: ''}}      
      case GET_SETTINGPROJECTSTATUSES_SUCCESS : 
        return {...state, ...{loading: false, projectStatusTypes : action.data, error: ''}}      
      case GET_SETTINGDEVIATIONCODES_SUCCESS : 
        return {...state, ...{loading: false, dataDeviationCodes : action.data, error: ''}}            
      case GET_SETTINGOBSERVATIONCATEGORIES_SUCCESS:
        return {...state, ...{loading: false, dataObservationCategories : action.data, error: ''}}            
      case CREATE_SETTINGDEVIATIONCODES_SUCCESS: 
        return {...state, ...{loading: false, dataDeviationCodes : state.dataDeviationCodes.concat(action.data), error: ''}}       
      case UPDATE_SETTINGDEVIATIONCODES_SUCCESS: {        
        let items = [...state.dataDeviationCodes];
        let index = items.findIndex(item => item.deviationCodeTypeId === action.data.deviationCodeTypeId);               
        items[index] = action.data;        
        return {...state, ...{loading: false, dataDeviationCodes : items, error: ''}}                  
      }
      case DELETE_SETTINGDEVIATIONCODES_SUCCESS: {
        let items = state.dataDeviationCodes.filter(item => item.deviationCodeTypeId !== action.data);                       
        return {...state, ...{loading: false, dataDeviationCodes : items, error: ''}}                  
      }
      case DELETE_SETTINGDEVIATIONCODES_FAILURE:
      case UPDATE_SETTINGDEVIATIONCODES_FAILURE:
      case CREATE_SETTINGDEVIATIONCODES_FAILURE:
      case GET_SETTINGOBSERVATIONCATEGORIES_FAILURE:
      case GET_SETTINGDEVIATIONCODES_FAILURE:
      case GET_SETTINGOBSERVATIONTYPES_FAILURE:     
      case GET_SETTINGPROJECTSTATUSES_FAILURE:
        return {...state, loading: false, error : action.data.error };    
      
      default:      
        return state;
    }
  };