import {
    GET_LOCATION_SUCCESS,
    GET_LOCATION_FAILURE, 
    GET_LOCATION,      
    CREATE_LOCATION_SUCCESS,
    CREATE_LOCATION_FAILURE, 
    CREATE_LOCATION,    
    DELETE_LOCATION,
    DELETE_LOCATION_SUCCESS,
    DELETE_LOCATION_FAILURE,
    UPDATE_LOCATION,    
    UPDATE_LOCATION_SUCCESS,    
    UPDATE_LOCATION_FAILURE,                            
  } from '../actions/constants'

import { deepCopy } from '../utils/deepCopy';

  
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
  };
  
  
  export const locationReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_LOCATION:
      case CREATE_LOCATION:      
      case DELETE_LOCATION:
      case UPDATE_LOCATION:
        return {...state, ...{loading: true, error: null}}      
      case GET_LOCATION_SUCCESS :      
        return {...state, ...{loading: false, data : action.data, error: ''}}      
      case CREATE_LOCATION_SUCCESS : {  
        let newArr = state.data.concat(action.data);
        return {...state, ...{loading: false, data : newArr, error: ''}}            
      }
      case DELETE_LOCATION_SUCCESS: {        
        let copyArr = deepCopy(state.data);
        let index = copyArr.findIndex(item => item.locationId === action.data.locationId);
        copyArr.splice(index, 1);

        return {...state, ...{loading: false, data : copyArr, error: ''}}      
      }
      case UPDATE_LOCATION_SUCCESS: {
        let copyArr = deepCopy(state.data);
        let index = copyArr.findIndex(item => item.locationId === action.data.locationId);
        copyArr[index].name = action.data.name;

        return {...state, ...{loading: false, data : copyArr, error: ''}}      
      }
      case GET_LOCATION_FAILURE:     
      case CREATE_LOCATION_FAILURE:      
      case DELETE_LOCATION_FAILURE:
      case UPDATE_LOCATION_FAILURE:
        return {...state, loading: false, error : action.data.error };      
      default:      
        return state;
    }
  };