import {
    GET_EQUIPMENTTYPE_SUCCESS,
    GET_EQUIPMENTTYPE_FAILURE, 
    GET_EQUIPMENTTYPE,     
    CREATE_EQUIPMENTTYPE_SUCCESS,
    CREATE_EQUIPMENTTYPE_FAILURE, 
    CREATE_EQUIPMENTTYPE,
    DELETE_EQUIPMENTTYPE,
    DELETE_EQUIPMENTTYPE_SUCCESS,
    DELETE_EQUIPMENTTYPE_FAILURE,
    UPDATE_EQUIPMENTTYPE,    
    UPDATE_EQUIPMENTTYPE_SUCCESS,    
    UPDATE_EQUIPMENTTYPE_FAILURE,
  } from '../actions/constants'  
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
  };  
  
  export const equipmentTypeReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_EQUIPMENTTYPE:
      case CREATE_EQUIPMENTTYPE:
      case DELETE_EQUIPMENTTYPE:
      case UPDATE_EQUIPMENTTYPE:
        return {...state, ...{loading: true, error: null}}      
        
      case GET_EQUIPMENTTYPE_SUCCESS :      
        return {...state, ...{loading: false, data : action.data, error: ''}}      
      case CREATE_EQUIPMENTTYPE_SUCCESS :  {       
        let newArr = state.data.concat(action.data);
        return {...state, ...{loading: false, data : newArr, error: ''}}                 
      }
      case DELETE_EQUIPMENTTYPE_SUCCESS: {        
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.equipmentTypeId === action.data.equipmenTypetId);
        copyArr.splice(index, 1);

        return {...state, ...{loading: false, data : copyArr, error: ''}}      
      }
      case UPDATE_EQUIPMENTTYPE_SUCCESS: {
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.equipmentTypeId === action.data.equipmentTypeId);
        copyArr[index] = action.data;       

        return {...state, ...{loading: false, data : copyArr, error: ''}}      
      }

      case GET_EQUIPMENTTYPE_FAILURE:     
      case CREATE_EQUIPMENTTYPE_FAILURE:
      case DELETE_EQUIPMENTTYPE_FAILURE:
      case UPDATE_EQUIPMENTTYPE_FAILURE:
        return {...state, loading: false, error : action.data.error };      
      default:      
        return state;
    }
  };