import {
    GET_MEASUREMENT_SUCCESS,
    GET_MEASUREMENT_FAILURE, 
    GET_MEASUREMENT,  
    DELETE_MEASUREMENT_SUCCESS,
    DELETE_MEASUREMENT_FAILURE, 
    DELETE_MEASUREMENT, 
    CREATE_MEASUREMENT, 
    CREATE_MEASUREMENT_SUCCESS,
    CREATE_MEASUREMENT_FAILURE, 
    UPDATE_MEASUREMENT,
    UPDATE_MEASUREMENT_SUCCESS,
    UPDATE_MEASUREMENT_FAILURE    
  } from '../actions/constants'
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
  };
  
  
  export const measurementReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_MEASUREMENT:
        return {...state, ...{loading: true, error: null}}      
      case GET_MEASUREMENT_SUCCESS :      
        return {...state, ...{loading: false, data : action.data, error: ''}}      
      case GET_MEASUREMENT_FAILURE:     
        return {...state, loading: false, error : action.data.error };
      case DELETE_MEASUREMENT:
            return {...state, ...{loading: true, error: null}}      
      case DELETE_MEASUREMENT_SUCCESS: {        
        const list = state.data.filter(item => item.measureId !== action.data);      
        return {...state, ...{loading: false, error : '', data : list }}; 
      }
      case DELETE_MEASUREMENT_FAILURE:             
        return {...state, loading: false, error : action.data.error };  
      case CREATE_MEASUREMENT:
        return {...state, ...{loading: true, error : null}}      
      case CREATE_MEASUREMENT_SUCCESS: { 
        var items = state.data.concat(action.data);
        return {...state, ...{loading: false, data : items,  error : ''}};
      }
      case CREATE_MEASUREMENT_FAILURE:      
        return {...state, loading: false, error : action.data.error };
      case UPDATE_MEASUREMENT:        
        return {...state, ...{loading: true, error: null}}      
      case UPDATE_MEASUREMENT_SUCCESS: {                    
        let newArr = [...state.data]; 
        let index = newArr.findIndex(item => item.measureId === action.data.measureId);
        
        var newData = Object.assign({}, action.data);
        if (index >= 0)
            newArr.splice(index, 1, newData)
  
        return {...state, ...{loading: false, data :  newArr, error : ''}};
      }    
      case UPDATE_MEASUREMENT_FAILURE:
        return {...state, loading: false, error : action.data.error };     
      default:      
        return state;
    }
  };