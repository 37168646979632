import { put, takeLatest, call } from 'redux-saga/effects'
import { EquipmentApi, FileUploadApi } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

import { 
  GET_EQUIPMENT,
  GET_EQUIPMENT_SUCCESS, 
  GET_EQUIPMENT_FAILURE,    
  CREATE_EQUIPMENT,
  CREATE_EQUIPMENT_SUCCESS,
  CREATE_EQUIPMENT_FAILURE,
  DELETE_EQUIPMENT_SUCCESS,
  DELETE_EQUIPMENT_FAILURE,
  DELETE_EQUIPMENT,
  UPDATE_EQUIPMENT_SUCCESS,
  UPDATE_EQUIPMENT_FAILURE,
  UPDATE_EQUIPMENT  
 } from '../actions/constants' 

export function* getAllEquipments() {
  var api = new EquipmentApi();

  try {          
    const response = yield call(() => api.getEquipments());    
    
    yield put({ type: GET_EQUIPMENT_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: GET_EQUIPMENT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createEquipment(action) {
    var api = new EquipmentApi();
    var fileApi = new FileUploadApi();
  
    try {                
      var equipment = action.payload.equipment;
      var extra = action.payload.extra;
            
      if (extra.fileUrl) {      
        var cropResponse = yield call(() => fileApi.cropFile(extra.fileUrl, extra.croppedImageData));         
        equipment.logoUrl = cropResponse.data.croppedFileUrl;
      }      

      yield call(() => api.createEquipment(equipment));            
  
      yield put({ type: CREATE_EQUIPMENT_SUCCESS, data: equipment });
    } catch(err) {        
      yield put({ type: CREATE_EQUIPMENT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
    }  
  }
  

export function* deleteEquipment(action) {
  var api = new EquipmentApi();

  try {                
    var equipment = action.payload;        

    yield call(() => api.deleteEquipment(equipment.equipmentId, equipment.equipmentTypeId));            

    yield put({ type: DELETE_EQUIPMENT_SUCCESS, data: equipment.equipmentId });
  } catch(err) {        
    yield put({ type: DELETE_EQUIPMENT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* updateEquipment(action) {
  var api = new EquipmentApi();

  try {                    
    var data = action.payload;        
    
    yield call(() => api.updateEquipment(data));            
    
    yield put({ type: UPDATE_EQUIPMENT_SUCCESS, data: data }); 
  } catch(err) {        
    yield put({ type: UPDATE_EQUIPMENT_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* watchEquipments() {
  yield takeLatest(GET_EQUIPMENT, getAllEquipments);
}

export function* watchDeleteEquipment() {
  yield takeLatest(DELETE_EQUIPMENT, deleteEquipment);
}

export function* watchUpdateEquipment() {
  yield takeLatest(UPDATE_EQUIPMENT, updateEquipment);
}

export function* watchCreateEquipment() {
    yield takeLatest(CREATE_EQUIPMENT, createEquipment);
}