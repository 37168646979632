import {
    SET_SALARYFORMHOURWAGE,
    SET_SALARYFORM_ACCORDIONHOURWAGE,
    DELETE_SALARYFORMHOURWAGE,
    SET_SALARYFORMADDITION,
    DELETE_SALARYFORMADDITION,
    SET_SALARYFORM_ACCORDIONADDITION

} from '../actions/constants'

const initialState = {
    hourType: {},
    additionType: {},
    hourTypeAccordionOpen: false,
    hourAdditionAccordionOpen: false
};


export const salaryFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_SALARYFORMHOURWAGE: {
            return { ...state, ...{ hourType: {} } }
        }
        case SET_SALARYFORMHOURWAGE:
            return { ...state, ...{ hourType: action.payload.data } };
        case SET_SALARYFORM_ACCORDIONHOURWAGE:
            return { ...state, ...{ hourTypeAccordionOpen: action.payload } };
        case DELETE_SALARYFORMADDITION: {
            return { ...state, ...{ additionType: {} } }
        }
        case SET_SALARYFORMADDITION:
            return { ...state, ...{ additionType: action.payload.data } };
        case SET_SALARYFORM_ACCORDIONADDITION:
            return { ...state, ...{ hourAdditionAccordionOpen: action.payload } };
        default:
            return state;
    }
};