import { put, takeLatest, call } from 'redux-saga/effects'
import { MeasurementApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import { 
  GET_MEASUREMENT,
  GET_MEASUREMENT_SUCCESS, 
  GET_MEASUREMENT_FAILURE,    
  DELETE_MEASUREMENT_SUCCESS,
  DELETE_MEASUREMENT_FAILURE,
  DELETE_MEASUREMENT,
  UPDATE_MEASUREMENT_SUCCESS,
  UPDATE_MEASUREMENT_FAILURE,
  UPDATE_MEASUREMENT,  
  CREATE_MEASUREMENT,
  CREATE_MEASUREMENT_SUCCESS,
  CREATE_MEASUREMENT_FAILURE
 } from '../actions/constants'

export function* getAllMeasurementsForProjects(action) {
  var api = new MeasurementApi();

  try {          
    const project = action.payload;
    const response = yield call(() => api.getAllMeasurmentsForProjects(project.projectId));        

    yield put({ type: GET_MEASUREMENT_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: GET_MEASUREMENT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createMeasurement(action) {
  var api = new MeasurementApi();

  try {                
    var measurement = action.payload;       
    
    var response = yield call(() => api.createNewMeasurment(measurement));            
    yield put({ type: CREATE_MEASUREMENT_SUCCESS, data: response.data });
  } catch(err) {        
    yield put({ type: CREATE_MEASUREMENT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
  }  
}


export function* deleteMeasurement(action) {
  var api = new MeasurementApi();

  try {                
    var measurementId = action.payload;
        
    yield call(() => api.deleteMeasurementById(measurementId));            
    yield put({ type: DELETE_MEASUREMENT_SUCCESS, data: measurementId });
  } catch(err) {        
    yield put({ type: DELETE_MEASUREMENT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* updateMeasurement(action) {
  var api = new MeasurementApi();

  try {                    
    var data = action.payload;           
    
    yield call(() => api.updateMeasurment(data));            
    
    yield put({ type: UPDATE_MEASUREMENT_SUCCESS, data: data }); 
  } catch(err) {        
    yield put({ type: UPDATE_MEASUREMENT_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* watchGetMeasurements() {
  yield takeLatest(GET_MEASUREMENT, getAllMeasurementsForProjects);
}

export function* watchCreateMeasurements() {
  yield takeLatest(CREATE_MEASUREMENT, createMeasurement);
}


export function* watchDeleteMeasureMeasurement() {
  yield takeLatest(DELETE_MEASUREMENT, deleteMeasurement);
}

export function* watchUpdateMeasurement() {
  yield takeLatest(UPDATE_MEASUREMENT, updateMeasurement);
}