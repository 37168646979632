import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class GroupApi {
    constructor() {
        this.endpoint = `/Group/`;
    }

    async getGroups() {
        try {            
            return axiosConfig.get(`${this.endpoint}`);
        }
        catch(error) {
            throw error;
        }        
    }

    async createGroup(group) {
        try {            
            return axiosConfig.post(`${this.endpoint}`, group);
        }
        catch(error) {
            throw error;
        }        
    }

    async updateGroup(intelliGroupId, group) {
        try {                      
            return axiosConfig.put(`${this.endpoint}${intelliGroupId}`, group);            
        }
        catch (error) {
            throw error;
        }        
    }            

    async deleteGroup(intelliGroupId) {
        try {                      
            return axiosConfig.delete(`${this.endpoint}${intelliGroupId}`);            
        }
        catch (error) {
            throw error;
        }        
    }            
}


mock.onGet(/Groups/).reply(200, [
    {
        intelliGroupId : '512121-12122-9912',        
        name: 'Timelønn',
        description : "Gruppe for arbeidere med timelønn"
        
    },
    {
        intelliGroupId : '212121-12122-9912',        
        name: 'Fastlønn',
        description : "Gruppe for arbeidere med fastlønn"
        
    },
]);