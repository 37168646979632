import axiosConfig from '../axiosConfig'
import { mock } from '../mock'

import {v1 as uuid} from 'uuid'

export default class ApprovalFlowApi {
    constructor() {        
        this.endpoint = `/ApprovalFlow`;         
    }

    async getApprovalFlows() {                     
        try {
            return axiosConfig.get(this.endpoint);
        }
        catch(error) {
            throw error;
        }                
    }    

    async createNewApprovalFlow(approvalFlow) {
        try {
            return axiosConfig.post(this.endpoint, approvalFlow);
        }
        catch(error) {
            throw error;
        }
    }

    async deleteApprovalFlowById(approvalFlowId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}/${approvalFlowId}`);
        }
        catch(error) {
            throw error;
        }
    }
    
    async updateApprovalFlow(approvalFlow) {
        try {
            return axiosConfig.put(`${this.endpoint}/${approvalFlow.approvalFlowId}`, approvalFlow);
        }
        catch(error) {
            throw error;
        }
    }
}

mock.onDelete("/ApprovaLFlow").reply(200);

mock.onPut("/ApprovaLFlow/.+/").reply(200, {
    approvalFlowId : uuid(),
    companyId: uuid(),
    groupId : uuid(),
    approvalFlowIdentityRoleLink  : [] 
});

mock.onGet('/ApprovaLFlow').reply(200, 
    [
        {
            approvalFlowId : uuid(),
            companyId: uuid(),
            groupId : uuid(),
            approvalFlowIdentityRoleLink  : [] 
        }        
    ]);

mock.onPost("/ApprovaLFlow").reply(200, {
    approvalFlowId : uuid(),
    companyId: uuid(),
    groupId : uuid(),
    approvalFlowIdentityRoleLink  : [] 
    });