/*Actions only to be sendt when recieving signals from socket*/
import {
    CREATE_NOTIFICATION,
    MARK_NOTIFICATIONASREAD
} from './constants'

export function createNotification(message, correlationId) {    
    return {
        type: CREATE_NOTIFICATION,
        payload: { message, correlationId}
    }
}

export function markNotificationsRead() {
    return {
        type: MARK_NOTIFICATIONASREAD,
        payload: {}
    }
}