import { CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAILURE, CREATE_ACCOUNT,CREATE_ACCOUNT_INITIALFORM } from '../actions/constants'

const initialState = {  
  error: null,
  loading: false,
  success: false,
};

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ACCOUNT:
      return { ...state, ...{ loading: true, error: null } }      
    case CREATE_ACCOUNT_SUCCESS: {                  
      return { ...state, ...{ loading: false, error: null, success: true } }
    }
    case CREATE_ACCOUNT_FAILURE: {          
      return { ...state, ...{loading: false, error: action.data.error }};
    }
    default:
      return state;
  }
};


export const initialFormAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ACCOUNT_INITIALFORM:          
      return  {...state, ...action.payload};
    default:
      return state;
  }
};