import { put, takeLatest, call } from 'redux-saga/effects'
import { SalaryApi  } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import {
  GET_SALARY,
  GET_SALARY_SUCCESS,
  GET_SALARY_FAILURE,
  CREATE_SALARY,
  CREATE_SALARY_SUCCESS,
  CREATE_SALARY_FAILURE,
  DELETE_SALARY_SUCCESS,
  DELETE_SALARY_FAILURE,
  DELETE_SALARY,
  UPDATE_SALARY_SUCCESS,
  UPDATE_SALARY_FAILURE,
  UPDATE_SALARY
 } from '../actions/constants'

export function* getAllSalaryTypes() {
  var api = new SalaryApi();

  try {
    const response = yield call(() => api.getSalaryTypes());

    yield put({ type: GET_SALARY_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_SALARY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createSalaryType(action) {
    let api = new SalaryApi();

    try {
      let salaryType = action.payload;
      let isArray = Array.isArray(salaryType);
      let salariesArr = isArray ? salaryType : [salaryType];

      for (let sal of salariesArr) {
        let type = yield call(() => api.createSalaryType(sal));
        yield put({ type: CREATE_SALARY_SUCCESS, data: type.data });
      }

    } catch(err) {
      yield put({ type: CREATE_SALARY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
    }
  }


export function* deleteSalaryType(action) {
  var api = new SalaryApi();

  try {
    var salaryTypeId = action.payload;

    yield call(() => api.deleteSalaryType(salaryTypeId));

    yield put({ type: DELETE_SALARY_SUCCESS, data: salaryTypeId });
  } catch(err) {
    yield put({ type: DELETE_SALARY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* updateSalaryType(action) {
  var api = new SalaryApi();

  try {
    var data = action.payload;

    yield call(() => api.updateSalaryType(data));

    yield put({ type: UPDATE_SALARY_SUCCESS, data: data });
  } catch(err) {
    yield put({ type: UPDATE_SALARY_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* watchGetSalaries() {
  yield takeLatest(GET_SALARY, getAllSalaryTypes);
}

export function* watchDeleteSalary() {
  yield takeLatest(DELETE_SALARY, deleteSalaryType);
}

export function* watchUpdateSalary() {
  yield takeLatest(UPDATE_SALARY, updateSalaryType);
}

export function* watchCreateSalary() {
    yield takeLatest(CREATE_SALARY, createSalaryType);
}