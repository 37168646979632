import {
        GET_GOODSCONSUMPTION,
        GET_GOODSCONSUMPTION_SUCCESS,
        GET_GOODSCONSUMPTION_FAILURE,
        CREATE_GOODSCONSUMPTION,
        CREATE_GOODSCONSUMPTION_SUCCESS,
        CREATE_GOODSCONSUMPTION_FAILURE,
        UPDATE_GOODSCONSUMPTION,
        UPDATE_GOODSCONSUMPTION_SUCCESS,
        UPDATE_GOODSCONSUMPTION_FAILURE,
        DELETE_GOODSCONSUMPTION,
        DELETE_GOODSCONSUMPTION_SUCCESS,
        DELETE_GOODSCONSUMPTION_FAILURE,
        GET_PROJECTCONSUMPTIONS,
        GET_PROJECTCONSUMPTIONS_SUCCESS,
        GET_PROJECTCONSUMPTIONS_FAILURE,
        APPROVE_GOODSCONSUMPTION,
        APPROVE_GOODSCONSUMPTION_SUCCESS,
        APPROVE_GOODSCONSUMPTION_RESET,
        APPROVE_GOODSCONSUMPTION_FAILURE,
        CHANGE_INVOICEITEMREMOTESYSTEMS,
        CHANGE_INVOICEITEMREMOTESYSTEMSYNCSTARTED
} from '../actions/constants'

import { deepCopy } from '../utils/deepCopy';

const initialState = {
        data: [],
        error: null,
        loading: false,
        saved: false,
        deleted: false
};

export const goodsConsumptionReducer = (state = initialState, action) => {
        switch (action.type) {
                case APPROVE_GOODSCONSUMPTION:
                case UPDATE_GOODSCONSUMPTION:
                case CREATE_GOODSCONSUMPTION:
                case DELETE_GOODSCONSUMPTION:
                case GET_PROJECTCONSUMPTIONS:
                case GET_GOODSCONSUMPTION: {
                        return { ...state, ...{ loading: true, error: null, saved: false, deleted: false } }
                }
                case APPROVE_GOODSCONSUMPTION_FAILURE:
                case UPDATE_GOODSCONSUMPTION_FAILURE:
                case DELETE_GOODSCONSUMPTION_FAILURE:
                case GET_GOODSCONSUMPTION_FAILURE:
                case GET_PROJECTCONSUMPTIONS_FAILURE:
                case CREATE_GOODSCONSUMPTION_FAILURE:
                        return { ...state, loading: false, error: action.data.error, saved: false, deleted: false };
                case GET_GOODSCONSUMPTION_SUCCESS: {
                        return { ...state, ...{ loading: false, data: action.data, error: '', saved: false, deleted: false } };
                }
                case GET_PROJECTCONSUMPTIONS_SUCCESS: {
                        return { ...state, ...{ loading: false, data: action.data, error: '', saved: false, deleted: false } };
                }
                case CREATE_GOODSCONSUMPTION_SUCCESS: {
                        let goodsConsumption = [...state.data];
                        goodsConsumption.splice(0, 0, action.data);

                        return { ...state, ...{ loading: false, data: goodsConsumption, error: '', saved: true, deleted: false } }
                }
                case UPDATE_GOODSCONSUMPTION_SUCCESS: {
                        let updCopy = [...state.data];
                        let foundIndex = updCopy.findIndex(item => item.goodsConsumptionId === action.data.goodsConsumptionId);

                        if (foundIndex >= 0)
                                updCopy.splice(foundIndex, 1, action.data);

                        return { ...state, ...{ loading: false, data: updCopy, error: '', saved: true, deleted: false } }
                }
                case APPROVE_GOODSCONSUMPTION_SUCCESS: {
                        let goodsData = action.data.goodsData;
                        let goodsCopy = deepCopy(state.data);

                        for (let itemGoods of goodsData) {
                                let foundItem = goodsCopy.find(item => item.goodsConsumptionId === itemGoods.goodsConsumptionId)
                                if (foundItem) {
                                        foundItem.isApproved = itemGoods.approve;
                                }
                        }

                        return { ...state, ...{ loading: false, error: '', data: goodsCopy, saved: true } }
                }
                case APPROVE_GOODSCONSUMPTION_RESET: {
                        return { ...state, loading: false, error: null, saved: false, deleted: false };
                }

                case CHANGE_INVOICEITEMREMOTESYSTEMS: {
                        let foundIndex = state.data.findIndex(item => item.goodsConsumptionId === action.payload.goodsConsumptionId);                        
                        if (foundIndex < 0) return state;

                        let arrStateCopy = deepCopy(state.data);

                        arrStateCopy[foundIndex].externalSystemRef = action.payload.externalSystemRef;
                        arrStateCopy[foundIndex].externalSystemRefError = action.payload.errorMessage;

                        arrStateCopy.splice(foundIndex, 1, arrStateCopy[foundIndex]);
                        return { ...state, ...{ data: arrStateCopy } };
                }
                case CHANGE_INVOICEITEMREMOTESYSTEMSYNCSTARTED: {
                        let arrRemoteStarted = deepCopy(state.data);
                        let arrConsumptionIds = action.data;

                        for (let consumptionId of arrConsumptionIds) {
                                let itemIndex = arrRemoteStarted.findIndex(item => item.goodsConsumptionId === consumptionId);
                                if (itemIndex < 0) continue;

                                arrRemoteStarted[itemIndex].externalSystemStartTime = new Date().toISOString();
                                arrRemoteStarted[itemIndex].externalSystemRefError = '';
                        }

                        return { ...state, ...{ data: arrRemoteStarted } };

                }
                case DELETE_GOODSCONSUMPTION_SUCCESS: {
                        let deleteCopy = [...state.data];

                        let deleteIndex = deleteCopy.findIndex(item => item.goodsConsumptionId === action.data);

                        if (deleteIndex >= 0)
                                deleteCopy.splice(deleteIndex, 1);

                        return { ...state, ...{ loading: false, data: deleteCopy, error: '', saved: false, deleted: true } }

                }
                default:
                        return state;
        }
};