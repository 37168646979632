import { put, takeLatest, call} from 'redux-saga/effects'
import { CustomerApi, LocationApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import { 
  GET_LOCATION, 
  GET_ALLLOCATION,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  CREATE_LOCATION,  
  CREATE_LOCATION_SUCCESS,  
  CREATE_LOCATION_FAILURE,  
  DELETE_LOCATION, 
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  UPDATE_LOCATION,    
  UPDATE_LOCATION_SUCCESS,    
  UPDATE_LOCATION_FAILURE,    
} from '../actions/constants'

export function* getLocationsForCustomer(action) {
  var api = new CustomerApi();
  try {    
    var data = action.payload;

    const response = yield call(() => api.getCustomerLocations(data.customerId));    
    yield put({ type: GET_LOCATION_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_LOCATION_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* getAllLocationsForAllCustomer() {
  var api = new LocationApi();
  try {    
    const response = yield call(() => api.getAllLocations());
    
    yield put({ type: GET_LOCATION_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_LOCATION_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* createLocationForCustomer(action) {
  var api = new CustomerApi();

  try {       
    var data = action.payload;            
    var response = yield call(() => api.createLocationForCustomer(data.customerId, data.locationName, data.parentId));    
    
    yield put({ type: CREATE_LOCATION_SUCCESS, data: response.data}); 
  } catch(err) {    
    
    yield put({ type: CREATE_LOCATION_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
  }  
}

// export function* createPlaceAtLocationForCustomer(action) {
//     var api = new CustomerApi();
  
//     try {       
//       var data = action.payload;            
//       var response = yield call(() => api.createPlaceAtLocationForCustomer(data.locationId, data.customerId, data.placeName));  
  
//       yield put({ type: CREATE_LOCATIONPLACE_SUCCESS, data: { placeId : response.data.placeId, name : response.data.name, locationId : data.locationId }}); 
//     } catch(err) {    
//       yield put({ type: CREATE_LOCATIONPLACE_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
//     }  
//   }

// export function* deletePlaceAtLocationForCustomer(action) {
//   var api = new CustomerApi();

//   try {                    
//     var locationId = action.payload.locationId;
//     var placeId = action.payload.placeId;
//     var customerId = action.payload.customerId;
    
//     yield call(() => api.deletePlaceAtLocationForCustomer(customerId, locationId, placeId));        
    
//     yield put({ type: DELETE_LOCATIONPLACE_SUCCESS, data: {locationId : locationId, placeId : placeId } });
//   } catch(err) {        
//     yield put({ type: DELETE_LOCATIONPLACE_FAILURE, data: {error : handleCommonHttpErrors(err.response)} }); 
//   }  
// }

export function* deleteLocationForCustomer(action) {
  var api = new CustomerApi();

  try {                    
    var locationId = action.payload.locationId;    
    var customerId = action.payload.customerId;
    
    yield call(() => api.deleteLocationForCustomer(customerId, locationId));        
    
    yield put({ type: DELETE_LOCATION_SUCCESS, data: {locationId : locationId } });
  } catch(err) {        
    yield put({ type: DELETE_LOCATION_FAILURE, data: {error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* updateCustomerLocation(action) {
  var api = new CustomerApi();

  try {                    
    var locationId = action.payload.locationId;    
    var customerId = action.payload.customerId;
    var name = action.payload.name;

    yield call(() => api.updateCustomerLocation(customerId, locationId, name));        
    
    yield put({ type: UPDATE_LOCATION_SUCCESS, data: {name, locationId} }); 
  } catch(err) {            
    yield put({ type: UPDATE_LOCATION_FAILURE, data:{ error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* watchGetCustomerLocations() {
  yield takeLatest(GET_LOCATION, getLocationsForCustomer);
}


export function* watchGetAllLocations() {
  yield takeLatest(GET_ALLLOCATION, getAllLocationsForAllCustomer);
}

export function* watchLocationCreate() {
  yield takeLatest(CREATE_LOCATION, createLocationForCustomer);
}

// export function* watchLocationPlaceCreate() {
//     yield takeLatest(CREATE_LOCATIONPLACE, createPlaceAtLocationForCustomer);
// }

// export function* watchLocationPlaceDelete() {
//     yield takeLatest(DELETE_LOCATIONPLACE, deletePlaceAtLocationForCustomer);
// }

export function* watchLocationDelete() {
  yield takeLatest(DELETE_LOCATION, deleteLocationForCustomer);
}

export function* watchLocationUpdate() {
  yield takeLatest(UPDATE_LOCATION, updateCustomerLocation);
}