export function handleCommonHttpErrors(error) {     
    if (error) {        
        if (error.status === 400) {
            return { errors : error.data.errors, message : error.data.message ? error.data.message : error.data};
        } else if (error.status === 500) {
            return error.data.Message;
        } else if (error.status === 405) {
            return "Mangler rettighet";
        } else if (error.status === 403) {
            return "Du har ikke tilgang";
        }
    } else if (!error) {
        return "Nettverks feil";        
    }

    return "Ukjent feil";
}