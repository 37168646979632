import {
  GET_PROJECTS_SUCCESS, 
  GET_PROJECTS_FAILURE, 
  GET_PROJECTS,  
  RESET_PROJECTDELETE_STATE,
  DELETE_PROJECT_SUCCESS,  
  DELETE_PROJECT_FAILURE, 
  CREATE_PROJECT, 
  CREATE_PROJECT_FAILURE, 
  CREATE_PROJECT_SUCCESS,
  UPDATE_PROJECT, 
  UPDATE_PROJECT_FAILURE, 
  UPDATE_PROJECT_SUCCESS,
  GET_PROJECTMACHINEHOURS,
  GET_PROJECTMACHINEHOURS_SUCCESS,
  GET_PROJECTMACHINEHOURS_FAILURE,
  CHANGE_INVOICEITEMMACHINEREMOTESYSTEMSYNCSTARTED,
  CHANGE_INVOICEITEMMACHINEREMOTESYSTEMS
} from '../actions/constants'

import { deepCopy } from '../utils/deepCopy';

const initialState = {
  data: [],
  machineUsage: [],
  error: null,
  loading: false,
  loadingMachineUsage: false,
  machineError : null,
  saved: false
};


export const projectReducer = (state = initialState, action) => {  
  switch (action.type) {
    case GET_PROJECTMACHINEHOURS: 
      return {...state, ...{loadingMachineUsage: true, loadingMachineError: null}} 
    case GET_PROJECTMACHINEHOURS_SUCCESS:
      return {...state, ...{loadingMachineUsage: false, machineUsage : action.data, loadingMachineError : ''}};           
    case GET_PROJECTMACHINEHOURS_FAILURE:
      return {...state, ...{loadingMachineUsage: false, loadingMachineError : action.data.error}};           
    case GET_PROJECTS:
      return {...state, ...{loading: true, error: null, saved : false}}      
    case GET_PROJECTS_SUCCESS :      
      return {...state, ...{loading: false, data : action.data, error: '', saved : false}}      
    case GET_PROJECTS_FAILURE:     
      return {...state, loading: false, error : action.data.error, saved : false };
   
    case DELETE_PROJECT_SUCCESS: {       
      const list = state.data.filter(item => item.projectId !== action.data.id);      
      return {...state, ...{loading: false, error : '', data : list, saved : false }}; 
    }
    case DELETE_PROJECT_FAILURE:             
      return {...state, loading: false, error : action.data.error, saved : false };
    case CREATE_PROJECT:
      return {...state, ...{loading: true, error : null, saved : false}}      
    case CREATE_PROJECT_SUCCESS: { 
      let item = action.data;       
      let newArr = state.data.concat(item);        

      return {...state, ...{loading: false, data : newArr, error : '', saved : true}};
    }
    case RESET_PROJECTDELETE_STATE: {
      return {...state, ...{loading: false, error : '', saved : false}};
    }
    case CREATE_PROJECT_FAILURE:      
      return {...state, loadingMachineUsage: false, error : action.data.error, saved : false };   
    
    case UPDATE_PROJECT:
      return {...state, ...{loading: true, error: null, saved : false}}      
    case UPDATE_PROJECT_SUCCESS: {            
      let newArr = [...state.data]; 
      let index = newArr.findIndex(item => item.projectId === action.data.projectId);

      if (index >= 0)
          newArr.splice(index, 1, action.data)
          
      return {...state, ...{loading: false, data :  newArr, error : '', saved : true}};
    }    
    case UPDATE_PROJECT_FAILURE:
      return {...state, loading: false, error : action.data.error,saved : false };   
    case CHANGE_INVOICEITEMMACHINEREMOTESYSTEMSYNCSTARTED: {      
      let arrRemoteStarted = deepCopy(state.machineUsage);
      let arrConsumptionIds = action.data;

      for (let machineLinkId of arrConsumptionIds) {
        let itemIndex = arrRemoteStarted.findIndex(item => item.hourMachineLinkId === machineLinkId);
        if (itemIndex < 0) continue;

        arrRemoteStarted[itemIndex].externalSystemStartTime = new Date().toISOString();
        arrRemoteStarted[itemIndex].externalSystemRefError = '';
      }

      return { ...state, ...{ machineUsage: arrRemoteStarted } };
    }
    case CHANGE_INVOICEITEMMACHINEREMOTESYSTEMS: {      
      let foundIndex = state.machineUsage.findIndex(item => item.hourMachineLinkId === action.payload.hourMachineLinkId);

      if (foundIndex < 0) return state;

      let arrStateCopy = deepCopy(state.machineUsage);

      arrStateCopy[foundIndex].externalSystemRef = action.payload.externalSystemRef;
      arrStateCopy[foundIndex].externalSystemRefError = action.payload.errorMessage;

      arrStateCopy.splice(foundIndex, 1, arrStateCopy[foundIndex]);
      return { ...state, ...{ machineUsage: arrStateCopy } };
    }
      
    default:      
      return state;
  }
};