import { 
  GET_COMPANYINFO_SUCCESS, 
  GET_COMPANYINFO_FAILURE, 
  GET_COMPANYINFO ,
  UPDATE_COMPANYINFO,
  UPDATE_COMPANYINFO_SUCCESS,
  UPDATE_COMPANYINFO_FAILURE,
  UPDATE_COMPANYSETTING,
  UPDATE_COMPANYSETTING_SUCCESS,
  UPDATE_COMPANYSETTING_FAILURE,
  RESET_COMPANYSETTING
} from '../actions/constants'

const initialState = {  
  error: null,
  loading: false,
  saved : false,
  success: false,
  data : {},
  settings : {}
};

export const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANYINFO:
    case UPDATE_COMPANYSETTING:
    case UPDATE_COMPANYINFO:
      return { ...state, ...{ loading: true, error: null, saved : false } }          
    case GET_COMPANYINFO_SUCCESS: 
      return { ...state, ...{ loading: false, error: null, success: true, data : action.data.companyInfo, settings : action.data.companySetting, saved : false } }
    case UPDATE_COMPANYINFO_SUCCESS:     
      return { ...state, ...{ loading: false, error: null, success: true, data : action.data.companyInfo, saved : true } }    
    case UPDATE_COMPANYSETTING_SUCCESS:     
      return { ...state, ...{ loading: false, error: null, success: true, settings : action.data.companySetting, saved : true } }    
    case GET_COMPANYINFO_FAILURE:
    case UPDATE_COMPANYSETTING_FAILURE:
    case UPDATE_COMPANYINFO_FAILURE: {          
      return { ...state, ...{loading: false, error: action.data.error, saved : false }};
    }
    case RESET_COMPANYSETTING: {
      return { ...state, ...{ error: null, saved : false } }          
    }
    default:
      return state;
  }
}; 
