import { put, takeLatest, call} from 'redux-saga/effects'
import { ActivityApi } from '../api'
//import {getCompanyId} from '../utils/globalDataUtil';
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import { 
  GET_ACTIVITY, 
  GET_ACTIVITY_SUCCESS, 
  GET_ACTIVITY_FAILURE,
  SAVE_ACTIVITY,
  SAVE_ACTIVITY_FAILURE,
  SAVE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  RESET_ACTIVITYSAVED  
} from '../actions/constants'

export function* getAllActivities() {       
  var api = new ActivityApi();  
    
  try { 
        const response = yield call(() => api.getActivities());         
        yield put({ type: GET_ACTIVITY_SUCCESS, data: response.data }); 
    } catch(err) {             
        yield put({ type: GET_ACTIVITY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* createNewActivity(action) {
  var api = new ActivityApi();

  try {                     
    var data = action.payload;      
    
    let activityArr = Array.isArray(data) ? data : [data];
    
    for (let actitivty of activityArr) {
      var response = yield call(() => api.createNewActivity(actitivty));      
      yield put({ type: SAVE_ACTIVITY_SUCCESS, data: response.data});       
    }    
    
  } catch(err) {    
    yield put({ type: SAVE_ACTIVITY_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });   
  }  
}

export function* deleteActivity(action) {
  var api = new ActivityApi();

  try {                
    var activityId = action.payload.activityId;
    
    yield call(() => api.deleteActivityId(activityId));            
    yield put({ type: DELETE_ACTIVITY_SUCCESS, data: {id : activityId } });    
  } catch(err) {        
    yield put({ type: DELETE_ACTIVITY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* updateActivity(action) {
  var api = new ActivityApi();

  try {                    
    var data = action.payload; 
    
    yield call(() => api.updateActivity(data));        
    
    yield put({ type: UPDATE_ACTIVITY_SUCCESS, data: data });     
  } catch(err) {        
    yield put({ type: UPDATE_ACTIVITY_FAILURE, data:{error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* resetActivitySavedFlag() {
    yield put({ type: RESET_ACTIVITYSAVED })   
}

export function* watchGetAllActivities() {
  yield takeLatest(GET_ACTIVITY, getAllActivities);
}

export function* watchActivityCreate() {
  yield takeLatest(SAVE_ACTIVITY, createNewActivity);
}

export function* watchActivityDelete() {
  yield takeLatest(DELETE_ACTIVITY, deleteActivity);
}

export function* watchActivityUpdate() {
  yield takeLatest(UPDATE_ACTIVITY, updateActivity);
}

export function* watchActivityResetSaved() {
  yield takeLatest(UPDATE_ACTIVITY, updateActivity);
}