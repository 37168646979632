import {
    GET_OBSERVATION_SUCCESS, 
    GET_OBSERVATION_FAILURE, 
    GET_OBSERVATION,  
    DELETE_OBSERVATION_SUCCESS,  
    DELETE_OBSERVATION_FAILURE, 
    CREATE_OBSERVATION, 
    CREATE_OBSERVATION_FAILURE, 
    CREATE_OBSERVATION_SUCCESS,
    CREATE_OBSERVATIONMEASUREMENT,
    CREATE_OBSERVATIONMEASUREMENT_SUCCESS,
    CREATE_OBSERVATIONMEASUREMENT_FAILURE,
    DELETE_OBSERVATIONMEASUREMENT,
    DELETE_OBSERVATIONMEASUREMENT_SUCCESS,
    DELETE_OBSERVATIONMEASUREMENT_FAILURE,
    UPDATE_OBSERVATION, 
    UPDATE_OBSERVATION_FAILURE, 
    UPDATE_OBSERVATION_SUCCESS
  } from '../actions/constants'

  import {deepCopy} from '../utils/deepCopy'
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
    saved : false
  };
  
  
  export const observationReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_OBSERVATION:
        return {...state, ...{loading: true, error: null, saved : false}}      
      case GET_OBSERVATION_SUCCESS :         
        return {...state, ...{loading: false, data : action.data, error: '', saved : false}}      
      case GET_OBSERVATION_FAILURE:     
        return {...state, loading: false, error : action.data.error, saved : false };
     
      case DELETE_OBSERVATION_SUCCESS: {    
        const list = state.data.filter(item => item.observationId !== action.data.observationId);      
        return {...state, ...{loading: false, error : '', data : list }}; 
      }
      case DELETE_OBSERVATION_FAILURE:             
        return {...state, loading: false, error : action.data.error };
  
      case CREATE_OBSERVATION:
        return {...state, ...{loading: true, error : null, saved : false}}      
      case CREATE_OBSERVATION_SUCCESS: { 
        return {...state, ...{loading: false,  error : '', saved : true}};
      }
      case CREATE_OBSERVATION_FAILURE:      
        return {...state, loading: false, error : action.data.error, saved : false };

      case UPDATE_OBSERVATION:
        return {...state, ...{loading: true, error: null, saved : false}}      
      case UPDATE_OBSERVATION_SUCCESS: {                  
        let newArr = [...state.data]; 
        let index = newArr.findIndex(item => item.projectId === action.data.id);
        
        if (index >= 0)
            newArr.splice(index, 1, action.data)
  
        return {...state, ...{loading: false, data :  newArr, error : '', saved : true}};
      }    
      case UPDATE_OBSERVATION_FAILURE:
        return {...state, loading: false, error : action.data.error, saved : false };     
      
      case CREATE_OBSERVATIONMEASUREMENT:
        return {...state, ...{loading: true, error: null}}      

      case CREATE_OBSERVATIONMEASUREMENT_SUCCESS: {
        let index = state.data.findIndex(item => item.observationId === action.data.observationId);
        
        let newArr = [...state.data[index].measurements];        
        newArr.push(action.data);        
        
        let copy = deepCopy(state.data);
        copy[index].measurements = newArr;

        return {...state, ...{loading: false, error : '', data : copy}};
      }

      case CREATE_OBSERVATIONMEASUREMENT_FAILURE:
        return {...state, loading: false, error : action.data.error };

      case DELETE_OBSERVATIONMEASUREMENT:
        return {...state, ...{loading: true, error: null}}      

      case DELETE_OBSERVATIONMEASUREMENT_SUCCESS: {
        let index = state.data.findIndex(item => item.observationId === action.data.observationId);
        
        let newArr = [...state.data[index].measurements];   
        let measureIndex = newArr.findIndex(item => item.observationId === action.data.measurementId);
        newArr.splice(measureIndex, 1)        

        let copy = deepCopy(state.data);
        copy[index].measurements = newArr;

        return {...state, ...{loading: false, error : '', data : copy}};
      }

      case DELETE_OBSERVATIONMEASUREMENT_FAILURE:
        return {...state, loading: false, error : action.data.error };

      default:      
        return state;
    }
  };