import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class FileUploadApi {
    constructor() {
        this.endpoint = `/FileUpload`;
    }
    
    async uploadFile(file, fileTypeTag, onProgress) {        
        try {            
            let data = new FormData()
            data.append('file', file)
            data.append('typeOfFile', fileTypeTag)
            
            const config = {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: onProgress,
            };

            return axiosConfig.post(this.endpoint, data, config);
        }
        catch(error) {            
            throw error;
        }
    }    

    async cropFile(file, cropData) {        
        try {          
            cropData.fileId = file;
            return axiosConfig.post(`${this.endpoint}/crop`, cropData);
        }
        catch (error) {
            throw error;
        }        
    }    
}

function sleep(ms) {  
    return new Promise(resolve => setTimeout(resolve, ms));
  }

mock.onPost("FileUpload").reply(async (config) => {
    const total = 1024; // mocked file size
    for (const progress of [0, 0.2, 0.4, 0.6, 0.8, 1]) {
        await sleep(200);
        if (config.onUploadProgress) {
            config.onUploadProgress({ loaded: total * progress, total, lengthComputable:true });
        }
    }  

    return [200, {fileUrl : "https://homepages.cae.wisc.edu/~ece533/images/airplane.png"}];
});
