import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class SalaryApi {
    constructor() {
        this.endpoint = `/SalaryType`;
    }

    async getSalaryTypes() {
        try {            
            return axiosConfig.get(`${this.endpoint}`);
        }
        catch (error) {
            throw error;
        }        
    }            

    async createSalaryType(salaryType) {
        try {
            return axiosConfig.post(`${this.endpoint}`, salaryType);
        }
        catch (error) {
            throw error;
        }        
    }    

    async updateSalaryType(salaryType) {        
        try {
            return axiosConfig.put(`${this.endpoint}/${salaryType.salaryTypeId}`, salaryType);
        }
        catch (error) {
            throw error;
        }        
    }    

    async deleteSalaryType(salaryTypeId) {
        try {
            return axiosConfig.delete(`${this.endpoint}/${salaryTypeId}`);
        }
        catch (error) {
            throw error;
        }        
    }    
}

mock.onGet("SalaryType").reply(200, 
     [
         {
            salaryTypeId : '12277654-4545-2323-23',
            companyId : '1221212-4545-2323-23',
            type : 'Fastlønn',
            code : '200',
            isUnitAddition : false,
         },
         {
            salaryTypeId : '22277654-4545-2323-45623',
            companyId : '2221212-4545-2323-99',
            type : 'Timelønn',
            code : '250',
            isUnitAddition : false,
         },
         {
            salaryTypeId : '1477654-4545-2323-45623',
            companyId : '4421212-4545-2323-99',
            type : 'Overtidstilleg 50%',
            code : '260',
            isUnitAddition : true,
         }
     ]    
);

mock.onPost(/\/SalaryType\/.+\//).reply(200, {
    salaryTypeId : '1477654-4545-2323-45623',
    companyId : '4421212-4545-2323-99',
    type : 'Overtidstilleg 50%',
    code : '260',
    isUnitAddition : true,
 });
  

mock.onDelete("SalaryType").reply(200);

