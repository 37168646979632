import {
    GET_CURRENTUSER_SUCCESS, 
    GET_CURRENTUSER_FAILURE, 
    GET_CURRENTUSER,
    UPDATE_LANGUAGE_CURRENTUSER,
    UPDATE_LOGO_CURRENTUSER,
    REMOVE_LOGO_CURRENTUSER,
    RESET_CURRENTUSER_SAVEDSTATE,
    CHANGE_USERPASSWORD,
    CHANGE_USERPASSWORD_SUCCESS,
    CHANGE_USERPASSWORD_FAILURE,    
    DELETE_MODULEFORCOMPANY,
    DELETE_MODULEFORCOMPANY_SUCCESS,
    DELETE_MODULEFORCOMPANY_FAILURE,
    CREATE_MODULEFORCOMPANY,
    CREATE_MODULEFORCOMPANY_SUCCESS,
    CREATE_MODULEFORCOMPANY_FAILURE,
    SET_CURRENTUSERSETTING,
    REMOVE_CURRENTUSERMODULESETTING
    } from '../actions/constants'
  
  const initialState = {
    data: null,  
    abilities : null,
    settings : null,
    modules: null,
    error: null,
    loading: false,
    saved : false,
  };  
  
  export const currentUserReducer = (state = initialState, action) => {  
    switch (action.type) {
      case CREATE_MODULEFORCOMPANY:
      case DELETE_MODULEFORCOMPANY:
      case GET_CURRENTUSER:
      case CHANGE_USERPASSWORD:
        return {...state, ...{loading: true, error : null, saved : false}}      
      case GET_CURRENTUSER_SUCCESS:            
        return {...state, ...{loading: false, error : '', data : action.data.userData, abilities : action.data.abilities, settings : action.data.settings, modules : action.data.modules}}      
      case GET_CURRENTUSER_FAILURE:     
        return {...state, loading: false, error : "" };    
      case UPDATE_LANGUAGE_CURRENTUSER:        
        return {...state, data : {...state.data, languageCode : action.data.languageCode, saved : false}}    
      case UPDATE_LOGO_CURRENTUSER: {        
        if (!state.data || state.data.userId !== action.data.userId) 
          return state;        
        
        return {...state, data : {...state.data, avatarUrl : action.data.avatarUrl}, saved : false}    
      }
      case CHANGE_USERPASSWORD_SUCCESS: {
        return {...state, ...{loading: false, saved : true}}      
      } 
      case CHANGE_USERPASSWORD_FAILURE: {
        return {...state, ...{loading: false, saved : false, error : action.data.error}}      
      }   
      case REMOVE_LOGO_CURRENTUSER: {               
        return {...state, data : {...state.data, avatarUrl : null}, saved : false}    
      }
      case RESET_CURRENTUSER_SAVEDSTATE: {
        return {...state, ...{saved : false, loading : false}}            
      }   
      case DELETE_MODULEFORCOMPANY_SUCCESS: {
        let filter = state.modules.filter(item => item.modulesForCompanyId !== action.data);     

        return {...state, ...{saved : false, loading : false, modules : filter}}            
      }      
      case DELETE_MODULEFORCOMPANY_FAILURE: {
        return {...state, ...{loading: false, saved : false}}              
      }     
      case CREATE_MODULEFORCOMPANY_SUCCESS: {
        let copyModule = [...state.modules];
        copyModule.push(action.data);        

        return {...state, ...{saved : false, loading : false, modules : copyModule}}            
      }
      case CREATE_MODULEFORCOMPANY_FAILURE: {
        return {...state, ...{loading: false, saved : false}}              
      }
      case SET_CURRENTUSERSETTING: {        
        return {...state, ...{settings: action.data.settings}}                     
      }      
      case REMOVE_CURRENTUSERMODULESETTING: {
        const list = state.modules.filter(item => item.modulesForCompanyId !== action.data);      

        return {...state, ...{ modules: list }};                   
      }
      default:      
        return state;
    }
  };