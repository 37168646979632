import { 
    GET_EMPLOYEEATPROJECTS,
    GET_EMPLOYEEATPROJECTS_SUCCESS,
    GET_EMPLOYEEATPROJECTS_FAILURE,
    DELETE_EMPLOYEEATPROJECTS,
    UPDATE_EMPLOYEEATPROJECTS,
  } from '../actions/constants'
  
  const initialState = {
    data: [],
    error: null,
    loading: false,
    saved: false,
  };
  
  export const employeeAtProjectReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_EMPLOYEEATPROJECTS:
        return { ...state, ...{ loading: true, error: null } }
      case GET_EMPLOYEEATPROJECTS_SUCCESS:
        return { ...state, ...{ loading: false, data: action.data, error: '' } }
      case GET_EMPLOYEEATPROJECTS_FAILURE:
        return { ...state, loading: false, error: action.data.error };   
      
      case DELETE_EMPLOYEEATPROJECTS: {
        const list = state.data.filter(item => item.intelliUserId !== action.payload.intelliUserId);      
        return {...state, ...{loading: false, error : '', data : list }};           
      }
    
      case UPDATE_EMPLOYEEATPROJECTS: {
        let newArr = [...state.data]; 
        let index = newArr.findIndex(item => item.intelliUserId === action.payload.intelliUserId && item.projectId === action.payload.projectId);
        
        if (index >= 0)
            newArr.splice(index, 1, action.payload)
        else
            newArr.push(action.payload)
  
        return {...state, ...{data : newArr, error : ''}};          
      }
  
      default:
        return state;
    }
  };
  
  