import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "../reducers";
import rootSaga from "../sagas";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

import middleware, { sagaMiddleware } from './middleware';

const devMode = process.env.NODE_ENV === 'development';

const securityTokenBlacklistFilter = createBlacklistFilter(
  'securityTokenReducer',
  ['loading', 'token']
);

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist : ['currentUserReducer', 'securityTokenReducer', 'companyReducer', 'guiReducer'],
  transforms: [securityTokenBlacklistFilter],
  stateReconciler: autoMergeLevel2 //  "Merge Process" 
 };
 

const pReducer = persistReducer(persistConfig, rootReducer);

export const configureReduxStore = (initialState = {}) => {
  
  const store = configureStore({
    reducer: pReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk : false, serializableCheck: false}).concat(middleware),        
    preloadedState: initialState,
    devTools: devMode,
  });
  
  sagaMiddleware.run(rootSaga);

  return store;
}
