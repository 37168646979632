import { put, takeLatest, call } from 'redux-saga/effects'
import { ApprovalFlowApi } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

import {
    GET_APPROVALFLOW,
    GET_APPROVALFLOW_SUCCESS,
    GET_APPROVALFLOW_FAILURE,
    SAVE_APPROVALFLOW,
    SAVE_APPROVALFLOW_FAILURE,
    SAVE_APPROVALFLOW_SUCCESS,
    DELETE_APPROVALFLOW,
    DELETE_APPROVALFLOW_SUCCESS,
    DELETE_APPROVALFLOW_FAILURE,
    UPDATE_APPROVALFLOW,
    UPDATE_APPROVALFLOW_SUCCESS,
    UPDATE_APPROVALFLOW_FAILURE
} from '../actions/constants'

export function* getAllApprovalFlows() {
    var api = new ApprovalFlowApi();

    try {
        const response = yield call(() => api.getApprovalFlows());
        yield put({ type: GET_APPROVALFLOW_SUCCESS, data: response.data });
    } catch (err) {
        yield put({ type: GET_APPROVALFLOW_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* createNewApprovalFlow(action) {
    var api = new ApprovalFlowApi();

    try {
        var flow = action.payload;

        var response = yield call(() => api.createNewApprovalFlow(flow));

        yield put({ type: SAVE_APPROVALFLOW_SUCCESS, data: response.data });


    } catch (err) {
        yield put({ type: SAVE_APPROVALFLOW_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* deleteApprovalFlow(action) {
    var api = new ApprovalFlowApi();

    try {
        var approvalFlowId = action.payload.approvalFlowId;

        yield call(() => api.deleteApprovalFlowById(approvalFlowId));
        yield put({ type: DELETE_APPROVALFLOW_SUCCESS, data: { id: approvalFlowId } });
    } catch (err) {
        yield put({ type: DELETE_APPROVALFLOW_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* updateApprovalFlow(action) {
    var api = new ApprovalFlowApi();

    try {
        var data = action.payload;

        yield call(() => api.updateApprovalFlow(data));

        yield put({ type: UPDATE_APPROVALFLOW_SUCCESS, data: data });
    } catch (err) {
        yield put({ type: UPDATE_APPROVALFLOW_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* watchGetAllApprovalFlows() {
    yield takeLatest(GET_APPROVALFLOW, getAllApprovalFlows);
}

export function* watchApprovalFlowsCreate() {
    yield takeLatest(SAVE_APPROVALFLOW, createNewApprovalFlow);
}

export function* watchApprovalFlowsDelete() {
    yield takeLatest(DELETE_APPROVALFLOW, deleteApprovalFlow);
}

export function* watchApprovalFlowsUpdate() {
    yield takeLatest(UPDATE_APPROVALFLOW, updateApprovalFlow);
}