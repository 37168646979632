import {
    CREATE_TEMPLATERESULT_SUCCESS,
    CREATE_TEMPLATERESULT_FAILURE,
    CREATE_TEMPLATERESULT,
    GET_TEMPLATERESULT_SUCCESS,
    GET_TEMPLATERESULT_FAILURE,
    GET_TEMPLATERESULT,
    CLEAR_TEMPLATERESULTSAVED,
  } from '../actions/constants'

  const initialState = {
    data: [],
    error: null,
    loading: false,
    saved : false,
  };


  export const templateResultReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TEMPLATERESULT:
      case CREATE_TEMPLATERESULT:
        return {...state, ...{loading: true, error: null, saved : false}}

      case GET_TEMPLATERESULT_SUCCESS :
        return {...state, ...{loading: false, saved : false, data : action.data, error: ''}}
      case CREATE_TEMPLATERESULT_SUCCESS : {
        return {...state, ...{loading: false, saved : true, error: ''}}
      }
      case GET_TEMPLATERESULT_FAILURE:
      case CREATE_TEMPLATERESULT_FAILURE:
        return {...state, loading: false, saved : false, error : action.data.error };
      case CLEAR_TEMPLATERESULTSAVED:
        return {...state, saved : false };
      default:
        return state;
    }
  };