// type UploaderObject = {
//   id: String,
//   file?: File,
//   name: String,
//   mimeType?: String,
//   fileSize?: Number,
//   fileType?: 'image' | 'unknown',
//   isUploaded: Boolean,
//   isUploading?: Boolean,
//   isRetrying?: Boolean,
//   retriesLeft: Number,
//   error?: any,
//   progress?: Number
// };


export function isFileBeingUploaded(arrFiles) {
  if (!arrFiles || arrFiles.length === 0) return false;

  for (let i = 0; i < arrFiles.length; ++i) {
    let file = arrFiles[i];
    if (file.isUploading) return true;
  }

  return false;
}

export function isValidUploadFile(fileType) {
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  if (!validImageTypes.includes(fileType)) {
    return false;
  }
  return true;
}

export function calcCroppedData(cropStruct, imageRef) {
  var croppedImageData = Object.assign({}, cropStruct);

  const scaleX = imageRef.naturalWidth / imageRef.width;
  const scaleY = imageRef.naturalHeight / imageRef.height;

  croppedImageData.x = croppedImageData.x * scaleX;
  croppedImageData.y = croppedImageData.y * scaleY;
  croppedImageData.width = croppedImageData.width * scaleX;
  croppedImageData.height = croppedImageData.height * scaleY;

  return croppedImageData;
}

export function saveByteArray(reportName, byte, contentType) {
  var blob = new Blob([byte], { type: contentType });
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
}

export function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}