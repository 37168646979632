import {
    INITIALIZE,
    DESTROY,
    UPLOAD_ALL,
    REMOVE_ALL,
    CANCEL_ALL,
    RETRY_ALL,
    UPLOAD_OBJECT,
    START_UPLOAD,
    START_RETRY,
    FINISH_UPLOAD,
    REMOVE_OBJECT,
    CANCEL_UPLOAD,
    RETRY_UPLOAD,
    ADD_OBJECTS,
    UPDATE_VALIDATION_ERROR,
    UPDATE_UPLOAD_PROGRESS, 
    UPDATE_UPLOAD_ERROR,    
    SET_OBJECT_ATTRIBUTES,  
    SWAP_OBJECTS_BY_INDEX,  
    MOVE_OBJECT_BY_INDEX    
} from '../actions/constants'


export function initialize(id, props) {
    return { type: INITIALIZE, meta: { id }, payload: props };
  }
  
  export function destroy(id) {
    return { type: DESTROY, meta: { id } };
  }
  
  export function addObjects(id, objects) {
    return { type: ADD_OBJECTS, meta: { id }, payload: { objects } };
  }
    
  export function uploadAll(id) {
    return { type: UPLOAD_ALL, meta: { id } };
  }
  
  export function removeAll(id) {
    return { type: REMOVE_ALL, meta: { id } };
  }  
  
  export function cancelAll(id) {
    return { type: CANCEL_ALL, meta: { id } };
  }
  
  export function retryAll(id) {
    return { type: RETRY_ALL, meta: { id } };
  }
  
  export function uploadObject(id, objectId) {
    return { type: UPLOAD_OBJECT, meta: { id }, payload: { objectId } };
  }
  
  export function removeObject(id, objectId) {
    return { type: REMOVE_OBJECT, meta: { id }, payload: { objectId } };
  }
  
  export function cancelUpload(id, objectId) {
    return { type: CANCEL_UPLOAD, meta: { id }, payload: { objectId } };
  }
  
  export function startRetry(id, objectId) {
    return { type: START_RETRY, meta: { id }, payload: { objectId } };
  }
  
  export function retryUpload(id, objectId) {
    return { type: RETRY_UPLOAD, meta: { id }, payload: { objectId } };
  }
  
  export function startUpload(id, objectId) {
    return { type: START_UPLOAD, meta: { id }, payload: { objectId } };
  }
  
  export function finishUpload(id, objectId, response) {   
    return { type: FINISH_UPLOAD, meta: { id }, payload: { objectId, response } };
  }
  
  export function updateUploadProgress(id, objectId, progress) {
    return { type: UPDATE_UPLOAD_PROGRESS, meta: { id }, payload: { objectId, progress } };
  }
  
  export function updateUploadError(id, objectId, error) {  
    return { type: UPDATE_UPLOAD_ERROR, meta: { id }, payload: { objectId, error } };
  }
  
  export function setObjectAttributes(id, objectId, attributes) {
    return { type: SET_OBJECT_ATTRIBUTES, meta: { id }, payload: { objectId, attributes } };
  }
  
  export function updateValidationError(id, error) {
    return { type: UPDATE_VALIDATION_ERROR, meta: { id }, payload: { error } };
  }
  
  export function swapObjectsByIndex(id, firstIndex, secondIndex) {
    return { type: SWAP_OBJECTS_BY_INDEX, meta: { id }, payload: { firstIndex, secondIndex } };
  }
  
  export function moveObjectByIndex(id, oldIndex, newIndex) {
    return { type: MOVE_OBJECT_BY_INDEX, meta: { id }, payload: { oldIndex, newIndex } };
  }