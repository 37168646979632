import { put, takeLatest, call } from 'redux-saga/effects'
import { TemplateApi  } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

import {
  GET_TEMPLATERESULT,
  GET_TEMPLATERESULT_SUCCESS,
  GET_TEMPLATERESULT_FAILURE,
  CREATE_TEMPLATERESULT,
  CREATE_TEMPLATERESULT_SUCCESS,
  CREATE_TEMPLATERESULT_FAILURE
 } from '../actions/constants'

export function* createTemplateResult(action) {
  var api = new TemplateApi();

  try {
    let payload = action.payload;

    yield call(() => api.createTemplateResult(payload.templateCategoryId, payload.templateId, payload.templateResult));
    yield put({ type: CREATE_TEMPLATERESULT_SUCCESS, data: {} });

  } catch(err) {
    yield put({ type: CREATE_TEMPLATERESULT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getTemplateResults() {
  var api = new TemplateApi();

  try {
    let response = yield call(() => api.getTemplateResults());
    yield put({ type: GET_TEMPLATERESULT_SUCCESS, data: response.data });

  } catch(err) {
    yield put({ type: GET_TEMPLATERESULT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* watchCreateTemplateResult() {
  yield takeLatest(CREATE_TEMPLATERESULT, createTemplateResult);
}

export function* watchGetTemplateResults() {
  yield takeLatest(GET_TEMPLATERESULT, getTemplateResults);
}