import { setupSignalRConnection } from '../singnalR';
import { createNotification } from '../actions/notificationAction'
import { updateEmployeeAtProject } from '../actions/employeeAtProjecActions'
import { changeInvoiceStatusForExternalSystem } from '../actions/goodsConsumptionActions'
import { changeInvoiceStatusMachinesForExternalSystem } from '../actions/machineActions'
import { changeHourStatusForExternalSystem } from '../actions/hourActions'
import { chatMessageReceived } from '../actions/chatActions'
import { SECURITYTOKEN_REHYDRATE, GET_SECURITYTOKEN_SUCCESS } from '../actions/constants'
import { TOKEN } from '../globals'

export const reconnectSignalR = store => next => action => {
    if (action.type === SECURITYTOKEN_REHYDRATE || action.type === GET_SECURITYTOKEN_SUCCESS) {
        let token = localStorage.getItem(TOKEN);
        if (token) {
            const connectionHub = process.env.REACT_APP_CONNECTIONHUB;
            const setupEventsHub = setupSignalRConnection(connectionHub, {
                onNotification: createNotification,
                onCheckInChanged: updateEmployeeAtProject,
                onHourInTransferStatusChange : changeHourStatusForExternalSystem,
                onOrderLinesInTransferStatusChange : getInvoiceAction,                
                onChatMessage : chatMessageReceived,
            }, () => token);

            setupEventsHub(store.dispatch);
        }
    }

    return next(action);
}

function getInvoiceAction(invoiceStatusStruct) {
    if (!invoiceStatusStruct) return;

    const invoiceStatus = JSON.parse(invoiceStatusStruct);
    
    if (invoiceStatus.invoiceType === "goods") {
        return changeInvoiceStatusForExternalSystem(invoiceStatusStruct);
    } else if (invoiceStatus.invoiceType === "machines") {
        return changeInvoiceStatusMachinesForExternalSystem(invoiceStatusStruct);    
    } else {
        console.error("unhandled type from signalR");
        return null;
    }    
}
