import { 
    CREATE_NOTIFICATION, 
    CREATE_NOTIFICATION_SUCCESS, 
    CREATE_NOTIFICATION_FAILURE,  
    MARK_NOTIFICATIONASREAD,
    MARK_NOTIFICATIONASREAD_SUCCESS,
    MARK_NOTIFICATIONASREAD_FAILURE
  } from '../actions/constants'
  
  import { put, takeLatest} from 'redux-saga/effects'  
  
  export function* createNotification(action) {           
    try {        
          const response = action.payload;
          
          yield put({ type: CREATE_NOTIFICATION_SUCCESS, data: response }); 
      } catch(err) {
          yield put({ type: CREATE_NOTIFICATION_FAILURE, data: {error : 'Something unexpected happended'}   }); 
    }  
  }   

  export function* markNotificationsAsRead() {           
    try {      
          yield put({ type: MARK_NOTIFICATIONASREAD_SUCCESS, data: {} }); 
      } catch(err) {
        console.log(err)        
          yield put({ type: MARK_NOTIFICATIONASREAD_FAILURE, data: {error : 'Something unexpected happended'}   }); 
    }  
  }   
  
  export function* watchCreateNotification() {
    yield takeLatest(CREATE_NOTIFICATION, createNotification);
  }

  export function* watchMarAsReadNotifications() {
    yield takeLatest(MARK_NOTIFICATIONASREAD, markNotificationsAsRead);
  }