import { SET_CHATSTATUS, CREATE_CHATMESSAGE, CREATE_CHATMESSAGE_SUCCESS } from './constants'
 
/*SignalR event*/
export function chatMessageReceived(item) {
    const chatData = JSON.parse(item);
    
    let isConnectionInfo = chatData.type === "connection";
    
    if (isConnectionInfo) {
        return  { type: SET_CHATSTATUS, payload: { connected: isConnectionInfo, userId: chatData.fromUserId, onlineUsers : chatData.onlineCompanyUsers } }
    }
    else {
        return  { type: CREATE_CHATMESSAGE_SUCCESS, data: { fromUserId : chatData.fromUserId, toUserId : chatData.toUserId, chatMessageId : chatData.chatMessageId,  message : chatData.message, time : chatData.time} }    
    }
}

export function createChatMessage(message, fromUserId, toUserId) {
    return {
        type : CREATE_CHATMESSAGE,
        payload : {message, fromUserId, toUserId, time : new Date()}
    }    
}

