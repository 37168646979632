import { put, takeLatest, call} from 'redux-saga/effects'
import { FormTemplateApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

import { 
  GET_FORMTEMPLATE, 
  GET_FORMTEMPLATE_SUCCESS,
  GET_FORMTEMPLATE_FAILURE,    
  CREATE_FORMTEMPLATE, 
  CREATE_FORMTEMPLATE_SUCCESS,
  CREATE_FORMTEMPLATE_FAILURE,    
  DELETE_FORMTEMPLATE, 
  DELETE_FORMTEMPLATE_SUCCESS,
  DELETE_FORMTEMPLATE_FAILURE,
  UPDATE_FORMTEMPLATE,
  UPDATE_FORMTEMPLATE_SUCCESS,
  UPDATE_FORMTEMPLATE_FAILURE,    
} from '../actions/constants'

export function* getMeasureTemplates() {
  var api = new FormTemplateApi();  
  try {      
    const response = yield call(() => api.getFormTemplates());    
    yield put({ type: GET_FORMTEMPLATE_SUCCESS, data: response.data });
  } catch (err) {
    yield put({ type: GET_FORMTEMPLATE_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
  }
}

export function* createMeasureTemplate(action) {
  var api = new FormTemplateApi();
  
  try {       
    var data = action.payload;            
    var response = yield call(() => api.createFormTemplate(data));    
    data.formTemplateId = response.data.formTemplateId;

    yield put({ type: CREATE_FORMTEMPLATE_SUCCESS, data}); 
  } catch(err) {    
    yield put({ type: CREATE_FORMTEMPLATE_FAILURE, data: {error : handleCommonHttpErrors(err.response)} });  
  }  
}

export function* deleteFormTemplate(action) {
  var api = new FormTemplateApi();

  try {                        
    var checkList = action.payload;            
    yield call(() => api.deleteFormTemplate(checkList.id));            
    
    yield put({ type: DELETE_FORMTEMPLATE_SUCCESS, data: {id : checkList.id } });
  } catch(err) {            
    yield put({ type: DELETE_FORMTEMPLATE_FAILURE, data: {error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* updateFormTemplate(action) {
  var api = new FormTemplateApi();

  try {                    
    var measureTemplate = action.payload;    
    yield call(() => api.updateFormTemplate(measureTemplate));        
    
    yield put({ type: UPDATE_FORMTEMPLATE_SUCCESS, data: measureTemplate }); 
  } catch(err) {            
    yield put({ type: UPDATE_FORMTEMPLATE_FAILURE, data:{ error : handleCommonHttpErrors(err.response)} }); 
  }  
}

export function* watchGetMeasureTemplate() {
  yield takeLatest(GET_FORMTEMPLATE, getMeasureTemplates);
}

 export function* watchMeasureTemplateCreate() {
   yield takeLatest(CREATE_FORMTEMPLATE, createMeasureTemplate);
 }

export function* watchMeasureTemplateDelete() {
  yield takeLatest(DELETE_FORMTEMPLATE, deleteFormTemplate);
}

export function* watchMeasureTemplateUpdate() {
  yield takeLatest(UPDATE_FORMTEMPLATE, updateFormTemplate);
}