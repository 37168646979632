import {
    GET_FORMTEMPLATE_SUCCESS,
    GET_FORMTEMPLATE_FAILURE, 
    GET_FORMTEMPLATE,  
    DELETE_FORMTEMPLATE_SUCCESS,
    DELETE_FORMTEMPLATE_FAILURE, 
    DELETE_FORMTEMPLATE, 
    CREATE_FORMTEMPLATE, 
    CREATE_FORMTEMPLATE_SUCCESS,
    CREATE_FORMTEMPLATE_FAILURE, 
    UPDATE_FORMTEMPLATE,
    UPDATE_FORMTEMPLATE_SUCCESS,
    UPDATE_FORMTEMPLATE_FAILURE    
  } from '../actions/constants'
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
  };
  
  
  export const formTemplateReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_FORMTEMPLATE:
        return {...state, ...{loading: true, error: null}}      
      case GET_FORMTEMPLATE_SUCCESS :      
        return {...state, ...{loading: false, data : action.data, error: ''}}      
      case GET_FORMTEMPLATE_FAILURE:     
        return {...state, loading: false, error : action.data.error };
      case DELETE_FORMTEMPLATE:
            return {...state, ...{loading: true, error: null}}      
      case DELETE_FORMTEMPLATE_SUCCESS:    {      
        const list = state.data.filter(item => item.formTemplateId !== action.data.id);      
        return {...state, ...{loading: false, error : '', data : list }}; 
      }
      case DELETE_FORMTEMPLATE_FAILURE:             
        return {...state, loading: false, error : action.data.error };  
      case CREATE_FORMTEMPLATE:
        return {...state, ...{loading: true, error : null}}      
      case CREATE_FORMTEMPLATE_SUCCESS: { 
        let newArr = state.data.concat(action.data);
        return {...state, ...{loading: false, data : newArr,  error : ''}};
      }
      case CREATE_FORMTEMPLATE_FAILURE:           
        return {...state, loading: false, error : action.data.error };
      case UPDATE_FORMTEMPLATE:
        return {...state, ...{loading: true, error: null}}      
      case UPDATE_FORMTEMPLATE_SUCCESS: {            
        let newArr = [...state.data]; 
        let index = newArr.findIndex(item => item.formTemplateId === action.data.formTemplateId);
        
        if (index >= 0)
            newArr.splice(index, 1, action.data)
  
        return {...state, ...{loading: false, data :  newArr, error : ''}};
      }    
      case UPDATE_FORMTEMPLATE_FAILURE:
        return {...state, loading: false, error : action.data.error };     
      default:      
        return state;
    }
  };