import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class FormTemplateApi {
    constructor() {
        this.endpoint = `/FormTemplate/`;
    }

    async getFormTemplates() {
        try {          
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }    

    async createFormTemplate(data) {
        try {                   
            return axiosConfig.post(this.endpoint, data);
        }
        catch (error) {
            throw error;
        }        
    }      

    async deleteFormTemplate(id) {
        try {            
            return axiosConfig.delete(`${this.endpoint}${id}`, {                
            });
        }
        catch(error) {
            throw error;
        }        
    }    
    
    async updateFormTemplate(formData) {
        try {
            return axiosConfig.put(`${this.endpoint}${formData.formTemplateId}`, {                
                FormTemplateId : formData.formTemplateId,
                Name : formData.name,
                Date : formData.date,
                FormJson : formData.formJson
            });
        }
        catch(error) {
            throw error;
        }        
    }
}           

mock.onGet("FormTemplate/").reply(200, [
    {
        formTemplateId : '112121-12122-9911',
        name: 'Strøm måling list',
        date : '2020-01-01',    
        type : "measurelist",    
        items : [{
            fieldId: '112121-12122-44911',
            text : "item1",
            type : "textboxwithunit",
            description : "description xxxx",
            unit : 'Watt'
        },
        {
            fieldId: '272121-12122-44911',
            text : "item2",
            type : "textboxwithunit",
            description : "description2 xxxx",
            unit : 'Volt'
        }]
    },
    {
        formTemplateId : '212121-12122-9911',
        name: 'Dummy liste',
        date: '2020-10-12',   
        options : "Yes|No",     
        type : "checkedlist",    
        items : [{
            fieldId: '612121-12122-44911',
            text : "item1",
            description : "description xxxx",    
            options : ["Yes", "No"]        
        }],
    },    
]);

mock.onPost("FormTemplate/").reply(200, {
    formTemplateId: '112121-12122-9911',
    name: 'Strøm måling list',
    date : '2020-01-01',    
    type : "measurelist",    
    items : [{
        fieldId: '812121-12122-44911',
        text : "item1",
        description : "description xxxx",
        unit : 'Watt'
    }],
});

mock.onDelete(/FormTemplate\/.+/).reply(200);

// mock.onGet("MeasureTemplate/").reply(200, [
//     {
//         measurementFormId: '112121-12122-9911',
//         name: 'Strøm måling list',
//         date : '2020-01-01',        
//         items : [{
//             measurementFormItemId: '112121-12122-44911',
//             text : "item1",
//             description : "description xxxx",
//             unit : 'Watt'
//         },
//         {
//             measurementFormItemId: '272121-12122-44911',
//             text : "item2",
//             description : "description2 xxxx",
//             unit : 'Volt'
//         }]
//     },
//     {
//         measurementFormId: '212121-12122-9911',
//         name: 'Dummy liste',
//         date: '2020-10-12',        
//         items : [{
//             measurementFormItemId: '612121-12122-44911',
//             text : "item1",
//             description : "description xxxx",
//             unit : 'Watt'
//         }],
//     },    
// ]);

// mock.onPost("MeasureTemplate/").reply(200, {
//     measurementFormId: '112121-12122-9911',
//     name: 'Strøm måling list',
//     date : '2020-01-01',    
//     items : [{
//         measurementFormItemId: '812121-12122-44911',
//         text : "item1",
//         description : "description xxxx",
//         unit : 'Watt'
//     }],
// });

// mock.onDelete(/MeasureTemplate\/.+/).reply(200);


