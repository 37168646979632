import { 
    GET_APPROVALFLOW_SUCCESS, 
    GET_APPROVALFLOW_FAILURE, 
    GET_APPROVALFLOW,
    SAVE_APPROVALFLOW,
    SAVE_APPROVALFLOW_FAILURE,
    SAVE_APPROVALFLOW_SUCCESS,
    UPDATE_APPROVALFLOW,
    UPDATE_APPROVALFLOW_FAILURE,
    UPDATE_APPROVALFLOW_SUCCESS,
    DELETE_APPROVALFLOW,
    DELETE_APPROVALFLOW_FAILURE,
    DELETE_APPROVALFLOW_SUCCESS,    
  } from '../actions/constants'
  
  const initialState = {
    data: [],
    error: null,
    loading: false,
    saved: false,
  };
  
  export const approvalFlowReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_APPROVALFLOW:
        return { ...state, ...{ loading: true, error: null } }
      case GET_APPROVALFLOW_SUCCESS:
        return { ...state, ...{ loading: false, data: action.data, error: '' } }
      case GET_APPROVALFLOW_FAILURE:
        return { ...state, loading: false, error: action.data.error }; 
      case SAVE_APPROVALFLOW:
        return { ...state, ...{ loading: true, error: null, saved: false } }
      case SAVE_APPROVALFLOW_SUCCESS: {    
        let item = action.data;       
        let newArr = state.data.concat(item);              
        return { ...state, ...{ loading: false, data : newArr, error: '', saved: true } }
      }
      case SAVE_APPROVALFLOW_FAILURE:
        return { ...state, loading: false, error: action.data.error, saved: false };
  
      case DELETE_APPROVALFLOW:
        return { ...state, ...{ loading: true, error: null, saved: false } }
      case DELETE_APPROVALFLOW_SUCCESS:{
        const list = state.data.filter(item => item.approvalFlowId !== action.data.id);      
        return {...state, ...{loading: false, error : '', data : list }};     
      }
      case DELETE_APPROVALFLOW_FAILURE:
        return { ...state, loading: false, error: action.data.error, saved: false };    
  
      case UPDATE_APPROVALFLOW:
        return { ...state, ...{ loading: true, error: null, saved: false } }
      case UPDATE_APPROVALFLOW_SUCCESS: {
        let newArr = [...state.data]; 
        let index = newArr.findIndex(item => item.activityId === action.data.approvalFlowId);
        
        if (index >= 0)
            newArr.splice(index, 1, action.data)
  
        return {...state, ...{loading: false, data : newArr, error : '', saved : true}};  
      }    
      case UPDATE_APPROVALFLOW_FAILURE:
        return { ...state, loading: false, error: action.data.error, saved: false };
  
      default:
        return state;
    }
  };
  
  