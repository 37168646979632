// Security  constants
export const SECURITYTOKEN_REHYDRATE = 'persist/REHYDRATE';

export const GET_SECURITYTOKEN = 'GET_SECURITYTOKEN';
export const GET_SECURITYTOKEN_SUCCESS = 'GET_SECURITYTOKEN_SUCCESS';
export const GET_SECURITYTOKEN_FAILURE = 'GET_SECURITYTOKEN_FAILURE';
export const SET_SECURITYTOKEN_RESET = 'SET_SECURITYTOKEN_RESET';
export const SET_USERINITIALINFO = 'SET_USERINITIALINFO';
export const SET_USERINITIALINFO_SUCCESS = 'SET_USERINITIALINFO_SUCCESS';
export const SET_USERINITIALINFO_FAILURE = 'SET_USERINITIALINFO_FAILURE';

// Customer  constants
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMERFROMARR = 'CREATE_CUSTOMERFROMARR';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';

export const GET_GOODS = 'GET_GOODS';
export const GET_GOODS_SUCCESS = 'GET_GOODS_SUCCESS';
export const GET_GOODS_FAILURE = 'GET_GOODS_FAILURE';
export const CREATE_GOODS = 'CREATE_GOODS';
export const CREATE_GOODS_SUCCESS = 'CREATE_GOODS_SUCCESS';
export const CREATE_GOODS_FAILURE = 'CREATE_GOODS_FAILURE';
export const UPDATE_GOODS = 'UPDATE_GOODS';
export const UPDATE_GOODS_SUCCESS = 'UPDATE_GOODS_SUCCESS';
export const UPDATE_GOODS_FAILURE = 'UPDATE_GOODS_FAILURE';
export const DELETE_GOODS = 'DELETE_GOODS';
export const DELETE_GOODS_SUCCESS = 'DELETE_GOODS_SUCCESS';
export const DELETE_GOODS_FAILURE = 'DELETE_GOODS_FAILURE';
export const CREATE_GOODS_PRICE = 'CREATE_GOODS_PRICE';
export const CREATE_GOODS_PRICE_SUCCESS = 'CREATE_GOODS_PRICE_SUCCESS';
export const CREATE_GOODS_PRICE_FAILURE = 'CREATE_GOODS_PRICE_FAILURE';

export const GET_GOODSCATEGORIES = 'GET_GOODSCATEGORIES';
export const GET_GOODSCATEGORIES_SUCCESS = 'GET_GOODSCATEGORIES_SUCCESS';
export const GET_GOODSCATEGORIES_FAILURE = 'GET_GOODSCATEGORIES_FAILURE';
export const CREATE_GOODSCATEGORIES = 'CREATE_GOODSCATEGORIES';
export const CREATE_GOODSCATEGORIES_SUCCESS = 'CREATE_GOODSCATEGORIES_SUCCESS';
export const CREATE_GOODSCATEGORIES_FAILURE = 'CREATE_GOODSCATEGORIES_FAILURE';
export const UPDATE_GOODSCATEGORIES = 'UPDATE_GOODSCATEGORIES';
export const UPDATE_GOODSCATEGORIES_SUCCESS = 'UPDATE_GOODSCATEGORIES_SUCCESS';
export const UPDATE_GOODSCATEGORIES_FAILURE = 'UPDATE_GOODSCATEGORIES_FAILURE';
export const DELETE_GOODSCATEGORIES = 'DELETE_GOODSCATEGORIES';
export const DELETE_GOODSCATEGORIES_SUCCESS = 'DELETE_GOODSCATEGORIES_SUCCESS';
export const DELETE_GOODSCATEGORIES_FAILURE = 'DELETE_GOODSCATEGORIES_FAILURE';

export const GET_GOODSCONSUMPTIONBYPROJECT = 'GET_GOODSCONSUMPTIONBYPROJECT';
export const GET_GOODSCONSUMPTION = 'GET_GOODSCONSUMPTION';
export const GET_GOODSCONSUMPTION_SUCCESS = 'GET_GOODSCONSUMPTION_SUCCESS';
export const GET_GOODSCONSUMPTION_FAILURE = 'GET_GOODSCONSUMPTION_FAILURE';

export const CREATE_GOODSCONSUMPTION = 'CREATE_GOODSCONSUMPTION';
export const CREATE_GOODSCONSUMPTION_SUCCESS = 'CREATE_GOODSCONSUMPTION_SUCCESS';
export const CREATE_GOODSCONSUMPTION_FAILURE = 'CREATE_GOODSCONSUMPTION_FAILURE';
export const UPDATE_GOODSCONSUMPTION = 'UPDATE_GOODSCONSUMPTION';
export const UPDATE_GOODSCONSUMPTION_SUCCESS = 'UPDATE_GOODSCONSUMPTION_SUCCESS';
export const UPDATE_GOODSCONSUMPTION_FAILURE = 'UPDATE_GOODSCONSUMPTION_FAILURE';
export const DELETE_GOODSCONSUMPTION = 'DELETE_GOODSCONSUMPTION';
export const DELETE_GOODSCONSUMPTION_SUCCESS = 'DELETE_GOODSCONSUMPTION_SUCCESS';
export const DELETE_GOODSCONSUMPTION_FAILURE = 'DELETE_GOODSCONSUMPTION_FAILURE';

export const APPROVE_GOODSCONSUMPTION = 'APPROVE_GOODSCONSUMPTION';
export const APPROVE_GOODSCONSUMPTION_SUCCESS = 'APPROVE_GOODSCONSUMPTION_SUCCESS';
export const APPROVE_GOODSCONSUMPTION_FAILURE = 'APPROVE_GOODSCONSUMPTION_FAILURE';
export const APPROVE_GOODSCONSUMPTION_RESET = 'APPROVE_GOODSCONSUMPTION_RESET';


// Location  constants
export const GET_LOCATION            = 'GET_LOCATION';
export const GET_ALLLOCATION         = 'GET_ALLLOCATION';
export const GET_LOCATION_SUCCESS    = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE    = 'GET_LOCATION_FAILURE';
export const CREATE_LOCATION         = 'CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE';
export const DELETE_LOCATION         = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';
export const UPDATE_LOCATION         = 'UPDATE_LOCATION';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';

//hourrules
export const GET_HOURRULES            = 'GET_HOURRULES';
export const GET_HOURRULES_SUCCESS    = 'GET_HOURRULES_SUCCESS';
export const GET_HOURRULES_FAILURE    = 'GET_HOURRULES_FAILURE';
export const CREATE_HOURRULES            = 'CREATE_HOURRULES';
export const CREATE_HOURRULES_SUCCESS    = 'CREATE_HOURRULES_SUCCESS';
export const CREATE_HOURRULES_FAILURE    = 'CREATE_HOURRULES_FAILURE';
export const UPDATE_HOURRULES            = 'UPDATE_HOURRULES';
export const UPDATE_HOURRULES_SUCCESS    = 'UPDATE_HOURRULES_SUCCESS';
export const UPDATE_HOURRULES_FAILURE    = 'UPDATE_HOURRULES_FAILURE';
export const DELETE_HOURRULES            = 'DELETE_HOURRULES';
export const DELETE_HOURRULES_SUCCESS    = 'DELETE_HOURRULES_SUCCESS';
export const DELETE_HOURRULES_FAILURE    = 'DELETE_HOURRULES_FAILURE';
export const HOURRULES_RESET             = 'HOURRULES_RESET';

// Permission  constants
export const GET_COMPANYPERMISSIONS = 'GET_COMPANYPERMISSIONS';
export const GET_COMPANYPERMISSIONS_SUCCESS = 'GET_COMPANYPERMISSIONS_SUCCESS';
export const GET_COMPANYPERMISSIONS_FAILURE = 'GET_COMPANYPERMISSIONS_FAILURE';

//Report
export const GET_EMPLOYEEREPORT = 'GET_EMPLOYEEREPORT';
export const GET_EMPLOYEEREPORT_SUCCESS = 'GET_EMPLOYEEREPORT_SUCCESS';
export const GET_EMPLOYEEREPORT_FAILURE = 'GET_EMPLOYEEREPORT_FAILURE';

//Breg Company Info
export const GET_BREGINFO = 'GET_BREGINFO';
export const GET_BREGINFO_SUCCESS = 'GET_BREGINFO_SUCCESS';
export const GET_BREGINFO_FAILURE = 'GET_BREGINFO_FAILURE';
export const GET_BREGINFO_RESET = 'GET_BREGINFO_RESET';

export const GET_COMPANYBREGINFO = 'GET_COMPANYBREGINFO';
export const GET_COMPANYBREGINFO_SUCCESS = 'GET_COMPANYBREGINFO_SUCCESS';
export const GET_COMPANYBREGINFO_FAILURE = 'GET_COMPANYBREGINFO_FAILURE';

//Company Info
export const GET_COMPANYINFO = 'GET_COMPANYINFO';
export const GET_COMPANYINFO_SUCCESS = 'GET_COMPANYINFO_SUCCESS';
export const GET_COMPANYINFO_FAILURE = 'GET_COMPANYINFO_FAILURE';

export const UPDATE_COMPANYINFO = 'UPDATE_COMPANYINFO';
export const UPDATE_COMPANYINFO_SUCCESS = 'UPDATE_COMPANYINFO_SUCCESS';
export const UPDATE_COMPANYINFO_FAILURE = 'UPDATE_COMPANYINFO_FAILURE';

export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAILURE = 'GET_DEPARTMENTS_FAILURE';

export const CREATE_DEPARTMENTS = 'CREATE_DEPARTMENTS';
export const CREATE_DEPARTMENTS_SUCCESS = 'CREATE_DEPARTMENTS_SUCCESS';
export const CREATE_DEPARTMENTS_FAILURE = 'CREATE_DEPARTMENTS_FAILURE';

export const UPDATE_DEPARTMENTS = 'UPDATE_DEPARTMENTS';
export const UPDATE_DEPARTMENTS_SUCCESS = 'UPDATE_DEPARTMENTS_SUCCESS';
export const UPDATE_DEPARTMENTS_FAILURE = 'UPDATE_DEPARTMENTS_FAILURE';

export const DELETE_DEPARTMENTS = 'DELETE_DEPARTMENTS';
export const DELETE_DEPARTMENTS_SUCCESS = 'DELETE_DEPARTMENTS_SUCCESS';
export const DELETE_DEPARTMENTS_FAILURE = 'DELETE_DEPARTMENTS_FAILURE';

export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';
export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUS_FAILURE';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

export const CREATE_MODULEFORCOMPANY = 'CREATE_MODULEFORCOMPANY';
export const CREATE_MODULEFORCOMPANY_SUCCESS = 'CREATE_MODULEFORCOMPANY_SUCCESS';
export const CREATE_MODULEFORCOMPANY_FAILURE = 'CREATE_MODULEFORCOMPANY_FAILURE';

export const DELETE_MODULEFORCOMPANY = 'DELETE_MODULEFORCOMPANY';
export const DELETE_MODULEFORCOMPANY_SUCCESS = 'DELETE_MODULEFORCOMPANY_SUCCESS';
export const DELETE_MODULEFORCOMPANY_FAILURE = 'DELETE_MODULEFORCOMPANY_FAILURE';

export const UPDATE_COMPANYSETTING = 'UPDATE_COMPANYSETTING';
export const UPDATE_COMPANYSETTING_SUCCESS = 'UPDATE_COMPANYSETTING_SUCCESS';
export const UPDATE_COMPANYSETTING_FAILURE = 'UPDATE_COMPANYSETTING_FAILURE';
export const RESET_COMPANYSETTING = 'RESET_COMPANYSETTING';

export const SET_COMPANYINITIALINFO = 'SET_COMPANYINITIALINFO';
export const SET_COMPANYINITIALINFO_SUCCESS = 'SET_COMPANYINITIALINFO_SUCCESS';
export const SET_COMPANYINITIALINFO_FAILURE = 'SET_COMPANYINITIALINFO_FAILURE';

// Account constants
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';
export const CREATE_ACCOUNT_INITIALFORM = 'INITIAL_COMPANY_ACCOUNT_FORM';

// Users  constants
export const GET_CURRENTUSER = 'GET_CURRENTUSER';
export const GET_CURRENTUSER_SUCCESS = 'GET_CURRENTUSER_SUCCESS';
export const GET_CURRENTUSER_FAILURE = 'GET_CURRENTUSER_FAILURE';
export const UPDATE_LANGUAGE_CURRENTUSER = 'UPDATE_LANGUAGE_CURRENTUSER';
export const UPDATE_LOGO_CURRENTUSER = 'UPDATE_LOGO_CURRENTUSER';
export const REMOVE_LOGO_CURRENTUSER = 'REMOVE_LOGO_CURRENTUSER';
export const RESET_CURRENTUSER_SAVEDSTATE = 'RESET_CURRENTUSER_SAVEDSTATE';
export const SET_CURRENTUSERSETTING = 'SET_CURRENTUSERSETTING';
export const REMOVE_CURRENTUSERMODULESETTING = 'REMOVE_CURRENTUSERMODULESETTING';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const CREATE_USERINCOMPANY = 'CREATE_USERINCOMPANY';
export const CREATE_USERINCOMPANY_SUCCESS = 'CREATE_USERINCOMPANY_SUCCESS';
export const CREATE_USERINCOMPANY_FAILURE = 'CREATE_USERINCOMPANY_FAILURE';

export const RECOVER_USERPASSWORD = 'RECOVER_USERPASSWORD';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const CHANGE_USERPASSWORD = 'CHANGE_USERPASSWORD';
export const CHANGE_USERPASSWORD_SUCCESS = 'CHANGE_USERPASSWORD_SUCCESS';
export const CHANGE_USERPASSWORD_FAILURE = 'CHANGE_USERPASSWORD_FAILURE';

export const VERIFY_NEWUSER = 'VERIFY_NEWUSER';
export const VERIFY_NEWUSER_SUCCESS = 'VERIFY_NEWUSER_SUCCESS';
export const VERIFY_NEWUSER_FAILURE = 'VERIFY_NEWUSER_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

// Projects  constants
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';

export const GET_EMPLOYEEATPROJECTS = 'GET_EMPLOYEEATPROJECTS';
export const GET_EMPLOYEEATPROJECTS_SUCCESS = 'GET_EMPLOYEEATPROJECTS_SUCCESS';
export const GET_EMPLOYEEATPROJECTS_FAILURE = 'GET_EMPLOYEEATPROJECTS_FAILURE';
export const UPDATE_EMPLOYEEATPROJECTS = 'UPDATE_EMPLOYEEATPROJECTS';
export const DELETE_EMPLOYEEATPROJECTS = 'DELETE_EMPLOYEEATPROJECTS';

export const GET_PROJECTSDOCUMENTS = 'GET_PROJECTSDOCUMENTS';
export const GET_PROJECTSDOCUMENTS_SUCCESS = 'GET_PROJECTSDOCUMENTS_SUCCESS';
export const GET_PROJECTSDOCUMENTS_FAILURE = 'GET_PROJECTSDOCUMENTS_FAILURE';

export const GET_PROJECTCONSUMPTIONS = 'GET_PROJECTCONSUMPTIONS';
export const GET_PROJECTCONSUMPTIONS_SUCCESS = 'GET_PROJECTCONSUMPTIONS_SUCCESS';
export const GET_PROJECTCONSUMPTIONS_FAILURE = 'GET_PROJECTCONSUMPTIONS_FAILURE';

export const GET_PROJECTMACHINEHOURS = 'GET_PROJECTMACHINEHOURS';
export const GET_PROJECTMACHINEHOURS_SUCCESS = 'GET_PROJECTMACHINEHOURS_SUCCESS';
export const GET_PROJECTMACHINEHOURS_FAILURE = 'GET_PROJECTMACHINEHOURS_FAILURE';

export const CREATE_PROJECTSDOCUMENTS = 'CREATE_PROJECTSDOCUMENTS';
export const CREATE_PROJECTSDOCUMENTS_SUCCESS = 'CREATE_PROJECTSDOCUMENTS_SUCCESS';
export const CREATE_PROJECTSDOCUMENTS_FAILURE = 'CREATE_PROJECTSDOCUMENTS_FAILURE';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';
export const RESET_PROJECTDELETE_STATE = 'RESET_PROJECTDELETE_STATE';

//Observation  constants
export const GET_OBSERVATION = 'GET_OBSERVATION';
export const GET_OBSERVATION_SUCCESS = 'GET_OBSERVATION_SUCCESS';
export const GET_OBSERVATION_FAILURE = 'GET_OBSERVATION_FAILURE';
export const CREATE_OBSERVATION = 'CREATE_OBSERVATION';
export const CREATE_OBSERVATION_SUCCESS = 'CREATE_OBSERVATION_SUCCESS';
export const CREATE_OBSERVATION_FAILURE = 'CREATE_OBSERVATION_FAILURE';
export const CREATE_OBSERVATIONMEASUREMENT = 'CREATE_OBSERVATIONMEASUREMENT';
export const CREATE_OBSERVATIONMEASUREMENT_SUCCESS = 'CREATE_OBSERVATIONMEASUREMENT_SUCCESS';
export const CREATE_OBSERVATIONMEASUREMENT_FAILURE = 'CREATE_OBSERVATIONMEASUREMENT_FAILURE';
export const UPDATE_OBSERVATION = 'UPDATE_OBSERVATION';
export const UPDATE_OBSERVATION_SUCCESS = 'UPDATE_OBSERVATION_SUCCESS';
export const UPDATE_OBSERVATION_FAILURE = 'UPDATE_OBSERVATION_FAILURE';
export const DELETE_OBSERVATION = 'DELETE_OBSERVATION';
export const DELETE_OBSERVATION_SUCCESS = 'DELETE_OBSERVATION_SUCCESS';
export const DELETE_OBSERVATION_FAILURE = 'DELETE_OBSERVATION_FAILURE';
export const DELETE_OBSERVATIONMEASUREMENT = 'DELETE_OBSERVATIONMEASUREMENT';
export const DELETE_OBSERVATIONMEASUREMENT_SUCCESS = 'DELETE_OBSERVATIONMEASUREMENT_SUCCESS';
export const DELETE_OBSERVATIONMEASUREMENT_FAILURE = 'DELETE_OBSERVATIONMEASUREMENT_FAILURE';

//Measurments constants
export const GET_MEASUREMENT = 'GET_MEASUREMENT';
export const GET_MEASUREMENT_SUCCESS = 'GET_MEASUREMENT_SUCCESS';
export const GET_MEASUREMENT_FAILURE = 'GET_MEASUREMENT_FAILURE';
export const CREATE_MEASUREMENT = 'CREATE_MEASUREMENT';
export const CREATE_MEASUREMENT_SUCCESS = 'CREATE_MEASUREMENT_SUCCESS';
export const CREATE_MEASUREMENT_FAILURE = 'CREATE_MEASUREMENT_FAILURE';
export const UPDATE_MEASUREMENT = 'UPDATE_MEASUREMENT';
export const UPDATE_MEASUREMENT_SUCCESS = 'UPDATE_MEASUREMENT_SUCCESS';
export const UPDATE_MEASUREMENT_FAILURE = 'UPDATE_MEASUREMENT_FAILURE';
export const DELETE_MEASUREMENT = 'DELETE_MEASUREMENT';
export const DELETE_MEASUREMENT_SUCCESS = 'DELETE_MEASUREMENT_SUCCESS';
export const DELETE_MEASUREMENT_FAILURE = 'DELETE_MEASUREMENT_FAILURE';

//Equipment constants
export const GET_EQUIPMENT = 'GET_EQUIPMENT';
export const GET_EQUIPMENT_SUCCESS = 'GET_EQUIPMENT_SUCCESS';
export const GET_EQUIPMENT_FAILURE = 'GET_EQUIPMENT_FAILURE';
export const CREATE_EQUIPMENT = 'CREATE_EQUIPMENT';
export const CREATE_EQUIPMENT_SUCCESS = 'CREATE_EQUIPMENT_SUCCESS';
export const CREATE_EQUIPMENT_FAILURE = 'CREATE_EQUIPMENT_FAILURE';
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const UPDATE_EQUIPMENT_SUCCESS = 'UPDATE_EQUIPMENT_SUCCESS';
export const UPDATE_EQUIPMENT_FAILURE = 'UPDATE_EQUIPMENT_FAILURE';
export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';
export const DELETE_EQUIPMENT_SUCCESS = 'DELETE_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT_FAILURE = 'DELETE_EQUIPMENT_FAILURE';

export const GET_MACHINE = 'GET_MACHINE';
export const GET_MACHINE_SUCCESS = 'GET_MACHINE_SUCCESS';
export const GET_MACHINE_FAILURE = 'GET_MACHINE_FAILURE';
export const CREATE_MACHINE = 'CREATE_MACHINE';
export const CREATE_MACHINE_SUCCESS = 'CREATE_MACHINE_SUCCESS';
export const CREATE_MACHINE_FAILURE = 'CREATE_MACHINE_FAILURE';
export const UPDATE_MACHINE = 'UPDATE_MACHINE';
export const UPDATE_MACHINE_SUCCESS = 'UPDATE_MACHINE_SUCCESS';
export const UPDATE_MACHINE_FAILURE = 'UPDATE_MACHINE_FAILURE';
export const DELETE_MACHINE = 'DELETE_MACHINE';
export const DELETE_MACHINE_SUCCESS = 'DELETE_MACHINE_SUCCESS';
export const DELETE_MACHINE_FAILURE = 'DELETE_MACHINE_FAILURE';

//Equipment type constants
export const GET_EQUIPMENTTYPE = 'GET_EQUIPMENTTYPE';
export const GET_EQUIPMENTTYPE_SUCCESS = 'GET_EQUIPMENTTYPE_SUCCESS';
export const GET_EQUIPMENTTYPE_FAILURE = 'GET_EQUIPMENTTYPE_FAILURE';
export const CREATE_EQUIPMENTTYPE = 'CREATE_EQUIPMENTTYPE';
export const CREATE_EQUIPMENTTYPE_SUCCESS = 'CREATE_EQUIPMENTTYPE_SUCCESS';
export const CREATE_EQUIPMENTTYPE_FAILURE = 'CREATE_EQUIPMENTTYPE_FAILURE';
export const UPDATE_EQUIPMENTTYPE = 'UPDATE_EQUIPMENTTYPE';
export const UPDATE_EQUIPMENTTYPE_SUCCESS = 'UPDATE_EQUIPMENTTYPE_SUCCESS';
export const UPDATE_EQUIPMENTTYPE_FAILURE = 'UPDATE_EQUIPMENTTYPE_FAILURE';
export const DELETE_EQUIPMENTTYPE = 'DELETE_EQUIPMENTTYPE';
export const DELETE_EQUIPMENTTYPE_SUCCESS = 'DELETE_EQUIPMENTTYPE_SUCCESS';
export const DELETE_EQUIPMENTTYPE_FAILURE = 'DELETE_EQUIPMENTTYPE_FAILURE';

//Salary constants
export const GET_SALARY = 'GET_SALARY';
export const GET_SALARY_SUCCESS = 'GET_SALARY_SUCCESS';
export const GET_SALARY_FAILURE = 'GET_SALARY_FAILURE';
export const CREATE_SALARY = 'CREATE_SALARY';
export const CREATE_SALARY_SUCCESS = 'CREATE_SALARY_SUCCESS';
export const CREATE_SALARY_FAILURE = 'CREATE_SALARY_FAILURE';
export const UPDATE_SALARY = 'UPDATE_SALARY';
export const UPDATE_SALARY_SUCCESS = 'UPDATE_SALARY_SUCCESS';
export const UPDATE_SALARY_FAILURE = 'UPDATE_SALARY_FAILURE';
export const DELETE_SALARY = 'DELETE_SALARY';
export const DELETE_SALARY_SUCCESS = 'DELETE_SALARY_SUCCESS';
export const DELETE_SALARY_FAILURE = 'DELETE_SALARY_FAILURE';
export const CLEAR_SALARYSAVED = 'CLEAR_SALARYSAVED';

export const SET_SALARYFORMHOURWAGE = 'SET_SALARYFORMHOURWAGE';
export const DELETE_SALARYFORMHOURWAGE = 'DELETE_SALARYFORMHOURWAGE';
export const SET_SALARYFORM_ACCORDIONHOURWAGE = "SET_SALARYFORM_ACCORDIONHOURWAGE";

export const SET_SALARYFORMADDITION = 'SET_SALARYADDITION';
export const DELETE_SALARYFORMADDITION = 'DELETE_SALARYADDITION';
export const SET_SALARYFORM_ACCORDIONADDITION = "SET_SALARYFORM_ACCORDIONADDITION";

//Template
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE';
export const GET_TEMPLATEBYID = 'GET_TEMPLATEBYID';
export const GET_TEMPLATEBYID_SUCCESS = 'GET_TEMPLATEBYID_SUCCESS';
export const GET_TEMPLATEBYID_FAILURE = 'GET_TEMPLATEBYID_FAILURE';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAILURE = 'CREATE_TEMPLATE_FAILURE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';
export const CLEAR_TEMPLATESAVED = 'CLEAR_TEMPLATESAVED';

export const GET_TEMPLATECATEGORY = 'GET_TEMPLATECATEGORY';
export const GET_TEMPLATECATEGORY_SUCCESS = 'GET_TEMPLATECATEGORY_SUCCESS';
export const GET_TEMPLATECATEGORY_FAILURE = 'GET_TEMPLATECATEGORY_FAILURE';
export const CREATE_TEMPLATECATEGORY = 'CREATE_TEMPLATECATEGORY';
export const CREATE_TEMPLATECATEGORY_SUCCESS = 'CREATE_TEMPLATECATEGORY_SUCCESS';
export const CREATE_TEMPLATECATEGORY_FAILURE = 'CREATE_TEMPLATECATEGORY_FAILURE';
export const UPDATE_TEMPLATECATEGORY = 'UPDATE_TEMPLATECATEGORY';
export const UPDATE_TEMPLATECATEGORY_SUCCESS = 'UPDATE_TEMPLATECATEGORY_SUCCESS';
export const UPDATE_TEMPLATECATEGORY_FAILURE = 'UPDATE_TEMPLATECATEGORY_FAILURE';
export const DELETE_TEMPLATECATEGORY = 'DELETE_TEMPLATECATEGORY';
export const DELETE_TEMPLATECATEGORY_SUCCESS = 'DELETE_TEMPLATECATEGORY_SUCCESS';
export const DELETE_TEMPLATECATEGORY_FAILURE = 'DELETE_TEMPLATECATEGORY_FAILURE';
export const CLEAR_TEMPLATECATEGORYSAVED = 'CLEAR_TEMPLATECATEGORYSAVED';
export const GET_TEMPLATERESULT = 'GET_TEMPLATERESULT';
export const GET_TEMPLATERESULT_SUCCESS = 'GET_TEMPLATERESULT_SUCCESS';
export const GET_TEMPLATERESULT_FAILURE = 'GET_TEMPLATERESULT_FAILURE';
export const CREATE_TEMPLATERESULT = 'CREATE_TEMPLATERESULT';
export const CREATE_TEMPLATERESULT_SUCCESS = 'CREATE_TEMPLATERESULT_SUCCESS';
export const CREATE_TEMPLATERESULT_FAILURE = 'CREATE_TEMPLATERESULT_FAILURE';
export const CLEAR_TEMPLATERESULTSAVED = 'CLEAR_TEMPLATERESULTSAVED';

//Hour constants
export const GET_HOURS = 'GET_HOURS';
export const GET_HOURS_SUCCESS = 'GET_HOURS_SUCCESS';
export const GET_HOURS_FAILURE = 'GET_HOURS_FAILURE';

export const APPROVE_HOUR = 'APPROVE_HOUR';
export const APPROVE_HOUR_SUCCESS = 'APPROVE_HOUR_SUCCESS';
export const APPROVE_HOUR_FAILURE = 'APPROVE_HOUR_FAILURE';
export const RESET_APPROVESAVED = 'APPROVE_HOUR_RESET';

export const GET_HOURSBETWEENDATES = 'GET_HOURSBETWEENDATES';
export const GET_HOURSBETWEENDATES_SUCCESS = 'GET_HOURSBETWEENDATES_SUCCESS';
export const GET_HOURSBETWEENDATES_FAILURE = 'GET_HOURSBETWEENDATES_FAILURE';

export const GET_HOURBANK = 'GET_HOURBANK';
export const GET_HOURBANK_SUCCESS = 'GET_HOURBANK_SUCCESS';
export const GET_HOURBANK_FAILURE = 'GET_HOURBANK_FAILURE';

export const GET_PROJECTHOURS = 'GET_PROJECTHOURS';
export const GET_PROJECTHOURS_SUCCESS = 'GET_PROJECTHOURS_SUCCESS';
export const GET_PROJECTHOURS_FAILURE = 'GET_PROJECTHOURS_FAILURE';

export const CREATE_ABSENCEHOUR = 'CREATE_ABSENCEHOUR';
export const CREATE_HOUR = 'CREATE_HOUR';
export const CREATE_HOUR_SUCCESS = 'CREATE_HOUR_SUCCESS';
export const CREATE_HOUR_FAILURE = 'CREATE_HOUR_FAILURE';
export const RESET_HOURSAVED = 'RESET_HOURSAVED';

export const SAVE_HOURS = 'SAVE_HOURS';
export const SAVE_HOURS_SUCCESS = 'SAVE_HOURS_SUCCESS';
export const SAVE_HOURS_FAILURE = 'SAVE_HOURS_FAILURE';

export const CLEAR_HOURITEMSTATE = 'CLEAR_HOURITEMSTATE';
export const CHANGE_HOURITEMREMOTESYSTEMSTATE = 'CHANGE_HOURITEMREMOTESYSTEMSTATE'; //websockets
export const CHANGE_HOURITEMREMOTESYSTEMSYNCSTARTED = 'CHANGE_HOURITEMREMOTESYSTEMSYNCSTARTED';

export const INTEGRATION_EXPORTHOUR = 'INTEGRATION_EXPORTHOUR';
export const INTEGRATION_EXPORTHOUR_SUCCESS = 'INTEGRATION_EXPORTHOUR_SUCCESS';
export const INTEGRATION_EXPORTHOUR_FAILURE = 'INTEGRATION_EXPORTHOUR_FAILURE';

export const INTEGRATION_EXPORTINVOICE = 'INTEGRATION_EXPORTINVOICE';
export const INTEGRATION_EXPORTINVOICE_SUCCESS = 'INTEGRATION_EXPORTINVOICE_SUCCESS';
export const INTEGRATION_EXPORTINVOICE_FAILURE = 'INTEGRATION_EXPORTINVOICE_FAILURE';
export const CHANGE_INVOICEITEMREMOTESYSTEMSYNCSTARTED = 'CHANGE_INVOICEITEMREMOTESYSTEMSYNCSTARTED';
export const CHANGE_INVOICEITEMMACHINEREMOTESYSTEMSYNCSTARTED = 'CHANGE_INVOICEITEMMACHINEREMOTESYSTEMSYNCSTARTED';

export const CHANGE_INVOICEITEMREMOTESYSTEMS = 'CHANGE_INVOICEITEMREMOTESYSTEMS'; //websockets
export const CHANGE_INVOICEITEMMACHINEREMOTESYSTEMS = 'CHANGE_INVOICEITEMMACHINEREMOTESYSTEMS'; //websockets

export const CHANGE_HOURITEM = 'CHANGE_HOURITEM';
export const CHANGE_HOURITEM_SUCCESS = 'CHANGE_HOURITEM_SUCCESS';
export const CHANGE_HOURITEM_FAILURE = 'CHANGE_HOURITEM_FAILURE';

export const DELETE_HOUR = 'DELETE_HOUR';
export const DELETE_HOUR_SUCCESS = 'DELETE_HOUR_SUCCESS';
export const DELETE_HOUR_FAILURE = 'DELETE_HOUR_FAILURE';

export const GET_FORMTEMPLATE = 'GET_MEASURELIST';
export const GET_FORMTEMPLATE_SUCCESS = 'GET_MEASURELIST_SUCCESS';
export const GET_FORMTEMPLATE_FAILURE = 'GET_MEASURELIST_FAILURE';
export const CREATE_FORMTEMPLATE = 'CREATE_MEASURELIST';
export const CREATE_FORMTEMPLATE_SUCCESS = 'CREATE_MEASURELIST_SUCCESS';
export const CREATE_FORMTEMPLATE_FAILURE = 'CREATE_MEASURELIST_FAILURE';
export const UPDATE_FORMTEMPLATE = 'UPDATE_MEASURELIST';
export const UPDATE_FORMTEMPLATE_SUCCESS = 'UPDATE_MEASURELIST_SUCCESS';
export const UPDATE_FORMTEMPLATE_FAILURE = 'UPDATE_MEASURELIST_FAILURE';
export const DELETE_FORMTEMPLATE = 'DELETE_MEASURELIST';
export const DELETE_FORMTEMPLATE_SUCCESS = 'DELETE_MEASURELIST_SUCCESS';
export const DELETE_FORMTEMPLATE_FAILURE = 'DELETE_MEASURELIST_FAILURE';

//Activities constants
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_FAILURE = 'GET_ACTIVITY_FAILURE';

export const CHANGE_ACTIVITYITEM = 'CHANGE_ACTIVITYITEM';
export const CHANGE_ACTIVITYITEM_SUCCESS = 'CHANGE_ACTIVITYITEM_SUCCESS';
export const CHANGE_ACTIVITYITEM_FAILURE= 'CHANGE_ACTIVITYITEM_FAILURE';

export const SAVE_ACTIVITY = 'SAVE_ACTIVITY';
export const SAVE_ACTIVITY_SUCCESS = 'SAVE_ACTIVITY_SUCCESS';
export const SAVE_ACTIVITY_FAILURE = 'SAVE_ACTIVITY_FAILURE';

export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE';

export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_FAILURE = 'DELETE_ACTIVITY_FAILURE';
export const RESET_ACTIVITYSAVED = 'RESET_ACTIVITYSAVED';
export const RESET_ACTIVITYERROR = 'RESET_ACTIVITYERROR';

//Websockets constants
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAILURE = 'CREATE_NOTIFICATION_FAILURE';
export const MARK_NOTIFICATIONASREAD = 'MARK_NOTIFICATIONASREAD';
export const MARK_NOTIFICATIONASREAD_SUCCESS = 'MARK_NOTIFICATIONASREAD_SUCCESS';
export const MARK_NOTIFICATIONASREAD_FAILURE = 'MARK_NOTIFICATIONASREAD_FAILURE';

// Role constants
export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';
export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE';
export const CHANGE_ROLEITEM = 'CHANGE_ROLEITEM';
export const CHANGE_ROLEITEM_SUCCESS = 'CHANGE_ROLEITEM_SUCCESS';
export const CHANGE_ROLEITEM_FAILURE = 'CHANGE_ROLEITEM_FAILURE';
export const SAVE_ROLE = 'SAVE_ROLE';
export const SAVE_ROLE_SUCCESS = 'SAVE_ROLE_SUCCESS';
export const SAVE_ROLE_FAILURE = 'SAVE_ROLE_FAILURE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

//news constant
export const GET_NEWS            = 'GET_NEWS';
export const GET_NEWS_SUCCESS    = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILURE    = 'GET_NEWS_FAILURE';
export const CREATE_NEWS         = 'CREATE_NEWS';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAILURE = 'CREATE_NEWS_FAILURE';
export const UPDATE_NEWS         = 'UPDATE_NEWS';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAILURE = 'UPDATE_NEWS_FAILURE';
export const DELETE_NEWS         = 'DELETE_NEWS';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILURE = 'DELETE_NEWS_FAILURE';

// Permissions constants
export const GET_INTEGRATION_ISALIVE         = 'GET_INTEGRATION_ISALIVE';
export const GET_INTEGRATION_ISALIVE_SUCCESS = 'GET_INTEGRATION_ISALIVE_SUCCESS';
export const GET_INTEGRATION_ISALIVE_FAILURE = 'GET_INTEGRATION_ISALIVE_FAILURE';
export const RESET_INTEGRATION_ISALIVE         = 'RESET_INTEGRATION_ISALIVE';
export const RESET_INTEGRATION_ISALIVE_SUCCESS = 'RESET_INTEGRATION_ISALIVE_SUCCESS';
export const GET_INTEGRATION_ACTIVITY         = 'GET_INTEGRATION_ACTIVITY';
export const GET_INTEGRATION_ACTIVITY_SUCCESS = 'GET_INTEGRATION_ACTIVITY_SUCCESS';
export const GET_INTEGRATION_ACTIVITY_FAILURE = 'GET_INTEGRATION_ACTIVITY_FAILURE';

export const GET_INTEGRATION_PROJECT         = 'GET_INTEGRATION_PROJECT';
export const GET_INTEGRATION_PROJECT_SUCCESS = 'GET_INTEGRATION_PROJECT_SUCCESS';
export const GET_INTEGRATION_PROJECT_FAILURE = 'GET_INTEGRATION_PROJECT_FAILURE';
export const CREATE_INTEGRATION_PROJECT         = 'CREATE_INTEGRATION_PROJECT';
export const CREATE_INTEGRATION_PROJECT_SUCCESS = 'CREATE_INTEGRATION_PROJECT_SUCCESS';
export const CREATE_INTEGRATION_PROJECT_FAILURE = 'CREATE_INTEGRATION_PROJECT_FAILURE';

export const CREATE_INTEGRATIONPROJECTCONNECT         = 'CREATE_INTEGRATIONPROJECTCONNECT';
export const CREATE_INTEGRATIONPROJECTCONNECT_SUCCESS = 'CREATE_INTEGRATIONPROJECTCONNECT_SUCCESS';
export const CREATE_INTEGRATIONPROJECTCONNECT_FAILURE = 'CREATE_INTEGRATIONPROJECTCONNECT_FAILURE';

export const GET_INTEGRATION_EMPLOYEE         = 'GET_INTEGRATION_EMPLOYEE';
export const GET_INTEGRATION_EMPLOYEE_SUCCESS = 'GET_INTEGRATION_EMPLOYEE_SUCCESS';
export const GET_INTEGRATION_EMPLOYEE_FAILURE = 'GET_INTEGRATION_EMPLOYEE_FAILURE';
export const CREATE_INTEGRATION_EMPLOYEE         = 'CREATE_INTEGRATION_EMPLOYEE';
export const CREATE_INTEGRATION_EMPLOYEE_SUCCESS = 'CREATE_INTEGRATION_EMPLOYEE_SUCCESS';
export const CREATE_INTEGRATION_EMPLOYEE_FAILURE = 'CREATE_INTEGRATION_EMPLOYEE_FAILURE';

export const GET_INTEGRATION_SALARY         = 'GET_INTEGRATION_SALARY';
export const GET_INTEGRATION_SALARY_SUCCESS = 'GET_INTEGRATION_SALARY_SUCCESS';
export const GET_INTEGRATION_SALARY_FAILURE = 'GET_INTEGRATION_SALARY_FAILURE';

export const GET_INTEGRATION_CUSTOMER         = 'GET_INTEGRATION_CUSTOMER';
export const GET_INTEGRATION_CUSTOMER_SUCCESS = 'GET_INTEGRATION_CUSTOMER_SUCCESS';
export const GET_INTEGRATION_CUSTOMER_FAILURE = 'GET_INTEGRATION_CUSTOMER_FAILURE';

export const CREATE_INTEGRATION_PUSHREQUEST = 'CREATE_PUSHREQUEST';
export const CREATE_INTEGRATION_PUSHREQUEST_SUCCESS = 'CREATE_PUSHREQUEST_SUCCESS';
export const CREATE_INTEGRATION_PUSHREQUEST_FAILURE = 'CREATE_PUSHREQUEST_FAILURE';
export const CREATE_INTEGRATION_PULLREQUEST = 'CREATE_PULLREQUEST';
export const CREATE_INTEGRATION_PULLREQUEST_SUCCESS = 'CREATE_PULLREQUEST_SUCCESS';
export const CREATE_INTEGRATION_PULLREQUEST_FAILURE = 'CREATE_PULLREQUEST_FAILURE';
export const GET_INTEGRATION_SETTING = 'GET_INTEGRATION_SETTING';
export const GET_INTEGRATION_SETTING_SUCCESS = 'GET_INTEGRATION_SETTING_SUCCESS';
export const GET_INTEGRATION_SETTING_FAILURE = 'GET_INTEGRATION_SETTING_FAILURE';
export const CREATE_INTEGRATION_SETTING = 'CREATE_INTEGRATION_SETTING';
export const CREATE_INTEGRATION_SETTING_SUCCESS = 'CREATE_INTEGRATION_SETTING_SUCCESS';
export const CREATE_INTEGRATION_SETTING_FAILURE = 'CREATE_INTEGRATION_SETTING_FAILURE';
export const GET_INTEGRATION_LOGS = 'GET_INTEGRATION_LOGS';
export const GET_INTEGRATION_LOGS_SUCCESS = 'GET_INTEGRATION_LOGS_SUCCESS';
export const GET_INTEGRATION_LOGS_FAILURE = 'GET_INTEGRATION_LOGS_FAILURE';
export const CREATE_INTEGRATION_ACTIVITY = 'CREATE_INTEGRATION_ACTIVITY';
export const CREATE_INTEGRATION_ACTIVITY_SUCCESS = 'CREATE_INTEGRATION_ACTIVITY_SUCCESS';
export const CREATE_INTEGRATION_ACTIVITY_FAILURE = 'CREATE_INTEGRATION_ACTIVITY_FAILURE';

export const CREATE_INTEGRATION_HOURTRANSFER = 'CREATE_INTEGRATION_HOURTRANSFER';
export const CREATE_INTEGRATION_HOURTRANSFER_SUCCESS = 'CREATE_INTEGRATION_HOURTRANSFER_SUCCESS';
export const CREATE_INTEGRATION_HOURTRANSFER_FAILURE = 'CREATE_INTEGRATION_HOURTRANSFER_FAILURE';



// Permissions constants
export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE';

// Settings constants
export const GET_SETTINGOBSERVATIONTYPES = 'GET_SETTINGOBSERVATIONTYPES';
export const GET_SETTINGOBSERVATIONTYPES_SUCCESS = 'GET_SETTINGOBSERVATIONTYPES_SUCCESS';
export const GET_SETTINGOBSERVATIONTYPES_FAILURE = 'GET_SETTINGOBSERVATIONTYPES_FAILURE';

export const GET_SETTINGPROJECTSTATUSES = 'GET_SETTINGPROJECTSTATUSES';
export const GET_SETTINGPROJECTSTATUSES_SUCCESS = 'GET_SETTINGPROJECTSTATUSES_SUCCESS';
export const GET_SETTINGPROJECTSTATUSES_FAILURE = 'GET_SETTINGPROJECTSTATUSES_FAILURE';

export const GET_SETTINGDEVIATIONCODES = 'GET_SETTINGDEVIATIONCODES';
export const GET_SETTINGDEVIATIONCODES_SUCCESS = 'GET_SETTINGDEVIATIONCODES_SUCCESS';
export const GET_SETTINGDEVIATIONCODES_FAILURE = 'GET_SETTINGDEVIATIONCODES_FAILURE';
export const CREATE_SETTINGDEVIATIONCODES = 'CREATE_SETTINGDEVIATIONCODES';
export const CREATE_SETTINGDEVIATIONCODES_SUCCESS = 'CREATE_SETTINGDEVIATIONCODES_SUCCESS';
export const CREATE_SETTINGDEVIATIONCODES_FAILURE = 'CREATE_SETTINGDEVIATIONCODES_FAILURE';
export const UPDATE_SETTINGDEVIATIONCODES = 'UPDATE_SETTINGDEVIATIONCODES';
export const UPDATE_SETTINGDEVIATIONCODES_SUCCESS = 'UPDATE_SETTINGDEVIATIONCODES_SUCCESS';
export const UPDATE_SETTINGDEVIATIONCODES_FAILURE = 'UPDATE_SETTINGDEVIATIONCODES_FAILURE';
export const DELETE_SETTINGDEVIATIONCODES = 'DELETE_SETTINGDEVIATIONCODES';
export const DELETE_SETTINGDEVIATIONCODES_SUCCESS = 'DELETE_SETTINGDEVIATIONCODES_SUCCESS';
export const DELETE_SETTINGDEVIATIONCODES_FAILURE = 'DELETE_SETTINGDEVIATIONCODES_FAILURE';

export const GET_SETTINGOBSERVATIONCATEGORIES = 'GET_SETTINGOBSERVATIONCATEGORIES';
export const GET_SETTINGOBSERVATIONCATEGORIES_SUCCESS = 'GET_SETTINGOBSERVATIONCATEGORIES_SUCCESS';
export const GET_SETTINGOBSERVATIONCATEGORIES_FAILURE = 'GET_SETTINGOBSERVATIONCATEGORIES_FAILURE';

export const SET_CHATSTATUS = 'SET_CHATSTATUS';
export const CREATE_CHATMESSAGE = 'CREATE_CHATMESSAGE';
export const CREATE_CHATMESSAGE_SUCCESS = 'CREATE_CHATMESSAGE_SUCCESS';
export const CREATE_CHATMESSAGE_FAILURE = 'CREATE_CHATMESSAGE_FAILURE';

//File Upload
export const INITIALIZE         = 'uploader/INITIALIZE';
export const DESTROY            = 'uploader/DESTROY';
export const UPLOAD_ALL         = 'uploader/UPLOAD_ALL';
export const REMOVE_ALL         = 'uploader/REMOVE_ALL';
export const CANCEL_ALL         = 'uploader/CANCEL_ALL';
export const RETRY_ALL          = 'uploader/RETRY_ALL';
export const UPLOAD_OBJECT      = 'uploader/UPLOAD_OBJECT';
export const START_UPLOAD       = 'uploader/START_UPLOAD';
export const START_RETRY        = 'uploader/START_RETRY';
export const FINISH_UPLOAD      = 'uploader/FINISH_UPLOAD';
export const REMOVE_OBJECT      = 'uploader/REMOVE_OBJECT';
export const CANCEL_UPLOAD      = 'uploader/CANCEL_UPLOAD';
export const RETRY_UPLOAD       = 'uploader/RETRY_UPLOAD';
export const CROP               = 'uploader/CROP';
export const SCALE              = 'uploader/SCALE';
export const ADD_OBJECTS        = 'uploader/ADD_OBJECTS';
export const UPDATE_VALIDATION_ERROR    = 'uploader/UPDATE_VALIDATION_ERROR';
export const UPDATE_UPLOAD_PROGRESS     = 'uploader/UPDATE_UPLOAD_PROGRESS';
export const UPDATE_UPLOAD_ERROR        = 'uploader/UPDATE_UPLOAD_ERROR';
export const SET_OBJECT_ATTRIBUTES      = 'uploader/SET_OBJECT_ATTRIBUTES';
export const SWAP_OBJECTS_BY_INDEX      = 'uploader/SWAP_OBJECTS_BY_INDEX';
export const MOVE_OBJECT_BY_INDEX       = 'uploader/MOVE_OBJECT_BY_INDEX';

export const GET_APPROVALFLOW = 'GET_APPROVALFLOW';
export const GET_APPROVALFLOW_SUCCESS = 'GET_APPROVALFLOW_SUCCESS';
export const GET_APPROVALFLOW_FAILURE = 'GET_APPROVALFLOW_FAILURE';

export const SAVE_APPROVALFLOW = 'SAVE_APPROVALFLOW';
export const SAVE_APPROVALFLOW_SUCCESS = 'SAVE_APPROVALFLOW_SUCCESS';
export const SAVE_APPROVALFLOW_FAILURE = 'SAVE_APPROVALFLOW_FAILURE';

export const UPDATE_APPROVALFLOW = 'UPDATE_APPROVALFLOW';
export const UPDATE_APPROVALFLOW_SUCCESS = 'UPDATE_APPROVALFLOW_SUCCESS';
export const UPDATE_APPROVALFLOW_FAILURE = 'UPDATE_APPROVALFLOW_FAILURE';

export const DELETE_APPROVALFLOW = 'DELETE_APPROVALFLOW';
export const DELETE_APPROVALFLOW_SUCCESS = 'DELETE_APPROVALFLOW_SUCCESS';
export const DELETE_APPROVALFLOW_FAILURE = 'DELETE_APPROVALFLOW_FAILURE';

// TopMenuText
export const SET_TOPMENUTEXT = 'SET_TOPMENUTEXT';

//gui constants
export const GUI_SETWORKHOURSTATE_SELECTEDDATE      = 'GUI_SETWORKHOURSTATE_SELECTEDDATE';
export const GUI_SETPROJECTTABSTATE_PROJECTDATA     = 'GUI_SETPROJECTTABSTATE_PROJECTDATA';
export const GUI_SETPROJECTTABSTATE_TABINDEX        = 'GUI_SETPROJECTTABSTATE_TABINDEX';
export const GUI_SETDESIGNERMODE                    = 'GUI_SETDESIGNERMODE';

// Logout constants
export const USER_LOGOUT = 'USER_LOGOUT';


