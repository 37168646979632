import { GET_ROLE, GET_ROLE_SUCCESS,  
  GET_ROLE_FAILURE, CHANGE_ROLEITEM, 
  CHANGE_ROLEITEM_SUCCESS, 
  CHANGE_ROLEITEM_FAILURE, 
  CREATE_ROLE, 
  CREATE_ROLE_SUCCESS, 
  CREATE_ROLE_FAILURE,
  SAVE_ROLE,
  SAVE_ROLE_SUCCESS,
  SAVE_ROLE_FAILURE,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE
 } from '../actions/constants'

import { put, takeLatest, call } from 'redux-saga/effects'
import { RoleApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'

export function* getRoles() {
  var api = new RoleApi();

  try {             
 
    const rolesWithPermission = yield call(() => api.getRolesWithPermissionsForCompany());
    const availablePermissions = yield call(() => api.getavailablePermissionsForCompany());    
    
    yield put({ type: GET_ROLE_SUCCESS, data: rolesWithPermission.data, permissionsAvailable : availablePermissions.data });
  } catch (err) {            
    yield put({ type: GET_ROLE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* changeRoleItem(action) {  
  var rolePayload = action.payload;  
  
  try {    
    yield put({ type: CHANGE_ROLEITEM_SUCCESS, data: { roleId : rolePayload.roleId, permissionId : rolePayload.permissionId, isChecked : rolePayload.isChecked, arrKey : rolePayload.arrKey}});  
  } catch (err) {    
    yield put({ type: CHANGE_ROLEITEM_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* addRole(action) {  
  var rolePayload = action.payload;   
  try {    
    yield put({ type: CREATE_ROLE_SUCCESS, data:  rolePayload});  
  } catch (err) {        
    yield put({ type: CREATE_ROLE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* saveRole(action) {  
  var api = new RoleApi();
  var rolePayload = action.payload.roleArr;       
  
  try {    
    
    yield call(() => api.saveRoleForCompany(rolePayload));
    yield put({ type: SAVE_ROLE_SUCCESS, data:  {}});  

    yield call(() => getRoles())
  } catch (err) {    
    yield put({ type: SAVE_ROLE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* updateRole(action) {  
  var api = new RoleApi();
  var rolePayload = action.payload.roleArr;       
  
  try {    
    
    yield call(() => api.updateRoleForCompany(rolePayload));

    yield put({ type: UPDATE_ROLE_SUCCESS, data:  {}});  
  } catch (err) {    
    yield put({ type: UPDATE_ROLE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* deleteRole(action) {  
  let api = new RoleApi();
  let payload = action.payload;       
  
  try {    
    
    if (!payload.isNew)
        yield call(() => api.deleteRole(payload.roleId));

    yield put({ type: DELETE_ROLE_SUCCESS, data:  payload.roleId});  
  } catch (err) {    
    yield put({ type: DELETE_ROLE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}


export function* watchRoles() {
  yield takeLatest(GET_ROLE, getRoles);
}

export function*  wacthChangeRoles() {
  yield takeLatest(CHANGE_ROLEITEM, changeRoleItem)
}

export function* watchAddRoles() {
  yield takeLatest(CREATE_ROLE, addRole);
}

export function* watchSaveRoles() {
  yield takeLatest(SAVE_ROLE, saveRole);
}

export function* watchUpdateRoles() {
  yield takeLatest(UPDATE_ROLE, updateRole);
}

export function* watchDeleteRole() {
  yield takeLatest(DELETE_ROLE, deleteRole);
}