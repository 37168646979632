import { put, takeLatest, call } from 'redux-saga/effects'
import { IntegrationApi } from '../api'
import { getAllActivities } from './activity'
import { getAllUsers } from './users'
import { getAllProjects } from './project'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'
import {   
    RESET_INTEGRATION_ISALIVE,
    RESET_INTEGRATION_ISALIVE_SUCCESS,
    GET_INTEGRATION_ISALIVE,
    GET_INTEGRATION_ISALIVE_SUCCESS,
    GET_INTEGRATION_ISALIVE_FAILURE,
    GET_INTEGRATION_ACTIVITY,
    GET_INTEGRATION_ACTIVITY_FAILURE,
    GET_INTEGRATION_ACTIVITY_SUCCESS,    
    GET_INTEGRATION_PROJECT,
    GET_INTEGRATION_PROJECT_FAILURE,
    GET_INTEGRATION_PROJECT_SUCCESS,    
    CREATE_INTEGRATIONPROJECTCONNECT,
    CREATE_INTEGRATIONPROJECTCONNECT_SUCCESS,
    CREATE_INTEGRATIONPROJECTCONNECT_FAILURE,
    CREATE_INTEGRATION_PUSHREQUEST,
    CREATE_INTEGRATION_PUSHREQUEST_FAILURE,
    CREATE_INTEGRATION_PUSHREQUEST_SUCCESS,
    CREATE_INTEGRATION_PULLREQUEST,
    CREATE_INTEGRATION_PULLREQUEST_FAILURE,
    CREATE_INTEGRATION_PULLREQUEST_SUCCESS,
    GET_INTEGRATION_SETTING,
    GET_INTEGRATION_SETTING_SUCCESS,
    GET_INTEGRATION_SETTING_FAILURE,
    CREATE_INTEGRATION_SETTING,
    CREATE_INTEGRATION_SETTING_SUCCESS,
    CREATE_INTEGRATION_SETTING_FAILURE,
    GET_INTEGRATION_LOGS,
    GET_INTEGRATION_LOGS_SUCCESS,
    GET_INTEGRATION_LOGS_FAILURE,
    CREATE_INTEGRATION_ACTIVITY,
    CREATE_INTEGRATION_ACTIVITY_SUCCESS,
    CREATE_INTEGRATION_ACTIVITY_FAILURE,
    CREATE_INTEGRATION_PROJECT,
    CREATE_INTEGRATION_PROJECT_SUCCESS,
    CREATE_INTEGRATION_PROJECT_FAILURE,
    GET_INTEGRATION_EMPLOYEE,
    GET_INTEGRATION_EMPLOYEE_SUCCESS,
    GET_INTEGRATION_EMPLOYEE_FAILURE,
    GET_INTEGRATION_SALARY,
    GET_INTEGRATION_SALARY_SUCCESS,
    GET_INTEGRATION_SALARY_FAILURE,
    GET_INTEGRATION_CUSTOMER,
    GET_INTEGRATION_CUSTOMER_SUCCESS,
    GET_INTEGRATION_CUSTOMER_FAILURE,
    CREATE_INTEGRATION_EMPLOYEE,
    CREATE_INTEGRATION_EMPLOYEE_SUCCESS,
    CREATE_INTEGRATION_EMPLOYEE_FAILURE,
    CHANGE_HOURITEMREMOTESYSTEMSYNCSTARTED,
    INTEGRATION_EXPORTHOUR,
    INTEGRATION_EXPORTHOUR_SUCCESS,
    INTEGRATION_EXPORTHOUR_FAILURE,
    INTEGRATION_EXPORTINVOICE,
    INTEGRATION_EXPORTINVOICE_SUCCESS,
    INTEGRATION_EXPORTINVOICE_FAILURE,
    CHANGE_INVOICEITEMREMOTESYSTEMSYNCSTARTED,
    CHANGE_INVOICEITEMMACHINEREMOTESYSTEMSYNCSTARTED
} from '../actions/constants'

export function* createIntegrationPush(action) {
  var api = new IntegrationApi();

  try {      
    const response = yield call(() => api.push(action.payload));   
    
    yield put({ type: CREATE_INTEGRATION_PUSHREQUEST_SUCCESS, data: response.data });
  } catch (err) {            
    yield put({ type: CREATE_INTEGRATION_PUSHREQUEST_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createIntegrationPull(action) {
    var api = new IntegrationApi();
  
    try {        
      const response = yield call(() => api.pull(action.payload));   
      yield put({ type: CREATE_INTEGRATION_PULLREQUEST_SUCCESS, data: response.data });
    } catch (err) {            
      yield put({ type: CREATE_INTEGRATION_PULLREQUEST_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
    }
}

export function* resetIntegrationAlive() {  
  yield put({ type: RESET_INTEGRATION_ISALIVE_SUCCESS, data: {} });
}

export function* isIntegrationAlive(action) {
  var api = new IntegrationApi();
  
  try {          
    
    yield call(() => api.isAlive(action.payload));   
    yield put({ type: GET_INTEGRATION_ISALIVE_SUCCESS, data: true });

  } catch (err) {            
    yield put({ type: GET_INTEGRATION_ISALIVE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createIntegrationSetting(action) {
  var api = new IntegrationApi();
  
  try {              
    yield call(() => api.createSettings(action.payload));   

    yield put({ type: CREATE_INTEGRATION_SETTING_SUCCESS, data: action.payload });
  } catch (err) {            
    yield put({ type: CREATE_INTEGRATION_SETTING_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* exportHour(action) {  
  var intergationApi = new IntegrationApi();

  var hourItems = action.payload;                  
  try {   
    if (hourItems.length > 0) {
        yield put ({type : CHANGE_HOURITEMREMOTESYSTEMSYNCSTARTED, data : hourItems});
        yield call(() => intergationApi.createIntegrationHourTransfer(hourItems));           
    }  

    yield put({ type: INTEGRATION_EXPORTHOUR_SUCCESS, data: action.payload });    
  } catch (err) {        
    yield put({ type: INTEGRATION_EXPORTHOUR_FAILURE, data: { error: handleCommonHttpErrors(err.response), hourIds : hourItems } });
  }
}


export function* exportInvoices(action) {  
  var intergationApi = new IntegrationApi();
  
  var goodsItems = action.payload.goods;
  var machineItems = action.payload.machines ?? [];
  try {   
    if (goodsItems.length > 0) {
        yield put ({type : CHANGE_INVOICEITEMREMOTESYSTEMSYNCSTARTED, data : goodsItems});        
    }  

    if (machineItems.length > 0) {
      yield put ({type : CHANGE_INVOICEITEMMACHINEREMOTESYSTEMSYNCSTARTED, data : machineItems});     
  }  

    yield call(() => intergationApi.createIntegrationInvoiceTransfer(goodsItems, machineItems));           
    yield put({ type: INTEGRATION_EXPORTINVOICE_SUCCESS, data: action.payload });    
  } catch (err) {        
    yield put({ type: INTEGRATION_EXPORTINVOICE_FAILURE, data: { error: handleCommonHttpErrors(err.response), InvoiceIds : goodsItems } });
  }
}

export function* getIntegrationSetting() {
  var api = new IntegrationApi();
  
  try {             

    let response = yield call(() => api.getSettings());   
    yield put({ type: GET_INTEGRATION_SETTING_SUCCESS, data: response.data });

  } catch (err) {            
    yield put({ type: GET_INTEGRATION_SETTING_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getIntegrationSalaries(action) {
  var api = new IntegrationApi();
  
  try {          
    var data = action.payload;

    let response = yield call(() => api.getSalaries(data.type));   
    yield put({ type: GET_INTEGRATION_SALARY_SUCCESS, data: response.data });

  } catch (err) {            
    yield put({ type: GET_INTEGRATION_SALARY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getIntegrationActivities() {
  var api = new IntegrationApi();
  
  try {          

    let response = yield call(() => api.getActivities());   
    yield put({ type: GET_INTEGRATION_ACTIVITY_SUCCESS, data: response.data });

  } catch (err) {            
    yield put({ type: GET_INTEGRATION_ACTIVITY_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getIntegrationEmployees(action) {
  var api = new IntegrationApi();
  
  try {          
    var data = action.payload;

    let response = yield call(() => api.getEmployees(data.type));   
    yield put({ type: GET_INTEGRATION_EMPLOYEE_SUCCESS, data: response.data });

  } catch (err) {            
    yield put({ type: GET_INTEGRATION_EMPLOYEE_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }  
}

export function* getIntegrationProjects(action) {
  var api = new IntegrationApi();
  
  try {          
    var data = action.payload;

    let response = yield call(() => api.getProjects(data.type));   
    yield put({ type: GET_INTEGRATION_PROJECT_SUCCESS, data: response.data });

  } catch (err) {            
    yield put({ type: GET_INTEGRATION_PROJECT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* getIntegrationCustomers(action) {
  var api = new IntegrationApi();
  
  try {          
    var data = action.payload;
    
    let response = yield call(() => api.getCustomers(data.type));   
    yield put({ type: GET_INTEGRATION_CUSTOMER_SUCCESS, data: response.data });

  } catch (err) {            
    yield put({ type: GET_INTEGRATION_CUSTOMER_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}


export function* createIntegrationActivity(action) {
  var api = new IntegrationApi();

  var data = action.payload;
  let breakOnFirstError = false;
  for (let activity of data.activityArr) {
    try {
      if (breakOnFirstError) break;

      let response = yield call(() => api.createIntegrationActivity(activity, data.type));     

      yield put({ type: CREATE_INTEGRATION_ACTIVITY_SUCCESS, data: response.data });
    } catch (err) {
      yield put({ type: CREATE_INTEGRATION_ACTIVITY_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
      breakOnFirstError = true;
    }

    yield call(getAllActivities, { payload: {  } })
  }
}

export function* createIntegrationProject(action) {
  var api = new IntegrationApi();

  var data = action.payload;
  let breakOnFirstError = false;
  for (let project of data.projectArr) {
    try {
      if (breakOnFirstError) break;

      let response = yield call(() => api.createIntegrationProject(project, data.type));     

      yield put({ type: CREATE_INTEGRATION_PROJECT_SUCCESS, data: response.data });
    } catch (err) {
      yield put({ type: CREATE_INTEGRATION_PROJECT_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
      breakOnFirstError = true;
    }

    yield call(getAllProjects, { payload: {  } })
  }
}

export function* createIntegrationEmployee(action) {
  var api = new IntegrationApi();

  var data = action.payload;
  let breakOnFirstError = false;
  for (let employee of data.employeeArr) {
    try {
      if (breakOnFirstError) break;

      let response = yield call(() => api.createIntegrationEmployee(employee, data.type));     

      yield put({ type: CREATE_INTEGRATION_EMPLOYEE_SUCCESS, data: response.data });
    } catch (err) {
      yield put({ type: CREATE_INTEGRATION_EMPLOYEE_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
      breakOnFirstError = true;
    }

    yield call(getAllUsers, { payload: {  } })
  }
}

export function* getIntegrationLogs() {
  var api = new IntegrationApi();
  
  try {          

    let response = yield call(() => api.getLogs());   
    yield put({ type: GET_INTEGRATION_LOGS_SUCCESS, data: response.data });

  } catch (err) {            
    yield put({ type: GET_INTEGRATION_LOGS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* createIntegrationProjectConnection(action) {
  var api = new IntegrationApi();
  
  try {          
    var payload = action.payload;

    let response = yield call(() => api.createProjectConnections(payload.type));   
    yield put({ type: CREATE_INTEGRATIONPROJECTCONNECT_SUCCESS, data: response.data });

    yield call(getAllProjects);
  } catch (err) {            
    yield put({ type: CREATE_INTEGRATIONPROJECTCONNECT_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   });
  }
}

export function* watchIntegrationSettingGet() {
  yield takeLatest(GET_INTEGRATION_SETTING, getIntegrationSetting);
}

export function* watchIntegrationActivitiesGet() {
  yield takeLatest(GET_INTEGRATION_ACTIVITY, getIntegrationActivities);
}

export function* watchIntegrationSalariesGet() {
  yield takeLatest(GET_INTEGRATION_SALARY, getIntegrationSalaries);
}

export function* watchIntegrationEmployeesGet() {
  yield takeLatest(GET_INTEGRATION_EMPLOYEE, getIntegrationEmployees);
}

export function* watchIntegrationProjectsGet() {
  yield takeLatest(GET_INTEGRATION_PROJECT, getIntegrationProjects);
}

export function* watchIntegrationCustomersGet() {
  yield takeLatest(GET_INTEGRATION_CUSTOMER, getIntegrationCustomers);
}

export function* watchIntegrationLogsGet() {
  yield takeLatest(GET_INTEGRATION_LOGS, getIntegrationLogs);
}

export function* watchIntegrationSettingCreate() {
  yield takeLatest(CREATE_INTEGRATION_SETTING, createIntegrationSetting);
}

export function* watchIntegrationPush() {
  yield takeLatest(CREATE_INTEGRATION_PUSHREQUEST, createIntegrationPush);
}

export function* watchIntegrationPull() {
    yield takeLatest(CREATE_INTEGRATION_PULLREQUEST, createIntegrationPull);
}

export function* watchIntegrationIsAlive() {
  yield takeLatest(GET_INTEGRATION_ISALIVE, isIntegrationAlive);
}

export function* watchIntegrationIsAliveReset() {
  yield takeLatest(RESET_INTEGRATION_ISALIVE, resetIntegrationAlive);
}

export function* watchIntegrationCreateActivity() {
  yield takeLatest(CREATE_INTEGRATION_ACTIVITY, createIntegrationActivity);
}

export function* watchIntegrationCreateProject() {
  yield takeLatest(CREATE_INTEGRATION_PROJECT, createIntegrationProject);
}

export function* watchIntegrationCreateEmployee() {
  yield takeLatest(CREATE_INTEGRATION_EMPLOYEE, createIntegrationEmployee);
}

export function* watchCreateProjectConnection() {
  yield takeLatest(CREATE_INTEGRATIONPROJECTCONNECT, createIntegrationProjectConnection);
}

export function* watchExportWorkHours() {
  yield takeLatest(INTEGRATION_EXPORTHOUR, exportHour);
}

export function* watchExportInvoices() {
  yield takeLatest(INTEGRATION_EXPORTINVOICE, exportInvoices);
}

