import axiosConfig from '../axiosConfig';
//import { mock } from '../mock'

export default class ChatApi {
    constructor() {
        this.endpoint = `/Chat/`;
    }

    async createChatMessage(message) {
        try {          
            return axiosConfig.post(this.endpoint, message);
        }
        catch (error) {
            throw error;
        }        
    } 
    
}

