import {
    GET_HOURS_SUCCESS,
    GET_HOURS_FAILURE,
    GET_HOURS,
    GET_HOURSBETWEENDATES,
    GET_HOURSBETWEENDATES_SUCCESS,
    GET_HOURSBETWEENDATES_FAILURE,
    GET_PROJECTHOURS,
    GET_PROJECTHOURS_SUCCESS,
    GET_PROJECTHOURS_FAILURE,
    APPROVE_HOUR,
    APPROVE_HOUR_SUCCESS,
    APPROVE_HOUR_FAILURE,
    RESET_APPROVESAVED,
    CREATE_HOUR_SUCCESS,
    CREATE_HOUR_FAILURE,
    CREATE_HOUR,
    RESET_HOURSAVED,
    DELETE_HOUR,
    DELETE_HOUR_SUCCESS,
    DELETE_HOUR_FAILURE,
    SAVE_HOURS,
    SAVE_HOURS_SUCCESS,
    SAVE_HOURS_FAILURE,
    CHANGE_HOURITEM_SUCCESS,
    CHANGE_HOURITEM_FAILURE,
    CHANGE_HOURITEM,
    CLEAR_HOURITEMSTATE,
    CHANGE_HOURITEMREMOTESYSTEMSTATE,
    CHANGE_HOURITEMREMOTESYSTEMSYNCSTARTED,
    INTEGRATION_EXPORTHOUR_FAILURE
} from '../actions/constants'

import {deepCopy} from '../utils/deepCopy'

const initialState = {
    data: [],
    error: null,
    loading: false,
    saved: false,
    deleted : false,
};

export const hourReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HOURS:
        case GET_PROJECTHOURS:
        case GET_HOURSBETWEENDATES:
        case APPROVE_HOUR:
        case CREATE_HOUR:
        case SAVE_HOURS:            
            return { ...state, ...{ loading: true, error: null, saved: false } }            
        case APPROVE_HOUR_SUCCESS: {            
            let hourData = action.data.hourData;
            let hourCopy = deepCopy(state.data);            

            for(let itemHour of hourData) {                
                let foundItem = hourCopy.find(item => item.hourId === itemHour.hourId)
                if (foundItem) {
                    foundItem.isApproved = itemHour.isApproved;
                    foundItem.approveFlow = itemHour.approveFlow;                    
                }
            }
            
            return { ...state, ...{ loading: false, error: '', data : hourCopy, saved: true } }            
        }

        case GET_HOURS_SUCCESS:          
        case GET_PROJECTHOURS_SUCCESS:
        case GET_HOURSBETWEENDATES_SUCCESS:        
            return { ...state, ...{ loading: false, data: action.data, error: '', saved: false } }                
            
        case CREATE_HOUR_SUCCESS: {                
            let item = action.data.hourItem;
            item.isNew = true;

            let newArr = state.data.concat(item);                        
            return { ...state, ...{ loading: false, data: newArr, error: '', saved: true } };
        }
        case RESET_HOURSAVED:
        case RESET_APPROVESAVED: {
            return { ...state, loading: false, error: null, saved : false, deleted : false };
        }        
        case DELETE_HOUR:            
            return { ...state, ...{ loading: true, error: null, saved: false, deleted : false } };
        case DELETE_HOUR_SUCCESS: {           
            let arr = deepCopy(state.data);            
            arr = arr.filter(item => item.hourId !== action.data.hourId);
            
            return { ...state, ...{ loading: false, error: '', data: arr, saved: false, deleted : true } };
        }                
        case SAVE_HOURS_SUCCESS:                  
                return { ...state, ...{ loading: false, error: '', saved : true } }        
        case CHANGE_HOURITEM:
            return { ...state, ...{ loading: true, error: null, saved: false } };
        case CHANGE_HOURITEM_SUCCESS: {                  
            let arr = deepCopy(state.data);              
  
            let hourId = action.data.hourId;                                     
            let index = arr.findIndex(t => t.hourId === hourId);            
            
            if (index >= 0)
                arr.splice(index, 1, action.data)                  
            
            return { ...state, ...{ loading: false, error: '', data: arr, saved: true } };
        }        
        case CHANGE_HOURITEMREMOTESYSTEMSTATE: {            
            let foundIndex = state.data.findIndex(item => item.hourId === action.payload.hourId);
            
            if (foundIndex<0 ) return state;           
            
            let arrStateCopy = deepCopy(state.data);              

            arrStateCopy[foundIndex].externalSystemRef = action.payload.externalSystemRef;
            arrStateCopy[foundIndex].externalSystemRefError = action.payload.errorMessage;            
            
            arrStateCopy.splice(foundIndex, 1, arrStateCopy[foundIndex]);
            return { ...state, ...{ data: arrStateCopy } };
        }
        case CHANGE_HOURITEMREMOTESYSTEMSYNCSTARTED: {            
            let arrRemoteStarted = deepCopy(state.data);
            let arrHourIds = action.data;

            for (let hourId of arrHourIds) {
                let itemIndex = arrRemoteStarted.findIndex(item => item.hourId === hourId);
                if (itemIndex<0) continue;

                arrRemoteStarted[itemIndex].externalSystemStartTime = new Date().toISOString();
                arrRemoteStarted[itemIndex].externalSystemRefError = '';                
            }
            
            return { ...state, ...{ data: arrRemoteStarted} };
        }

        case GET_HOURS_FAILURE:
        case GET_PROJECTHOURS_FAILURE:
        case GET_HOURSBETWEENDATES_FAILURE:           
        case CREATE_HOUR_FAILURE:                        
        case SAVE_HOURS_FAILURE:                            
        case CHANGE_HOURITEM_FAILURE:
                return { ...state, loading: false, error: action.data.error, saved : false };
        case DELETE_HOUR_FAILURE:
        case APPROVE_HOUR_FAILURE:
            return { ...state, loading: false, error: action.data.error, saved: false, deleted : false };
        case CLEAR_HOURITEMSTATE:
            return { ...state, ...{ loading: false, saved: false, deleted : false } }
        case INTEGRATION_EXPORTHOUR_FAILURE: {
            let stateCopy = deepCopy(state.data);            
            let hourIds = action.data.hourIds;
            
            for(let hourId of hourIds) {
                let item = stateCopy.find(item => item.hourId === hourId);
                if (!item) continue;

                item.externalSystemStartTime = null;
            }

            return { ...state, ...{ data: stateCopy } };
        }
        
        default:
            return state;
    }
};