export const DASHBOARD = '/dashboard';
export const COMPANYINFO = '/company';
export const ROLES = '/roles';
export const SIGNIN = '/sign-in';
export const SIGNOUT = '/sign-out';
export const REGISTERVIEW = '/register';
export const REGISTERSUCCESSVIEW = '/registersuccess';
export const USERS = '/users';
export const ADDNEWUSER = '/adduser';
export const EDITUSER = '/edituser';
export const ADDNEWPROJECT = '/addproject';
export const EDITPROJECT = '/editproject';
export const PROJECTS = '/projects';
export const PROJECTDETAILS = '/projectdetails';
export const ADDHOUR = '/addhour';
export const MYHOURS = '/myhours';
export const MYHOURBANK = '/hourbank';
export const CUSTOMERDETAILS = '/customerdetails';
export const ADDNEWCUSTOMER = '/addcustomer';
export const EDITCUSTOMER = '/editcustomer';
export const CUSTOMER = '/customers';
export const ADDNEWACTIVITY = '/addactivity';
export const EDITACTIVITY = '/editactivity';
export const ACTIVITY = '/activities';
export const ADDNEWOBSERVATION = '/addobservation';
export const EDITOBSERVATION = '/editobservation';
export const OBSERVATION = '/observations';
export const NEWSLIST = '/newslist';
export const EQUIPMENTLIST = '/equipments';
export const EQUIPMENTTYPELIST = '/equipmenttypes';
export const CHECKLISTVIEW = '/checklistview';
export const CHECKLIST = '/checklist';
export const CHECKLISTS = '/checklists';
export const MEASURELIST = '/measurelist';
export const MEASURELISTS = '/measurelists';
export const MEASURELISTVIEW = '/measurelistview';
export const DEVIATIONCODES = '/deviationcodes';
export const ATTESTHOURS = '/attesthours';
export const SALARYTYPE = '/salarytype';
export const GOODS = '/goods';
export const ADDGOODS = '/addgoods';
export const GOODSCONSUMPTION = '/goodsconsumption';
export const ADDGOODSCONSUMPTION = '/addgoodsconsumption';
export const PROFILE = '/profile';
export const PASSWORDRECOVERY = '/passwordrecovery';
export const PASSWORDCHANGE = '/changepassword';
export const ADDHOURABSENCE = '/addabsence';
export const DEPARTMENTS = '/departments';
export const MACHINES = '/machines';
export const ADDMACHINE = '/addmachine';
export const EDITMACHINE = '/editmachine';
export const NEWUSERCONFIRMATION = "/newuserconfirmation";
export const HOURINFO = '/hourinfo';
export const ADDHOURRULE = '/addrule';
export const EDITHOURRULE = '/editrule';
export const ADDGROUP = '/addgroup';
export const EDITGROUP = '/editgroup';
export const ADDAPPROVALFLOW = '/addapprovalflow';
export const EDITAPPROVALFLOW = '/editapprovalflow';

export const INTEGRATION = '/integration';
export const INTEGRATIONLOG = '/integrationlog';
export const INTEGRATIONCONNECT = '/integrationconnect';

export const DESIGNER = '/designer';

export const TEMPLATE = '/template';
export const TEMPLATECATEGORY = '/templatecategory';
export const ADDTEMPLATECATEGORY = '/addtemplatecategory';
export const EDITTEMPLATECATEGORY = '/edittemplatecategory';
export const ADDTEMPLATE = '/addtemplate';
export const EDITTEMPLATE = '/edittemplate';

export const FORM = '/form';
export const EDITFORM = '/editform';

export const TOKEN = 'Token';
export const TOKENPERSIST_ROOT = 'persist:root';
export const COMPANY = '1';

export const NOTOKEN_ROUTES = [SIGNIN, PASSWORDRECOVERY, PASSWORDCHANGE, NEWUSERCONFIRMATION];

export const FORMTYPECHECKEDLIST = "checkedlist";
export const FORMTYPEMEASURELIST = "measurelist";

export const PAGINATION_ROWSPRPAGE_OPTIONS = [10, 25, 100];
export const PAGINATION_ROWSPRPAGE = 10;