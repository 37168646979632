import {GET_SECURITYTOKEN_SUCCESS, GET_SECURITYTOKEN_FAILURE, GET_SECURITYTOKEN, SET_SECURITYTOKEN_RESET} from '../actions/constants'

const initialState = {  
  context : null,
  token : null,  
  error: null,
  loading: false,
};

export const securityTokenReducer = (state = initialState, action) => {  
  switch (action.type) {
    case GET_SECURITYTOKEN:      
      return {...state, ...{loading: true, error : null}}      
    case GET_SECURITYTOKEN_SUCCESS :              
      return {...state, ...{context : action.data.context, loading: false, token : action.data.tokenKey}};
    case GET_SECURITYTOKEN_FAILURE:     
      return {...state, ...{token: "", loading: false, error : "LoginFailed" }};
    case SET_SECURITYTOKEN_RESET:
      return {...state, ...{loading: false, error : null, token : null }};
    default:      
      return state;
  }
};