/* eslint-disable no-unused-vars */
import { FileUploadApi } from '../api'


const uploadFile = (object, fileTypeTag) => {  
  let uploader = new FileUploadApi();

  let progressCallback = null;
  return {
    on: (_, s) => progressCallback = s,
    off: (_, s) => progressCallback = null,
    abort: () => { },
    promise: uploader.uploadFile(object, fileTypeTag, (e) => {
      if (e.lengthComputable) {
        const progress = 100 * (e.loaded / e.total);
        console.log("Progress -> " + progress);
        progressCallback({ percent: progress });
      }
    })
  }
};

let fileUploader = { uploadFile };

export default fileUploader;