import axiosConfig from '../axiosConfig'
import { mock } from '../mock'


export default class AccountApi {
    constructor() {        
        this.endpoint = `/Account`;         
    }

    async createNewAccount(email, firstName, lastName, orgNumber, password) {                      

        try {
            return axiosConfig.post(this.endpoint, {
                OrganizationNumber : orgNumber,
                Email : email,
                Firstname : firstName,
                Lastname : lastName,
                Password : password,            
            });
        }
        catch(error) {
            throw error;
        }                
    }    
}

mock.onPost('/Account/').reply(200);