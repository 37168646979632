import {
    GET_MACHINE,
    CREATE_MACHINE,
    DELETE_MACHINE,
    UPDATE_MACHINE  ,
    CHANGE_INVOICEITEMMACHINEREMOTESYSTEMS
} from './constants'

export function getMachines() {
    return {
        type : GET_MACHINE,
        payload : {}
    }
}

export function createMachine(machine, croppedImageData, fileUrl) {
    return {
        type : CREATE_MACHINE,
        payload : { machine, extra : { croppedImageData, fileUrl} }
    }
}

export function deleteMachine(equipmentId) {
    return {
        type : DELETE_MACHINE,
        payload : {equipmentId}
    }
}

export function updateMachine(machine, croppedImageData, fileUrl) {
    return {
        type : UPDATE_MACHINE,
        payload : { machine, extra : { croppedImageData, fileUrl} }
    }
}

export function changeInvoiceStatusMachinesForExternalSystem(invoiceStatusStruct) {
    if (!invoiceStatusStruct) return;

    const invoiceStatus = JSON.parse(invoiceStatusStruct);
    return {
        type : CHANGE_INVOICEITEMMACHINEREMOTESYSTEMS,
        payload : { hourMachineLinkId : invoiceStatus.id, externalSystemRef : invoiceStatus.externalSystemRef, errorMessage : invoiceStatus.errorMessage }
    }
}