import { ChatApi } from '../api'
import { CREATE_CHATMESSAGE, CREATE_CHATMESSAGE_SUCCESS, CREATE_CHATMESSAGE_FAILURE } from '../actions/constants'
import { put, takeLatest, call } from 'redux-saga/effects'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

export function* createChatMessage(action) {
    var api = new ChatApi();

    try {        
        var payloadData = action.payload;
        const response = yield call(() => api.createChatMessage(payloadData));
        payloadData.chatMessageId = response.data;

        yield put({ type: CREATE_CHATMESSAGE_SUCCESS, data: payloadData});
    } catch (err) {
        console.log(err);
        yield put({ type: CREATE_CHATMESSAGE_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });        
    }
}

export function* watchCreateChatMessage() {
    yield takeLatest(CREATE_CHATMESSAGE, createChatMessage);
}


