import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class LocationApi {
    constructor() {
        this.endpoint = `/Location/`;
    }

    async getAllLocations() {
        try {          
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }        
}


mock.onGet(/Location/).reply(200, [
    {
        locationId: '512121-12122-9912',
        customerId: '112121-12122-9911',
        name: 'Bygg1',
        places: [
            {
                placeId: '412121-12122-1912',
                name: 'Kjeller'
            }
        ]
    },
    {
        locationId: '312121-12122-1912',
        name: 'Bygg2',
        customerId: '112121-12122-9911',
        places: [
            {
                placeId: '212121-12122-9932',
                name: 'Loft'
            }
        ]
    },
]);