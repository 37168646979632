import axiosConfig from './axiosConfig';
import { TOKEN, SIGNIN, NOTOKEN_ROUTES } from '../globals'
import { getLoginInProgress } from '../../src/utils/globalDataUtil'
import { signOut } from '../actions/userActions'

const setupInterceptors = (store) => {

    // Add a response interceptor
    axiosConfig.interceptors.response.use(async response => {
        return response;
    }, function (error) {
        //catches if the session ended!
        if (error.response.status === 401) {            
            store.dispatch(signOut());
        }
        return Promise.reject(error);
    });

    axiosConfig.interceptors.request.use(
        async config => {
            const token = localStorage.getItem(TOKEN);            
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
            else {
                const inProgress = getLoginInProgress();
                if (!inProgress) {
                    let anyUnprotected = NOTOKEN_ROUTES.some(item => item === window?.location?.pathname)
                    if (!anyUnprotected)
                        window.location = SIGNIN
                }
            }

            return config
        },
        error => {
            return Promise.reject(error)
        }
    );
}

export default setupInterceptors;
