import {
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILURE, 
    GET_NEWS,      
    CREATE_NEWS_SUCCESS,
    CREATE_NEWS_FAILURE, 
    CREATE_NEWS,
    DELETE_NEWS,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAILURE,
    UPDATE_NEWS,    
    UPDATE_NEWS_SUCCESS,    
    UPDATE_NEWS_FAILURE,                            
  } from '../actions/constants'
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
  };
  
  
  export const newsReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_NEWS:
      case CREATE_NEWS:
      case DELETE_NEWS:
      case UPDATE_NEWS:
        return {...state, ...{loading: true, error: null}}      
      case GET_NEWS_SUCCESS :      
        return {...state, ...{loading: false, data : action.data, error: ''}}            
      case CREATE_NEWS_SUCCESS: {
        let copyArr = [...state.data];
        copyArr.push(action.data);

        return {...state, ...{loading: false, data : copyArr, error: ''}}      
      }
      case DELETE_NEWS_SUCCESS: {
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.newsId === action.data.newsId);
        copyArr.splice(index, 1);

        return {...state, ...{loading: false, data : copyArr, error: ''}}      
      }      
      case UPDATE_NEWS_SUCCESS: {
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.newsId === action.data.newsId);
        copyArr[index] = action.data;

        return {...state, ...{loading: false, data : copyArr, error: ''}}      
      }
      case GET_NEWS_FAILURE:     
      case CREATE_NEWS_FAILURE:
      case DELETE_NEWS_FAILURE:
      case UPDATE_NEWS_FAILURE:
        return {...state, loading: false, error : action.data.error };      
      default:      
        return state;
    }
  };