import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export class EquipmentTypeApi {
    constructor() {
        this.endpoint = `/EquipmentType`;
    }

    async getEquipmentTypes() {
        try {          
            return axiosConfig.get(this.endpoint);
        }
        catch (error) {
            throw error;
        }        
    }        
    
    async createEquipmentType(equipmentType) {
        try {          
            return axiosConfig.post(this.endpoint, equipmentType);
        }
        catch (error) {
            throw error;
        }        
    }      

    async deleteEquipmentType(equipmentTypeId) {
        try {                        
            return axiosConfig.delete(`${this.endpoint}/${equipmentTypeId}`, { });
        }
        catch(error) {
            throw error;
        }        
    }    
    
    async updateEquipmentType(equipmentType) {
        try {            
            return axiosConfig.put(`${this.endpoint}/${equipmentType.equipmentTypeId}`, {                
                EquipmentTypeId : equipmentType.equipmentTypeId,                
                Name : equipmentType.name,
                Description : equipmentType.Description,
            });
        }
        catch(error) {
            throw error;
        }        
    }
}

mock.onGet("/EquipmentType").reply(200, [
    {
        equipmentTypeId: '112121-12122-9911',
        companyId: '112121-121212-2211',
        name: 'Elektor',
    },
    {
        equipmentTypeId: '212121-12122-9911',
        companyId: '212121-121212-2211',
        name: 'Biler',
    }
]);

mock.onPost("EquipmentType/").reply(200, {
    equipmentTypeId: '7562121-12122-9911',
    companyId: '212121-121212-2211',
    name: 'Sager',
});

mock.onPut(/EquipmentType.+/).reply(200, {
    equipmentTypeId: '112121-12122-9911',
    companyId: '212121-121212-2211',
    name: 'Klær',
});

mock.onDelete(/EquipmentType.+/).reply(200);

