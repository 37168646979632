import axiosConfig from '../axiosConfig';
import { mock } from '../mock'
import { camelizeKeys } from '../../utils/toPascalCase';



export default class CompanyApi {
    constructor() {
        this.endpoint = `/Company/`;         
    }

    async getAllPermissionsForCompany(companyId) {        
        try {
            return axiosConfig.get(this.endpoint + companyId + "/permission");
        }
        catch (error) {
            throw error;
        }                        
    }  
    
    async getRoles(companyId) {
        try {
            return axiosConfig.get(this.endpoint + companyId + "/role");
        }        
        catch (error) {            
            throw error;
        }                
    }

    async getUsers(companyId) {
        try {
            return axiosConfig.get(this.endpoint + companyId + "/user");
        }
        catch (error) {
            throw error;
        }        
    }

    async getBregCompanyInformation(companyId) {
        try {
            return axiosConfig.get(this.endpoint  + companyId + "/breginfo");
        }   
        catch(error) {
            throw error;
        }             
    }

    async getCompanyInfo() {
        try {
            return axiosConfig.get(this.endpoint);
        }
        catch(error) {
            throw error;
        }        
    }

    async getCompanySettings(companyId) {
        try {            
            return axiosConfig.get(`${this.endpoint}${companyId}/setting`);
        }
        catch(error) {
            throw error;
        }        
    }

    async getCompanyModulesAccess(companyId) {
        try {            
            return axiosConfig.get(`${this.endpoint}${companyId}/module`);
        }
        catch(error) {
            throw error;
        }        
    }    

    async getDepartments(companyId) {
        try {            
            return axiosConfig.get(`${this.endpoint}${companyId}/department`);
        }
        catch(error) {
            throw error;
        }        
    }    

    async createDepartment(companyId, department) {
        try {            
            return axiosConfig.post(`${this.endpoint}${companyId}/department`, department);
        }
        catch(error) {
            throw error;
        }        
    }

    async updateDepartment(department) {
        try {            
            return axiosConfig.put(`${this.endpoint}${department.companyId}/department/${department.departmentId}`, department);
        }
        catch(error) {
            throw error;
        }        
    }

    async deleteDepartment(companyId, departmentId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}${companyId}/department/${departmentId}`);
        }
        catch(error) {
            throw error;
        }        
    }

    async createCompanyModuleAccess(companyId, name) {
        try {            
            return axiosConfig.post(`${this.endpoint}${companyId}/module`, {
                name
            });
        }
        catch(error) {
            throw error;
        }        
    }

    async removeCompanyModuleAccess(companyId, modulesForCompanyId) {
        try {            
            return axiosConfig.delete(`${this.endpoint}${companyId}/module/${modulesForCompanyId}`);
        }
        catch(error) {
            throw error;
        }        
    }

    async updateCompanySettings(companyId, settings) {                
        try {            
            return await axiosConfig.put(`${this.endpoint}${companyId}/setting?settingId=${settings.companySettingId}`, settings);            
        }
        catch(error) {
            throw error;
        }                
    }

    async createCompanySettings(companyId, settings) {                
        try {            
            return await axiosConfig.post(`${this.endpoint}${companyId}/setting`, settings);            
        }
        catch(error) {
            throw error;
        }                        
    }

    async updateCompanyInfo(companyId, companyInfo) {        
        try {
            var updateData = {
                Id : companyId,
                Name : companyInfo.companyName,
                PhoneNumber : companyInfo.phoneNumber,
                CellPhoneNumber : companyInfo.cellPhoneNumber,
                Address : companyInfo.address,
                PostalCode : companyInfo.postNumber,
                City : companyInfo.city,
                Country : companyInfo.country,
                Email : companyInfo.email,
                OrganizationNumber : companyInfo.orgNumber,
                LogoUrl : companyInfo.logoUrl,
                FileId : companyInfo.fileId,
                Currency : companyInfo.currency,
                WebSite : companyInfo.webSite,
                DefaultLanguage : companyInfo.defaultLanguage
            };
            
            await axiosConfig.put(this.endpoint + companyId, updateData);            
            return camelizeKeys(updateData);
        }
        catch(error) {
            throw error;
        }        
    }

    async createUserInCompany(companyId, newUserInfo) {             
        try {            
            return axiosConfig.post(this.endpoint + companyId + '/user', {
                firstName: newUserInfo.firstName,
                lastName: newUserInfo.lastName,
                userName: newUserInfo.userName,
                phoneNumber: newUserInfo.phoneNumber,
                employeeNumber : newUserInfo.employeeNumber,
                languageCode   : newUserInfo.languageCode,
                roles : newUserInfo.roles,
                dateOfBirth : newUserInfo.dateOfBirth,
                externalSystemRef : newUserInfo.externalSystemRef,
                autoGeneratePassword : true,
                departmentId : newUserInfo.departmentId,
                typeOfSalary : newUserInfo.typeOfSalary,
                intelliGroupId : newUserInfo.intelliGroupId
            });                       
        }
        catch (error) {            
            throw error;
        }
    }

    async deleteUserFromCompany(companyId, userId) {
        try {
            return axiosConfig.delete(this.endpoint + companyId + `/user/${userId}`);
        }
        catch(error) {
            throw error;
        }        
    }

    async updateUserInCompany(companyId, newUserInfo) {        
        try {              
            return await axiosConfig.put(this.endpoint + companyId + '/user', {
                userId : newUserInfo.userId,
                firstName: newUserInfo.firstName,
                lastName: newUserInfo.lastName,
                userName: newUserInfo.userName,
                phoneNumber: newUserInfo.phoneNumber,
                employeeNumber : newUserInfo.employeeNumber,
                languageCode : newUserInfo.languageCode,
                roles : newUserInfo.roles,
                dateOfBirth : newUserInfo.dateOfBirth,
                avatarUrl : newUserInfo.avatarUrl,
                externalSystemRef : newUserInfo.externalSystemRef,
                departmentId : newUserInfo.departmentId,
                typeOfSalary : newUserInfo.typeOfSalary,
                intelliGroupId : newUserInfo.intelliGroupId
            });
        }
        catch(error) {
            throw error;
        }        
    }
}

mock.onDelete(/\/Company\/.+\/user/).reply(200); 

mock.onGet(/\/Company\/.+\/permission/).reply(200, 
    [
        {id : "9212-3212-2121", companyId : "1" , name : "AdminAll"},        
        {id : "8212-4212-2121", companyId : "1" , name : "ProsjektlederAll"},        
        {id : "3212-1212-2129", companyId : "1" , name : "BasAll"},        
    ]);

mock.onGet(/\/Company\/.+\/user/).reply(200,
    [
        {
            id: "1212-1212-2121",
            email: "oyvindlu@gmail.com",
            phoneNumber: "90924575",
            firstName: "oyvind",
            lastName: "Ludvigsen",
            address: "LilleRusten 14",
            zipCode: "5098",
            place: "Bergen",
            language: "Norsk",
            employeeNumber: "345778",
            dateOfBirth: "25.09.1977"
        },
        {
            id: "1212-1212-2122",
            email: "oyvindlu@gmail.com",
            phoneNumber: "90924576",
            firstName: "hilde",
            lastName: "Ludvigsen",
            address: "LilleRusten 11",
            zipCode: "5098",
            place: "Bergen",
            language: "Norsk",
            employeeNumber: "245677",
            dateOfBirth: "25.09.1977"
        },
        {
            id: "1212-1212-2123",
            email: "oyvindlu@gmail.com",
            phoneNumber: "90924577",
            firstName: "trond",
            lastName: "Ludvigsen",
            address: "LilleRusten 3",
            zipCode: "5098",
            place: "Bergen",
            language: "Norsk",
            employeeNumber: "56787",
            dateOfBirth: "25.09.1977"
        }
    ]);
           
mock.onGet(/\/Company\/.+\/role/).reply(200, 
    [
        { id: "4212-1212-2121", companyId: "1", name: "Admin", permissions : [{id : "9212-3212-2121", companyId : "1" , name : "AdminAll"}, 
                                                                             {id : "8212-4212-2121", companyId : "1" , name : "ProsjektlederAll"}] },
        { id: "5212-1212-2121", companyId: "1", name: "Prosjektleder" },
        { id: "6212-1212-2121", companyId: "1", name: "Bas" },
    ]);

 mock.onGet("Company/").reply(200,     
        {
            id : '4212-1212-2121',
            name : "LuddaCo",             
            organizationNumber : "98756432",
            email : "oyvindlu@gmail.com",
            postalCode : "5098",
            place : "Bergen",
            country : "Norge",
            logoUrl : 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png'
        },          
    );    

mock.onGet(/\/Company\/.+\/breginfo/).reply(200,     
        {
            name : "LuddaCo", 
            country : "Norway" , 
            zipCode : "5098",
            place : "Bergen",
            organizationNumber : "98756432"
        },          
    );  

mock.onPost(/\/Company\/.+\/user/).reply(200, 
    {
        id: "4212-1212-2121", companyId: "1", name: "Admin", permissions : [{id : "9212-3212-2121", companyId : "1" , name : "AdminAll"}],     
    }
);

mock.onPut(/\/Company\/.+\/user/).reply(200, 
    {
        id: "9212-3212-2121", companyId: "1", name: "Admin", permissions : [{id : "9212-3212-2121", companyId : "1" , name : "AdminAll"}],     
    }
);

mock.onPut(/Company\/.+/).reply(200, 
    {
        id: '4212-1212-2121',
        name: "LuddaCo",
        organizationNumber: "98756432",
        email: "oyvindlu@gmail.com",
        postalCode: "5098",
        place: "Bergen",
        country: "Norge",
        logoUrl: 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png'
    },  
);


