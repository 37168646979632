import { BregApi } from '../api'
import { GET_BREGINFO, GET_BREGINFO_SUCCESS, GET_BREGINFO_FAILURE, GET_BREGINFO_RESET } from '../actions/constants'
import { put, takeLatest, call } from 'redux-saga/effects'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

export function* getBregInfo(action) {
    var api = new BregApi();

    try {        
        var data = action.payload;
        const response = yield call(() => api.getBregInfo(data.orgNumber));
        
        yield put({ type: GET_BREGINFO_SUCCESS, data: response.data });
    } catch (err) {
        yield put({ type: GET_BREGINFO_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
        yield put({type : GET_BREGINFO_RESET, data : {}});
    }
}

export function* watchGetBregInfo() {
    yield takeLatest(GET_BREGINFO, getBregInfo);
}


