import {
    GET_HOURRULES_SUCCESS,
    GET_HOURRULES_FAILURE, 
    GET_HOURRULES,     
    CREATE_HOURRULES_SUCCESS,
    CREATE_HOURRULES_FAILURE, 
    CREATE_HOURRULES,
    DELETE_HOURRULES,
    DELETE_HOURRULES_SUCCESS,
    DELETE_HOURRULES_FAILURE,
    UPDATE_HOURRULES,    
    UPDATE_HOURRULES_SUCCESS,    
    UPDATE_HOURRULES_FAILURE,
    HOURRULES_RESET
  } from '../actions/constants'  
  
  const initialState = {
    data: [],  
    error: null,
    loading: false,
    saved : false
  };
  
  
  export const hourRuleReducer = (state = initialState, action) => {  
    switch (action.type) {
      case GET_HOURRULES:
      case CREATE_HOURRULES:
      case DELETE_HOURRULES:
      case UPDATE_HOURRULES:
        return {...state, ...{loading: true, error: null, saved : false}}      
        
      case GET_HOURRULES_SUCCESS :      
        return {...state, ...{loading: false, data : action.data, error: ''}}      
      case CREATE_HOURRULES_SUCCESS : {
        let newArr = state.data.concat(action.data);
        return {...state, ...{loading: false, data : newArr, error: '', saved: true}}                 
      }
      case DELETE_HOURRULES_SUCCESS: {        
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.hourRuleId === action.data.hourRuleId);
        copyArr.splice(index, 1);

        return {...state, ...{loading: false, data : copyArr, error: ''}}      
      }
      case UPDATE_HOURRULES_SUCCESS: {
        let copyArr = [...state.data];
        let index = copyArr.findIndex(item => item.hourRuleId === action.data.hourRuleId);
        copyArr[index] = action.data;       

        return {...state, ...{loading: false, data : copyArr, error: '', saved: true}}      
      }

      case GET_HOURRULES_FAILURE:     
      case CREATE_HOURRULES_FAILURE:
      case DELETE_HOURRULES_FAILURE:
      case UPDATE_HOURRULES_FAILURE:
        return {...state, loading: false, error : action.data.error };      
      case HOURRULES_RESET:
        return {...state, saved: false};      
      default:      
        return state;
    }
  };