import axiosConfig from '../axiosConfig';
import { mock } from '../mock'

export default class SecurityApi {
    constructor() {
        this.endpoint = `Security`;         
    }

    async getToken(username, password) {     
        try {            
            return axiosConfig.post('/Security', {
                username,
                password
            });
        }
        catch(error) {
            throw error;
        }                   
    }

    async getContext() {     
        try {
            return axiosConfig.get('/Security/Context', );
        }
        catch(error) {
            throw error;
        }                   
    }

    async getAbilities() {     
        try {
            return axiosConfig.get('/Security/Abilities', );
        }
        catch(error) {
            throw error;
        }                   
    }

    async logOut() {        
        try {
            return axiosConfig.delete('/Security');
        }
        catch(error) {
            throw error;
        }                
    }
}

mock.onPost('/Security').reply(200, {token : "token123456"});
mock.onGet('/Security/Context').reply(200, {
    "companyName" : "LuddaCo",
    "companyId" : '1212121-4455-5656',
    "userId" : '1212121-4455-5656'
});

mock.onGet('/Security/Abilities').reply(200, [
    {
        "group" : "project",
        "permission" : "list"    
    }, 
    {
        "group": "project",
        "permission": "create"
    },
    {
        "group": "project",
        "permission": "delete"
    }

]);

mock.onDelete('/Security').reply(200);