import {
    GET_GROUPS,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILURE,
    CREATE_GROUP,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILURE,
    UPDATE_GROUP,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAILURE,
    DELETE_GROUP,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE
} from '../actions/constants'

import { put, takeLatest, call } from 'redux-saga/effects'
import { GroupApi } from '../api'
import { handleCommonHttpErrors } from '../utils/handleCommonHttpErrors'

export function* getGroups() {
    var api = new GroupApi();

    try {
        let response = yield call(() => api.getGroups())

        yield put({ type: GET_GROUPS_SUCCESS, data: response.data });
    } catch (err) {
        yield put({ type: GET_GROUPS_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* createGroup(action) {
    var api = new GroupApi();

    try {
        var groupData = action.payload;
        let response = yield call(() => api.createGroup(groupData))

        yield put({ type: CREATE_GROUP_SUCCESS, data: response.data });
    } catch (err) {
        yield put({ type: CREATE_GROUP_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* updateGroup(action) {
    var api = new GroupApi();

    try {
        var groupData = action.payload;
        let response = yield call(() => api.updateGroup(groupData.intelliGroupId, groupData))

        yield put({ type: UPDATE_GROUP_SUCCESS, data: response.data });
    } catch (err) {
        yield put({ type: UPDATE_GROUP_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}

export function* deleteGroup(action) {
    var api = new GroupApi();

    try {
        var groupData = action.payload;
        yield call(() => api.deleteGroup(groupData.intelliGroupId))

        yield put({ type: DELETE_GROUP_SUCCESS, data: groupData.intelliGroupId });
    } catch (err) {
        yield put({ type: DELETE_GROUP_FAILURE, data: { error: handleCommonHttpErrors(err.response) } });
    }
}


export function* watchGetGroups() {
    yield takeLatest(GET_GROUPS, getGroups);
}

export function* watchCreateGroup() {
    yield takeLatest(CREATE_GROUP, createGroup);
}

export function* watchUpdateGroup() {
    yield takeLatest(UPDATE_GROUP, updateGroup);
}

export function* watchDeleteGroup() {
    yield takeLatest(DELETE_GROUP, deleteGroup);
}
