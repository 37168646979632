import {
    GUI_SETPROJECTTABSTATE_PROJECTDATA,
    GUI_SETPROJECTTABSTATE_TABINDEX,
    GUI_SETWORKHOURSTATE_SELECTEDDATE,
    USER_LOGOUT,
    GUI_SETDESIGNERMODE
  } from '../actions/constants'

  const initialState = {
    projectContainerTab:  { selectedTabIndex : 0, selectedProject : null},
    workHourGuiState : { selectedDate : new Date()},
    screenMode : {isDesigner : false}
  };

  export const guiReducer = (state = initialState, action) => {
    switch (action.type) {
      case GUI_SETPROJECTTABSTATE_PROJECTDATA: {

        let project = action.payload.selectedProject;
        let current = state.projectContainerTab;

        return {...state, ...{projectContainerTab : {selectedTabIndex : current.selectedIndex, selectedProject : project }}}
      }
      case GUI_SETPROJECTTABSTATE_TABINDEX: {
        let tabIndex = action.payload.selectedTabIndex;
        let newCurrent = state.projectContainerTab;

        return {...state, ...{projectContainerTab : {selectedTabIndex : tabIndex, selectedProject : newCurrent.selectedProject }}}
      }
      case GUI_SETWORKHOURSTATE_SELECTEDDATE: {
        return {...state, ...{workHourGuiState : {selectedDate : new Date(action.payload.selectedDate) }}}
      }
      case GUI_SETDESIGNERMODE: {
        let designerMode = action.payload.designerMode;

        return {...state, ...{screenMode: {isDesigner: designerMode}}};
      }
      case USER_LOGOUT: {
          return initialState;
      }
      default:
        return state;
    }
  };