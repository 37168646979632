import { 
  GET_USERS, 
  GET_USERS_SUCCESS, 
  GET_USERS_FAILURE,  
  GET_CURRENTUSER_FAILURE, 
  GET_CURRENTUSER_SUCCESS,
  SET_CURRENTUSERSETTING,
  RECOVER_USERPASSWORD,
  CHANGE_USERPASSWORD,
  CHANGE_USERPASSWORD_SUCCESS,
  CHANGE_USERPASSWORD_FAILURE,
  VERIFY_NEWUSER,
  VERIFY_NEWUSER_SUCCESS,
  VERIFY_NEWUSER_FAILURE
} from '../actions/constants'

import { put, takeLatest, call, select} from 'redux-saga/effects'
import { CompanyApi, UserApi } from '../api'
import {handleCommonHttpErrors} from '../utils/handleCommonHttpErrors'
import { getSecurityContextFromStore } from './securityToken'

export const getCurrentUserFromStore = (state) => state.currentUserReducer;

export function* getAllUsers() {       
  var api = new CompanyApi();  
    
  try {
        let companyId = yield getCompanyId();
        const response = yield call(() => api.getUsers(companyId)); 
        yield put({ type: GET_USERS_SUCCESS, data: response.data }); 
    } catch(err) {
        yield put({ type: GET_USERS_FAILURE, data: {error : handleCommonHttpErrors(err.response)}   }); 
  }  
}

export function* getUser(action) {  
  var api = new UserApi();

  try {
    var payload = action.payload;
    
    const response = yield call(() => api.getUserById(payload.userId));       
    yield put({ type: GET_USERS_SUCCESS, data: response.data });
  } catch (err) {         
    yield put({ type: GET_USERS_FAILURE, data: {error : handleCommonHttpErrors(err.response) }});
  }
}

/* only used when logging in to get the current user*/
export function* getCurrentUser(action) {  
  var api = new UserApi();

  try {    
    var payload = action.payload;    
    const response = yield call(() => api.getUserById(payload.userId));       
    var userSettings = yield call(() => api.getUserSettings(payload.userId));
 
    yield put({ type: GET_CURRENTUSER_SUCCESS, data: {userData : response.data,  abilities : payload.abilities, settings : userSettings.data, modules : payload.modules} });
  } catch (err) {         
    yield put({ type: GET_CURRENTUSER_FAILURE, data: {error : handleCommonHttpErrors(err.response) }});
  }
}

/* let excpetion be thrown from this method */
export function* getCurrentUserSetting() {
  var api = new UserApi();

    const { data: user } = yield select(getCurrentUserFromStore);       
 
    var userSettings = yield call(() => api.getUserSettings(user.userId));
    
    yield put({ type: SET_CURRENTUSERSETTING, data: { settings : userSettings.data } }); 
}

export function* recoverUserPassword(action) {  
  var api = new UserApi();
  try {    
    var payload = action.payload;    
    yield call(() => api.recoverUserPassword(payload));            
  } catch (err) {         
    console.log(err)
    //only return success codes
  }
}

export function* resetUserPassword(action) {  
  var api = new UserApi();

  try {    
    var payload = action.payload;
    
    const response = yield call(() => api.resetUserPassword(payload));       
    yield put({ type: CHANGE_USERPASSWORD_SUCCESS, data: response.data });
    //yield put({ type: RESET_CURRENTUSER_SAVEDSTATE, data: {} });
    
  } catch (err) {         
    yield put({ type: CHANGE_USERPASSWORD_FAILURE, data: {error : handleCommonHttpErrors(err.response) }});
    //yield put({ type: RESET_CURRENTUSER_SAVEDSTATE, data: {} });
  }
}

export function* verifyNewUser(action) {  
  var api = new UserApi();

  try {    
    var payload = action.payload;
        
    const response = yield call(() => api.verifyNewUser(payload.token, payload.data));       
    yield put({ type: VERIFY_NEWUSER_SUCCESS, data: response.data });    
    
  } catch (err) {         
    yield put({ type: VERIFY_NEWUSER_FAILURE, data: {error : handleCommonHttpErrors(err.response) }});    
  }
}

function* getCompanyId() {
  let context = yield select(getSecurityContextFromStore);  
  return context.companyId;
}

export function* watchGetAllUsers() {
  yield takeLatest(GET_USERS, getAllUsers);
}

export function* watchVerifyNewUser() {
  yield takeLatest(VERIFY_NEWUSER, verifyNewUser);
}

export function* watchChangeUserPassword() {
  yield takeLatest(CHANGE_USERPASSWORD, resetUserPassword);
}

export function* watchRecoverUserPassword() {
  yield takeLatest(RECOVER_USERPASSWORD, recoverUserPassword);
}